import type { ShipmentLocationActionDto as LocationAction } from '@uturn/api/v1';
import { ShipmentLocationActionDtoLocationActionType as LocationActionType } from '@uturn/api/v1';
import { TransportTypeCode } from '@uturn/portal/types/shipment';

/**
 * Determine transportType based on the content of locationActions.
 *
 * Import
 * [0]  "PICKUP"
 * [1?] "DELIVER"
 * [-1] "DROP"
 *
 * Export
 * [0]  "PICKUP"
 * [1?] "LOAD"
 * [-1] "DROP"
 *
 * Shunt
 * [0]  "PICKUP"
 * [-1] "DROP"
 */
export const guessTransportType = (locationActions: LocationAction[]) => {
	const hasLocationDelivery = locationActions.some(
		(item) =>
			item.locationActionType === LocationActionType.Deliver ||
			`${item.locationActionType}` === 'DELIVER', // TODO: internal.ts type wrong above?
	);
	const hasLocationLoad = locationActions.some(
		(item) =>
			item.locationActionType === LocationActionType.Load ||
			`${item.locationActionType}` === 'LOAD', // TODO: internal.ts type wrong above?
	);
	switch (true) {
		case locationActions.length === 2:
			return TransportTypeCode.SHUNT;
		case hasLocationDelivery:
			return TransportTypeCode.IMPORT;
		case hasLocationLoad:
			return TransportTypeCode.EXPORT;
		default:
			return TransportTypeCode.SHUNT;
	}
};
