import { logout } from '@uturn/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Logout() {
	const navigate = useNavigate();

	useEffect(() => {
		logout();
		navigate('/sign-in');
	}, []);

	return null;
}
