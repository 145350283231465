import { create } from 'zustand';

export type DevToolsState = {
	showReactQueryDevTools: boolean;
	toggleReactQueryDevTools: () => void;
};

export const useDevTools = create<DevToolsState>((set) => ({
	showReactQueryDevTools: false,
	toggleReactQueryDevTools: () => {
		set((state) => ({
			showReactQueryDevTools: !state.showReactQueryDevTools,
		}));
	},
}));
