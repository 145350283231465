import { ConditionType, useGetMyCarriersList } from '@uturn/api/v1';
import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';
import { DateTimePicker } from '@uturn/ui';
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	InputAddon,
	MultiSelect,
	RadioGroup,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useAbac } from 'react-abac';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Permission } from '@uturn/portal/abac';
import { MetaDataContext } from '@uturn/portal/context';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import {
	isFieldReadOnly,
	shouldUpdate,
} from '@uturn/portal/modules/shipments/utils';
import {
	formatDatetime,
	formatPrice,
	getCurrencySign,
} from '@uturn/portal/utils';
import { convertToIsoDateWithTimezone } from '@uturn/portal/utils/convert-to-iso-date-with-timezone';

type FormOptionsType<T> = {
	id: string;
	value: T;
	label: string;
	hidden: boolean;
};

function FormRadioGroupHorizontalItem<T>({
	id,
	value,
	label,
	hidden,
}: FormOptionsType<T>) {
	return hidden ? null : (
		<FormItem>
			<FormControl>
				<FormLabel
					htmlFor={id}
					className="ms-2 flex w-full cursor-pointer items-center gap-2 rounded-md border-2 border-muted bg-popover py-3 pl-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-primary"
				>
					<RadioGroupItem
						className="border-default peer peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
						id={id}
						value={typeof value === 'boolean' ? id : (value as string)}
					/>
					{label}
				</FormLabel>
			</FormControl>
		</FormItem>
	);
}

function FormRadioGroupVerticalItem<T>({
	id,
	value,
	label,
	hidden,
}: FormOptionsType<T>) {
	return hidden ? null : (
		<FormItem className="flex items-center space-x-3 space-y-0">
			<FormControl>
				<RadioGroupItem
					id={id}
					value={typeof value === 'boolean' ? id : (value as string)}
				/>
			</FormControl>
			<FormLabel htmlFor={id} className="cursor-pointer font-normal">
				{label}
			</FormLabel>
		</FormItem>
	);
}

export function PublishDetailsSection({
	isCrossBorderShipment,
	isGBRShipment,
	shipmentStatus,
}: {
	isCrossBorderShipment: boolean;
	isGBRShipment: boolean;
	shipmentStatus?: ShipmentStatus;
}) {
	const { t } = useTranslation();
	const { userHasPermissions } = useAbac();
	const { shipmentMetaData } = useContext(MetaDataContext);
	const { readOnlyFields } = useContext(ShipmentDetailContext);
	const { data: carriersResponse } = useGetMyCarriersList({
		query: {
			enabled: userHasPermissions(Permission.INVITE_CARRIER),
		},
	});
	const form = useFormContext<FormValues>();

	const watchFixedPrice = form.watch('fixedPrice');
	const watchDutiesPaid = form.watch('dutiesPaid');

	const [invoiceRequired, priceCurrencyCode] = form.getValues([
		'invoiceRequired',
		'price.currencyCode',
	]);

	const carriers =
		carriersResponse?.data?.filter(
			(carrier) =>
				carrier.relationStatus === 'Contracted' ||
				carrier.relationStatus === 'Dedicated',
		) ?? [];

	const fixedPriceOptions: FormOptionsType<boolean>[] = [
		{
			id: 'quote_accept',
			value: true,
			label: t(
				'pages.addshipment.manual.carrier_can.quote_accept.label',
				'Quote or accept',
			),
			hidden: false,
		},
		{
			id: 'quote_only',
			value: false,
			label: t(
				'pages.addshipment.manual.carrier_can.quote_only.label',
				'Quote only',
			),
			hidden: false,
		},
	];

	const dutiesPaidOptions: FormOptionsType<boolean>[] = [
		{
			id: 'free',
			value: false,
			label: t('pages.addshipment.manual.free_of_vat.label', 'Free of VAT'),
			hidden: false,
		},
		{
			id: 'regular',
			value: true,
			label: t('pages.addshipment.manual.regular_vat.label', 'Regular VAT'),
			hidden: false,
		},
	];

	const conditionOptions: FormOptionsType<ConditionType>[] = [
		{
			id: 'avc',
			value: ConditionType.AVC,
			label: t('pages.addshipment.manual.conditions.avc.label', 'AVC'),
			hidden: isCrossBorderShipment,
		},
		{
			id: 'cmr',
			value: ConditionType.CMR,
			label: t('pages.addshipment.manual.conditions.cmr.label', 'CMR'),
			hidden: false,
		},
		{
			id: 'rha',
			value: ConditionType.RHA,
			label: t('pages.addshipment.manual.conditions.rha.label', 'RHA'),
			hidden: !isGBRShipment,
		},
	];

	return (
		<div className="relative h-full">
			<Card>
				<CardHeader>
					<CardTitle>
						{t(
							'pages.create_shipment.publish_details.heading',
							'Publish information',
						)}
					</CardTitle>
				</CardHeader>
				<CardContent className="flex flex-col gap-6">
					<div className="grid grid-cols-1 gap-5 sm:grid-cols-6">
						{carriers.length > 0 && (
							<div className="sm:col-span-full">
								<FormField
									control={form.control}
									name="preferredCarrierIds"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.addshipment.manual.preferred_carries.label',
													'Preferred carriers',
												)}
											</FormLabel>
											<FormControl>
												<MultiSelect
													onChange={field.onChange}
													placeholder={
														t(
															'pages.addshipment.manual.preferred_carries.placeholder',
															'Select carrier',
														)!
													}
													selected={
														field.value?.length
															? field.value.map((value: number) =>
																	String(value),
																)
															: []
													}
													options={carriers.map((item) => {
														return {
															label: item.carrierParty!,
															value: String(item.carrierPartyId!),
														};
													})}
													selectAllEnabled={true}
													variant="default"
													align="start"
													filter={{
														placeholder: t(
															'pages.addshipment.manual.preferred_carries.search.placeholder',
															'Search carrier...',
														)!,
														empty: t(
															'pages.addshipment.manual.preferred_carries.search.empty',
															'No carriers found.',
														)!,
													}}
													disabled={isFieldReadOnly(
														readOnlyFields,
														'preferredCarrierIds',
													)}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						)}
						<div className="sm:col-span-3">
							<FormField
								control={form.control}
								name="price.quantity"
								render={({ field }) => (
									<FormItem className="relative">
										<FormLabel className="required-asterix">
											{shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED
												? t(
														'pages.shipments.details.agreedPrice',
														'Agreed price',
													)
												: t(
														'pages.addshipment.manual.target_price.label',
														'Target price',
													)}
										</FormLabel>
										{isFieldReadOnly(readOnlyFields!, 'price.quantity') ? (
											<FormFieldReadOnly
												value={formatPrice(field.value ?? 0, priceCurrencyCode)}
											/>
										) : (
											<div className="relative">
												<FormControl>
													<Input
														{...field}
														className="pl-6"
														type="number"
														value={field.value}
														onChange={(e) =>
															field.onChange(e.target.valueAsNumber)
														}
													/>
												</FormControl>
												<InputAddon type="leading">
													<span className="text-secondary-500 sm:text-sm">
														{getCurrencySign(
															priceCurrencyCode,
															shipmentMetaData?.currencies,
														)}
													</span>
												</InputAddon>
											</div>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="sm:col-span-3">
							<FormItem>
								<FormLabel className="my-0">
									{t(
										'pages.addshipment.manual.carrier_can.label',
										'Carrier can',
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields!, 'fixedPrice') ? (
									<FormFieldReadOnly
										value={
											fixedPriceOptions.find(
												(item) => watchFixedPrice === item.value,
											)?.label ?? '-'
										}
									/>
								) : (
									<FormControl>
										<RadioGroup
											value={
												fixedPriceOptions.find(
													(item) => watchFixedPrice === item.value,
												)?.id
											}
											onValueChange={(e) => {
												form.setValue(
													'fixedPrice',
													fixedPriceOptions.find((item) => e === item.id)
														?.value,
													shouldUpdate,
												);
											}}
											className={'mt-0 grid grid-cols-2 gap-2 pt-0'}
										>
											{fixedPriceOptions.map((item) => {
												return (
													<FormRadioGroupHorizontalItem
														key={item.id}
														{...item}
													/>
												);
											})}
										</RadioGroup>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						</div>
						<div className="sm:col-span-3">
							<FormItem>
								<FormLabel>
									{t(
										'pages.addshipment.manual.goods_cleared.label',
										'Goods cleared',
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields!, 'dutiesPaid') ? (
									<FormFieldReadOnly
										value={
											dutiesPaidOptions.find(
												(item) => watchDutiesPaid === item.value,
											)?.label ?? '-'
										}
									/>
								) : (
									<FormControl>
										<RadioGroup
											value={
												dutiesPaidOptions.find(
													(item) => watchDutiesPaid === item.value,
												)?.id
											}
											onValueChange={(e) => {
												form.setValue(
													'dutiesPaid',
													dutiesPaidOptions.find((item) => e === item.id)
														?.value,
													shouldUpdate,
												);
											}}
											className="flex flex-col space-y-1"
										>
											{dutiesPaidOptions.map((item) => {
												return (
													<FormRadioGroupVerticalItem key={item.id} {...item} />
												);
											})}
										</RadioGroup>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						</div>
						<div className="sm:col-span-3">
							<FormField
								control={form.control}
								name="conditions"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.addshipment.manual.conditions.label',
												'Applicable terms and conditions',
											)}
										</FormLabel>
										{isFieldReadOnly(readOnlyFields!, 'conditions') ? (
											<FormFieldReadOnly
												value={
													conditionOptions.find(
														(item) => field.value === item.value,
													)?.label ?? '-'
												}
											/>
										) : (
											<FormControl>
												<RadioGroup
													onValueChange={field.onChange}
													value={field.value}
													className="flex flex-col space-y-1"
												>
													{conditionOptions.map((item) => {
														return (
															<FormRadioGroupVerticalItem
																key={item.id}
																{...item}
															/>
														);
													})}
												</RadioGroup>
											</FormControl>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="sm:col-span-3">
							<FormField
								control={form.control}
								name="shipperReference"
								render={({ field }) => (
									<FormItem>
										<FormLabel
											className={invoiceRequired ? 'required-asterix' : ''}
										>
											{t(
												'pages.addshipment.manual.invoice_ref.label',
												'Invoice reference',
											)}
										</FormLabel>
										{isFieldReadOnly(readOnlyFields!, 'shipperReference') ? (
											<FormFieldReadOnly value={field.value ?? '-'} />
										) : (
											<FormControl>
												<Input {...field} />
											</FormControl>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="sm:col-span-3">
							<FormField
								control={form.control}
								name="publishUntilDate"
								render={({ field }) => (
									<FormItem>
										<FormLabel className="required-asterix">
											{t(
												'pages.addshipment.manual.publish_until.label',
												'Publish until',
											)}
										</FormLabel>
										{isFieldReadOnly(readOnlyFields, 'publishUntilDate') ? (
											<FormFieldReadOnly
												value={formatDatetime(field.value) ?? '-'}
											/>
										) : (
											<FormControl>
												<DateTimePicker
													refCallback={field.ref}
													defaultValue={field.value}
													dateFormat="dd-MM-yyyy HH:mm"
													timeCaption={t('general.time', 'Time')!}
													timeIntervals={10}
													showTimeSelect
													onChange={(date) =>
														field.onChange(convertToIsoDateWithTimezone(date))
													}
													customInput={<Input />}
													minDate={new Date()}
												/>
											</FormControl>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}
