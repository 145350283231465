import { Card, CardContent, CardHeader, CardTitle, Label } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LaneDetailContext } from './detail-context';
import { MetaDataContext } from '@uturn/portal/context';
import { formatPrice } from '@uturn/portal/utils';

export function ShipmentDetails() {
	const details = useContext(LaneDetailContext)!;
	const { shipmentMetaData } = useContext(MetaDataContext);
	const { t } = useTranslation();
	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.detail.card.shipment.title', 'Shipment')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<dl>
					<dt>
						<Label>
							{t('pages.lanes.detail.card.shipment.lane-name', 'Lane name')}
						</Label>
					</dt>
					<dd>{details.reference ?? '-'}</dd>
				</dl>
				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.shipment.frequency.label',
								'Frequency',
							)}
						</Label>
					</dt>
					<dd>
						{/* TODO: Double check if this works */}
						{t(
							'pages.lanes.detail.card.shipment.frequency.value',
							'{{ amount }} per {{ recurrence }}',
							{
								amount: details.containerFrequency.value,
								recurrence: (
									t(
										`general.frequency.${details.containerFrequency.frequency.toLowerCase()}`,
										details.containerFrequency.frequency.toLowerCase(),
									) as unknown as string
								).toLowerCase(),
							},
						)}
					</dd>
				</dl>
				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.shipment.shipment-target-price',
								'Shipment target price (excl. UTURN Fee)',
							)}
						</Label>
					</dt>
					<dd>
						{details.pricePerContainer
							? formatPrice(
									details.pricePerContainer.quantity,
									details.pricePerContainer.currencyCode,
								)
							: '-'}
					</dd>
				</dl>

				<div className="grid grid-flow-col gap-3">
					<dl>
						<dt>
							<Label>
								{t(
									'pages.lanes.detail.card.shipment.lane-starts',
									'Lane starts',
								)}
							</Label>
						</dt>
						<dd>
							{details.laneStartDate
								? new Intl.DateTimeFormat().format(
										new Date(details.laneStartDate),
									)
								: '-'}
						</dd>
					</dl>
					<dl>
						<dt>
							<Label>
								{t('pages.lanes.detail.card.shipment.lane-ends', 'Lane ends')}
							</Label>
						</dt>
						<dd>
							{details.laneEndDate
								? new Intl.DateTimeFormat().format(
										new Date(details.laneEndDate),
									)
								: '-'}
						</dd>
					</dl>
				</div>
				<dl>
					<dt>
						<Label>
							{t('pages.lanes.detail.card.shipment.remarks', 'Remarks')}
						</Label>
					</dt>
					<dd>{details.remarks ?? '-'}</dd>
				</dl>
				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.shipment.requirements',
								'Requirements',
							)}
						</Label>
					</dt>
					<dd>
						{details.requirements.length > 0
							? details.requirements
									?.map(
										(code: string) =>
											shipmentMetaData?.shipmentRequirements?.find(
												(requirement) => requirement.code === code,
											)?.description ?? code,
									)
									.join(', ')
							: '-'}
					</dd>
				</dl>
			</CardContent>
		</Card>
	);
}
