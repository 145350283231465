import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { ShipmentLocationActionRequestDtoLocationActionType as LocationActionType } from '@uturn/api/v1';
import { Icon } from '@uturn/ui';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { TransportTypeCode } from '@uturn/portal/types/shipment';

export function AddShipmentLocationDropdown({
	label,
	insert,
}: {
	label: string;
	insert: (locationActionType: LocationActionType) => void;
}) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<DropdownMenu open={open} onOpenChange={setOpen}>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="sm">
					<span className="flex items-center text-sm font-medium">
						<Icon icon={faPlusCircle} className="mr-2 size-5" />
						{label}
					</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" className="w-[200px]">
				<DropdownMenuGroup>
					<DropdownMenuItem onSelect={() => insert(LocationActionType.DELIVER)}>
						{t(
							'pages.shipment-details.add-shipment-location.options.deliver',
							'Deliver',
						)}
					</DropdownMenuItem>
					<DropdownMenuItem onSelect={() => insert(LocationActionType.LOAD)}>
						{t(
							'pages.shipment-details.add-shipment-location.options.load',
							'Load',
						)}
					</DropdownMenuItem>
					<DropdownMenuItem onSelect={() => insert(LocationActionType.STOP)}>
						{t(
							'pages.shipment-details.add-shipment-location.options.stop',
							'Stop',
						)}
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export function AddShipmentLocationButton({
	label,
	insert,
}: {
	label: string;
	insert: (locationActionType: LocationActionType) => void;
}) {
	return (
		<Button
			variant="ghost"
			size="sm"
			onClick={(e) => {
				e.preventDefault();
				insert(LocationActionType.STOP);
			}}
		>
			<span className="flex items-center text-sm font-medium">
				<Icon icon={faPlusCircle} className="mr-2 size-5" />
				{label}
			</span>
		</Button>
	);
}

export function AddShipmentLocation({
	insert,
}: {
	insert: (locationActionType: LocationActionType) => void;
}) {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();
	const transportType = form.getValues('transportType');

	return (
		<div className="relative flex w-full items-center justify-center pt-6">
			{transportType === TransportTypeCode.SHUNT ? (
				<AddShipmentLocationButton
					label={t(
						'pages.shipment-details.add-shipment-location.button-shunt',
						'Add extra stop',
					)}
					insert={insert}
				/>
			) : (
				<AddShipmentLocationDropdown
					label={t(
						'pages.shipment-details.add-shipment-location.button',
						'Add extra location or stop',
					)}
					insert={insert}
				/>
			)}
		</div>
	);
}
