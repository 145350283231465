import { InviteUserRequestRole } from '@uturn/api/v2';
import { z } from '@uturn/portal/zod';
import { phoneNumberSchema } from '@uturn/portal/zod/schema';
import { RequiredField } from '@uturn/portal/zod/utils';

export const formInviteUserSchema = z.object({
	role: z.nativeEnum(InviteUserRequestRole).describe(RequiredField),
	firstName: z.string().describe(RequiredField),
	lastName: z.string().describe(RequiredField),
	email: z.string().email().describe(RequiredField),
	phoneNumber: phoneNumberSchema.optional(),
});
