const isMetaKey = (key: string) => {
	const metaKeys = [
		'Shift',
		'Control',
		'Alt',
		'Meta',
		'CapsLock',
		'Tab',
		'Enter',
		'Escape',
		'ArrowLeft',
		'ArrowUp',
		'ArrowRight',
		'ArrowDown',
		'Home',
		'End',
		'PageUp',
		'PageDown',
		'Insert',
		'Delete',
		'Backspace',
		'ContextMenu',
		'F1',
		'F2',
		'F3',
		'F4',
		'F5',
		'F6',
		'F7',
		'F8',
		'F9',
		'F10',
		'F11',
		'F12',
	];
	return metaKeys.includes(key);
};

export default isMetaKey;
