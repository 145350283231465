import type { PageableResponseGroupDto } from '@uturn/api/v1';
import { useGetGroups } from '@uturn/api/v1';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupsPage } from '../../layouts';

export function GroupedShipments() {
	const { t } = useTranslation();
	const [page, setPage] = useState(0);

	const { data: groupsData, queryKey: groupsQueryKey } = useGetGroups({
		pageable: {
			page,
		},
	});

	if (!groupsData?.data) {
		return null;
	}

	return (
		<>
			<Helmet
				title={t('pages.shipments.grouped.title', 'Grouped shipments')!}
			/>
			<ShipmentGroupsPage
				data={groupsData.data}
				page={page}
				groupsQueryKey={groupsQueryKey}
				setPage={setPage}
			/>
		</>
	);
}
