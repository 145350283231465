import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.captureConsoleIntegration({ levels: ['error'] }),
		Sentry.feedbackIntegration({
			colorScheme: 'light',
			autoInject: false,
			attachTo: document.getElementById('test'),
			showBranding: false,
			enableScreenshot: false, // Looks like screenshot is currently broken, so we disable it for now
			showName: false, // Name can currently not be autofilled, tried both name and fullName in the setUser but didn't get it to work
		}),
	],
	environment: APP_ENV,
	enabled: !!APP_ENV && APP_ENV !== 'development',

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: [
		'portal.uturn-now.com',
		'portal-staging.uturn-now.com',
		/.*uturn-vercel.app/,
	],
});
