import { isAuthenticated } from '@uturn/api';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Outlet,
	createBrowserRouter,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { LoadingSpinnerWithLogo } from '../components';
import AppLayout from '../layout';
import { Logout } from '../pages/logout';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const router = createBrowserRouter([
	{
		index: true,
		Component: () => {
			const navigate = useNavigate();
			const [searchParams] = useSearchParams();

			useEffect(() => {
				const authenticated = isAuthenticated();

				if (authenticated) {
					navigate('/shipments');
				} else {
					const email = searchParams.get('email');
					navigate('/sign-in', {
						state: { email },
					});
				}
			}, [navigate]);

			return null;
		},
	},
	{ path: '/logout', Component: Logout },
	{
		Component: () => {
			const navigate = useNavigate();
			const [searchParams] = useSearchParams();
			const { t } = useTranslation();

			const referrer =
				window.location.pathname === '/login' ||
				window.location.pathname === '/sign-in'
					? '/shipments'
					: window.location.pathname;

			useEffect(() => {
				if (!isAuthenticated()) {
					const email = searchParams.get('email');
					navigate('/sign-in', { state: { email, referrer } });
				}
			}, []);

			return (
				<Suspense fallback={<LoadingSpinnerWithLogo />}>
					<AppLayout />
				</Suspense>
			);
		},
		children: protectedRoutes,
	},
	{
		Component: () => {
			const navigate = useNavigate();
			useEffect(() => {
				if (isAuthenticated()) {
					navigate('/');
				}
			}, []);

			return <Outlet />;
		},
		children: publicRoutes,
	},
]);
