import { AllowedTo } from 'react-abac';
import { Permission } from '@uturn/portal/abac';
import {
	ShipmentConceptSavedModal,
	ShipmentGroupConceptSavedModal,
	ShipmentGroupPublishedModal,
	ShipmentPublishDeniedModal,
	ShipmentPublishedModal,
} from '@uturn/portal/modules/shipments/components';
import { TypeOfShipment } from '@uturn/portal/types/shipment';

export function getShipmentModalContent(
	isConcept: boolean,
	shipmentType: TypeOfShipment,
	shipmentNumber: string | undefined,
	navigate: any,
	copyShipment: () => void,
	numberOfShipments: number,
	shipmentGroupName: string | undefined,
) {
	if (isConcept) {
		if (shipmentType === TypeOfShipment.GROUP) {
			return (
				<ShipmentGroupConceptSavedModal
					shipmentGroupName={shipmentGroupName ?? ''}
					navigate={navigate}
					copyShipment={copyShipment}
				/>
			);
		}
		return (
			<ShipmentConceptSavedModal
				shipmentNumber={shipmentNumber ?? ''}
				navigate={navigate}
				copyShipment={copyShipment}
			/>
		);
	}

	if (shipmentType === TypeOfShipment.GROUP) {
		return (
			<AllowedTo
				perform={Permission.PUBLISH_SHIPMENT}
				no={() => <ShipmentPublishDeniedModal copyShipment={copyShipment} />}
			>
				<ShipmentGroupPublishedModal
					numberOfShipments={numberOfShipments}
					shipmentGroupName={shipmentGroupName ?? ''}
					navigate={navigate}
					copyShipment={copyShipment}
				/>
			</AllowedTo>
		);
	}
	return (
		<AllowedTo
			perform={Permission.PUBLISH_SHIPMENT}
			no={() => <ShipmentPublishDeniedModal copyShipment={copyShipment} />}
		>
			<ShipmentPublishedModal
				shipmentNumber={shipmentNumber ?? ''}
				navigate={navigate}
				copyShipment={copyShipment}
			/>
		</AllowedTo>
	);
}
