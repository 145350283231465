import type { QueryKey } from '@tanstack/react-query';
import { getGetKeyQueryKey, useGetKey, useMetaData } from '@uturn/api/v1';
import { MeiliSearch } from 'meilisearch';
import { createContext, useContext } from 'react';

export const MeiliSearchContext = createContext<MeiliSearch | null>(null);

export const useMeiliSearch = () => {
	const meiliSearch = useContext(MeiliSearchContext);
	if (!meiliSearch) {
		throw new Error('MeiliSearchContext is not defined');
	}
	return meiliSearch;
};

export const useMeiliSearchKey = (): QueryKey => {
	const { data: metaData } = useMetaData();
	const meiliSearchKey = getGetKeyQueryKey();
	const userId = metaData?.data.user?.id;
	if (!userId) {
		return meiliSearchKey;
	}
	return [...meiliSearchKey, userId];
};

export const meiliSearchIndex = (meiliSearch: MeiliSearch) => {
	const shipmentsIndex = meiliSearch.index(
		import.meta.env.VITE_MEILISEARCH_INDEX,
	);
	return shipmentsIndex;
};

export const MeiliSearchProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const queryKey = useMeiliSearchKey();
	const { data: apiKey } = useGetKey({
		query: {
			staleTime: 1000 * 60 * 10, // 10 minutes
			refetchInterval: 1000 * 60 * 29, // 29 minutes
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: false,
			queryKey,
		},
	});

	const meiliSearch = new MeiliSearch({
		host: import.meta.env.VITE_MEILISEARCH_HOST,
		apiKey: apiKey?.data,
	});

	return (
		<MeiliSearchContext.Provider value={meiliSearch}>
			{children}
		</MeiliSearchContext.Provider>
	);
};
