import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons';
import { authenticate } from '@uturn/api';
import type { CommandPaletteAction } from '@uturn/ui';

type LoginProfiles = {
	[key: string]: {
		email: string;
		password: string;
	};
};

export function loginProfiles() {
	const profiles: LoginProfiles = JSON.parse(
		import.meta.env.VITE_LOGIN_PROFILES ?? '[]',
	) as unknown as LoginProfiles;

	const profileActions: CommandPaletteAction[] = Object.keys(profiles).map(
		(key) => ({
			name: `Login with ${key}`,
			icon: faRightToBracket,
			onAction: () => {
				authenticate({
					username: profiles[key].email,
					password: profiles[key].password,
				}).then(() => {
					window.location.href = '/shipments';
				});
			},
		}),
	);

	return profileActions;
}
