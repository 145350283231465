import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { DraftedShipments as DraftedShipmentsEmptyState } from '@uturn/design-tokens/empty-states';
import { Button, EmptyState, Link } from '@uturn/ui';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupDetailContext } from '../../providers/shipment-group-detail';
import {
	RequirementBadges,
	ShipmentPage,
} from '@uturn/portal/modules/shipments/components';
import { formatPrice } from '@uturn/portal/utils';

function DraftedShipmentEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<DraftedShipmentsEmptyState className="md:h-80" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t('pages.shipments.drafted.emptyState.title', 'To the drawing board!')}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.drafted.emptyState.description',
					'Here your drafts will be stored. You can publish them to the market or keep them as templates to use for future shipments.',
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button variant="primary" size="base">
						{t('pages.shipments.actions.start', 'Get started')}
					</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

export function DraftedGroupShipments() {
	const { t } = useTranslation();
	const { groupName } = useContext(ShipmentGroupDetailContext);

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.containerNumber'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.price'),
			value: formatPrice(
				shipment.price?.quantity ?? 0,
				shipment.price?.currencyCode ?? CurrencyType.EUR,
			),
			visibleOnMultiSelect: true,
		},
		{
			label: t('pages.shipments.details.requirements'),
			value: shipment.requirements && (
				<RequirementBadges requirements={shipment.requirements} />
			),
		},
	];

	return (
		<>
			<Helmet
				title={
					t(
						'pages.shipments.groups.drafted.title',
						'{{groupName}} - Drafted shipments',
						{
							groupName,
						},
					)!
				}
			/>
			<ShipmentPage
				enableGroupFilter={false}
				EmptyState={DraftedShipmentEmptyState}
				details={shipmentDetails}
			/>
		</>
	);
}
