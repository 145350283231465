import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export function CarriersLayout() {
	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t('pages.carriers.pagetitle', 'Carriers')!} />
			<Outlet />
		</>
	);
}
