import { ConditionType, CurrencyType } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';

/**
 * preferredCarrierIds: show if carriers.length > 0
 */
export const preferredCarrierIdsSchema = z.coerce.number().positive().array();

/**
 * Move onChange regex validation here.
 */
export const priceSchema = z.object({
	// quantity: z.string().min(1).max(5).pipe(z.coerce.number()),
	// quantity: z.coerce.number().max(99999).positive(),
	quantity: z.number().nonnegative(),
	currencyCode: z.nativeEnum(CurrencyType).default('EUR'),
});
// { required_error: 'Price is required' }
// { message: 'Price must be greater than 0' }

/**
 * Acceptance price
 * */
export const fixedPriceSchema = z.boolean();

export const publishUntilDateSchema = z.string().datetime({ offset: true });
// { required_error: 'Publish until date is required' }

/**
 * Invoice reference
 *
 * required if requiresInvoiceReference
 */
export const shipperReferenceSchema = z.string();

/** True means requires VAT */
export const dutiesPaidSchema = z.boolean();

export const conditionsSchema = z.nativeEnum(ConditionType);
