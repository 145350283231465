import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import {
	useFetchOrganizationById,
	useUpdateUserAssociation,
} from '@uturn/api/v1';
import type {
	OrganizationUserDto,
	UpdateUserAssociationBody,
} from '@uturn/api/v1';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	sonner,
} from '@uturn/ui-kit';
import type { TFunction } from 'i18next';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MetaDataContext } from '@uturn/portal/context';

function UserDialog({
	userId,
	type,
}: {
	userId: number;
	type: UpdateUserAssociationBody;
}) {
	const { t } = useTranslation();
	const words: Record<
		UpdateUserAssociationBody,
		{
			success: string;
			action: string;
			locale: { key: string; default: string };
		}
	> = {
		APPROVE: {
			success: 'accepted',
			action: 'accept',
			locale: {
				key: 'general.actions.accept',
				default: 'Accept',
			},
		},
		DECLINE: {
			success: 'declined',
			action: 'decline',
			locale: {
				key: 'general.actions.decline',
				default: 'Decline',
			},
		},
		REMOVE: {
			success: 'removed',
			action: 'remove',
			locale: {
				key: 'general.actions.remove',
				default: 'Remove',
			},
		},
	};
	const { mutate, isSuccess, isError, failureReason } =
		useUpdateUserAssociation();

	useEffect(() => {
		if (isSuccess) {
			sonner.success(
				t(
					'pages.account.organisation.users.modal.action.submit.success.description',
					{
						action: words[type].success,
						defaultValue: 'User has been {{ action }}',
					},
				),
			);

			// TODO: this is not pretty, we should remove the user from the list or use websockets
			window.location.reload();
		}
		if (isError) {
			sonner.error(
				failureReason?.toString() ||
					t('general.error-occured', 'Sorry, an error occurred.'),
			);
		}
	}, [isSuccess, isError]);

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button
					type="button"
					variant={type !== 'APPROVE' ? 'outline' : 'default'}
				>
					{t(words[type].locale.key, words[type].locale.default)}
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t('pages.account.organisation.users.modal.action.title', {
							action: t(words[type].locale.key, words[type].locale.default),
							defaultValue: '{{ action }} this user',
						})}
					</DialogTitle>
				</DialogHeader>
				<p className="text-secondary-800">
					{/* TODO: .action should probably be translated as well (see above) */}
					{t('pages.account.organisation.users.modal.action.description', {
						action: t(words[type].locale.key, words[type].locale.default),
						defaultValue: 'Are you sure you want to {{ action }} this user?',
					})}
				</p>
				<DialogFooter>
					<DialogClose asChild>
						<Button
							type="button"
							onClick={() => mutate({ id: userId, data: type })}
							variant={type !== 'APPROVE' ? 'destructive' : 'default'}
						>
							{t(
								'pages.account.organisation.users.modal.action.form.submit',
								'Yes, I am sure',
							)}
						</Button>
					</DialogClose>
					<DialogClose asChild>
						<Button type="button" variant="outline">
							{t('general.cancel', 'Cancel')}
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

function UserAction({
	isPending,
	userId,
}: {
	isPending: boolean;
	userId: number;
}) {
	if (isPending) {
		return (
			<div className="flex gap-3">
				<UserDialog userId={userId} type="APPROVE" />
				<UserDialog userId={userId} type="DECLINE" />
			</div>
		);
	}

	return <UserDialog userId={userId} type="REMOVE" />;
}

const columns = (isAdmin: boolean, t: TFunction<'translation', undefined>) =>
	[
		{
			header: t('general.name', 'name')!,
			accessorFn: (user) => `${user.firstName ?? ''} ${user.lastName ?? ''}`,
		},
		{
			header: t('general.email', 'email')!,
			accessorKey: 'email',
		},
		{
			header: t('general.role', 'role')!,
			accessorFn: (user) => (user.role === 'S_ADMIN' ? 'Admin' : 'User'),
		},
		...(isAdmin
			? [
					{
						id: 'actions',
						header: '',
						cell: (cell) => {
							const user = cell.row.original;

							return (
								!user.role.includes('S_ADMIN') && (
									<UserAction
										userId={user.id}
										isPending={user.pendingToJoinOrganization ?? false}
									/>
								)
							);
						},
					} satisfies ColumnDef<OrganizationUserDto>,
				]
			: []),
	] satisfies ColumnDef<OrganizationUserDto>[];

export function OrganizationUsers() {
	const { orgId } = useParams();
	const { t } = useTranslation();
	const { metadata } = useContext(MetaDataContext);
	const {
		data: { data },
	} = useFetchOrganizationById(Number(orgId));
	const table = useReactTable({
		data:
			data?.users.sort((user1, user2) => {
				if (user1.role.includes('ADMIN') && user2.role.includes('ADMIN')) {
					return 0;
				}

				if (user1.role.includes('ADMIN')) {
					return -1;
				}

				return 1;
			}) ?? [],
		columns: columns(metadata!.user?.role === 'S_ADMIN', t),
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									{t('general.no-results', 'No results.')}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
		</>
	);
}
