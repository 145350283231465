import { useGetLocationHours } from '@uturn/api/v1';
import { SheetHeader, SheetTitle } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function ShipmentLocationOpeningHours({ id }: { id: number }) {
	const { t } = useTranslation();
	const { data } = useGetLocationHours(id, {
		query: { suspense: true },
	});

	return (
		<>
			<SheetHeader>
				<SheetTitle>
					<h2 className="text-2xl font-bold text-foreground">
						{t(
							'pages.shipment-detail.modal.location-opening-hours.heading',
							'Openingshours',
						)}
					</h2>
				</SheetTitle>
			</SheetHeader>

			<div className="flex flex-col gap-0.5 py-4 text-sm font-medium leading-6 text-foreground">
				{Object.keys(data?.data).map((key) => {
					if (key === 'id' || key === 'locationId' || key === 'weekdays')
						return null;

					return (
						<div
							key={key}
							className="grid grid-cols-[minmax(0,_2.5fr),_minmax(0,_7fr)]"
						>
							<span className="capitalize">{key}:</span>
							<span>{data?.data[key]}</span>
						</div>
					);
				})}
			</div>
		</>
	);
}
