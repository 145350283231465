import { Card, CardContent, CardHeader, CardTitle, Label } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LaneDetailContext } from './detail-context';
import { MetaDataContext } from '@uturn/portal/context';

export function ContainerDetails() {
	const details = useContext(LaneDetailContext)!;
	const { shipmentUnits } = useContext(MetaDataContext);
	const { t } = useTranslation();
	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.detail.card.container.title', 'Container')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<dl>
					<dt>
						<Label>{t('pages.lanes.detail.card.container.type', 'Type')}</Label>
					</dt>
					<dd>
						{details.shipmentUnit
							? shipmentUnits[details.shipmentUnit].description
							: '-'}
					</dd>
				</dl>
				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.container.average-cargo-weight',
								'Average cargo weight',
							)}
						</Label>
					</dt>
					<dd>
						{details.cargoWeight
							? `${details.cargoWeight.quantity} ${
									details.cargoWeight.unit?.toLowerCase() ?? 'kg'
								}`
							: '-'}
					</dd>
				</dl>

				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.container.shipping-lane',
								'Shipping line',
							)}
						</Label>
					</dt>
					<dd>
						{details.shippingLine ? details.shippingLine.description : '-'}
					</dd>
				</dl>
			</CardContent>
		</Card>
	);
}
