import { formatDatetime } from '.';

// @TODO make this a more permanent solution or add more logic
export function getRouteDate(
	dateFrom: string | undefined,
	dateUntil: string | undefined,
) {
	const options: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'short',
		hour: 'numeric',
		minute: '2-digit',
	};

	let routeDate = '';

	if (dateFrom && dateUntil) {
		if (dateFrom === dateUntil) {
			// @TODO make this a more permanent solution once all apps are on Nexturn and remove this hack which removes the timezone
			routeDate = `${formatDatetime(dateFrom, options)}`;
		} else {
			// @TODO make this a more permanent solution once all apps are on Nexturn and remove this hack which removes the timezone
			routeDate = `${formatDatetime(dateFrom, options)} - ${formatDatetime(
				dateUntil,
				options,
			)}`;
		}
	} else if (!dateFrom && dateUntil) {
		// @TODO make this a more permanent solution once all apps are on Nexturn and remove this hack which removes the timezone
		routeDate = `Until ${formatDatetime(dateUntil, options)}`;
	} else if (dateFrom && !dateUntil) {
		// @TODO make this a more permanent solution once all apps are on Nexturn and remove this hack which removes the timezone
		routeDate = `From ${formatDatetime(dateFrom, options)}`;
	}

	return routeDate;
}
