import { useTranslation } from 'react-i18next';

export enum ShipmentView {
	Comfortable = 'Comfortable',
	Compact = 'Compact',
	Table = 'Table',
}

const useShipmentViews = () => {
	const { t } = useTranslation();

	return [
		{
			title: t('shipments.views.comfortable', { defaultValue: 'Comfortable' }),
			value: ShipmentView.Comfortable,
		},
		{
			title: t('shipments.views.compact', { defaultValue: 'Compact' }),
			value: ShipmentView.Compact,
		},
		{
			title: t('shipments.views.table', { defaultValue: 'Table' }),
			value: ShipmentView.Table,
		},
	];
};

export default useShipmentViews;
