export type CancelShipmentsException =
	(typeof CancelShipmentsException)[keyof typeof CancelShipmentsException];

export const CancelShipmentsException = {
	ARCHIVED: 'ARCHIVED',
	GROUPS: 'GROUPS',
	NO_SHIPMENTS: 'NO_SHIPMENTS',
} as const;

export type CancelShipmentsExceptionMessage = {
	title: string;
	description: string;
};
