import { useEffect } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage({ error }: FallbackProps) {
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		// logout users in case they experience a 403 - FORBIDDEN
		if (error.response?.status === 403) {
			navigate('/logout');
		}
	}, []);

	// TODO: create a much nicer error page
	return (
		<>
			<Helmet title={t('pages.error.title', 'Error')!} />
			<p className="mt-4">
				{t(
					'pages.error.experienced_error',
					'Sorry, it seems you have experience an error:',
				)}{' '}
				{error.message || ''}
			</p>
		</>
	);
}
