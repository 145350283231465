import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	FetchCancellationReasons200Item as CancelReason,
	FetchCancellationReasonsStatus as CancelShipmentStatus,
	type DeleteGroupByIdParams,
	useDeleteGroupById,
	useFetchCancellationReasons,
} from '@uturn/api/v1';
import {
	type CancelShipmentsParams,
	GetShipmentsFilterStatusesItem as ShipmentFilterStatus,
	useCancelShipments,
} from '@uturn/api/v2';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DropdownMenuItem,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Textarea,
	sonner,
} from '@uturn/ui-kit';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type {
	CancelShipmentsException,
	CancelShipmentsExceptionMessage,
} from './exceptions';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import { z } from '@uturn/portal/zod';
import { addIssueRequired } from '@uturn/portal/zod/utils';

export function ShipmentsCancelDropdownMenuItem({
	setOpenModal,
	cancelShipmentsException,
}: {
	setOpenModal: (open: boolean) => void;
	cancelShipmentsException?: CancelShipmentsException;
}) {
	const { t } = useTranslation();

	const exceptions: Record<
		CancelShipmentsException,
		CancelShipmentsExceptionMessage
	> = {
		ARCHIVED: {
			title: t('general.invalid-action', 'Invalid action'),
			description: t(
				'pages.shipments_cancel.dialog.form.errors.deny_archived_shipments',
				'Archived shipments cannot be cancelled.',
			) as string,
		},
		GROUPS: {
			title: t('general.invalid-action', 'Invalid action'),
			description: t(
				'pages.shipments_cancel.dialog.form.errors.deny_groups_shipments',
				'Please cancel shipments for individual groups below instead.',
			) as string,
		},
		NO_SHIPMENTS: {
			title: t('general.invalid-action', 'Invalid action'),
			description: t(
				'pages.shipments_cancel.dialog.form.errors.deny_no_shipments',
				'No shipments selected. Nothing to cancel.',
			) as string,
		},
	};

	const onSelect = () => {
		if (cancelShipmentsException) {
			const exception = exceptions[cancelShipmentsException];
			sonner.error(exception.description);

			return;
		}
		setOpenModal(true);
	};
	return (
		<DropdownMenuItem onSelect={onSelect}>
			{t('pages.shippers.actions.cancel', 'Cancel')}
		</DropdownMenuItem>
	);
}

export function ShipmentsCancelModal({
	exportFilters,
	statuses,
	groupId,
	isOpenModal,
	setOpenModal,
	totalGroupShipments,
}: {
	exportFilters: CancelShipmentsParams | DeleteGroupByIdParams;
	statuses: ShipmentFilterStatus[];
	groupId?: number;
	isOpenModal: boolean;
	setOpenModal: (open: boolean) => void;
	totalGroupShipments?: number;
}) {
	const { t } = useTranslation();
	const { totalShipments } = useShipmentsSearchStore();

	const shipmentsStatus = useMemo((): CancelShipmentStatus | undefined => {
		if (!statuses.length) {
			return groupId ? CancelShipmentStatus.PUBLISHED : undefined;
		}
		if (statuses.includes(ShipmentFilterStatus.CONCEPT)) {
			return CancelShipmentStatus.CONCEPT;
		}
		if (statuses.includes(ShipmentFilterStatus.PUBLISHED)) {
			return CancelShipmentStatus.PUBLISHED;
		}
		if (statuses.includes(ShipmentFilterStatus.CARRIER_ASSIGNED)) {
			return CancelShipmentStatus.CARRIER_ASSIGNED;
		}
		return undefined;
	}, [statuses, groupId]);

	const { data: cancelReasons } = useFetchCancellationReasons(
		{
			status: shipmentsStatus!,
			isMultiCancel: true,
			isGroupCancel: !!groupId,
		},
		{ query: { enabled: !!shipmentsStatus } },
	);

	const totalCancelShipments = totalGroupShipments ?? totalShipments;

	const formSchema = z
		.object({
			reasonSelect: z.string().min(1, {
				message: t(
					'general.errors.form_validation.select_required',
					'Must select a {{propertyName}}',
					{ propertyName: 'reason' },
				) as string,
			}),
			reasonOtherExplanation: z
				.string()
				.max(255, {
					message: t(
						'general.errors.form_validation.max',
						'{{propertyName}} must be less than {{maxLength}} characters',
						{ propertyName: 'Reason', maxLength: 255 },
					) as string,
				})
				.optional(),
		})
		.superRefine((values, ctx) => {
			if (
				values.reasonSelect === CancelReason.OTHER &&
				!values.reasonOtherExplanation
			) {
				addIssueRequired(ctx, 'reasonOtherExplanation');
			}
		});

	type FormValues = z.infer<typeof formSchema>;

	const ref = useRef<HTMLFormElement>(null);

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			reasonSelect: '',
			reasonOtherExplanation: '',
		},
	});

	const watchReasonSelect = form.watch('reasonSelect');

	const mutation = {
		onSuccess: () => {
			sonner.success(
				t(
					'pages.shipments_cancel.dialog.form.submit.success',
					'Shipments have been submitted for cancellation. Please refresh.',
				),
			);

			form.reset();
			setOpenModal(false);
		},
		onError: () => {
			sonner.error(
				t(
					'pages.shipments_cancel.dialog.form.submit.error',
					'Could not submit shipments for cancellation.',
				),
			);

			setOpenModal(false);
		},
	};

	const { mutate: mutateDeleteGroupById, isLoading: deleteGroupByIdIsLoading } =
		useDeleteGroupById({ mutation });
	const { mutate: mutateCancelShipments, isLoading: cancelShipmentsIsLoading } =
		useCancelShipments({ mutation });

	const handleSubmit = (event: React.FormEvent) => {
		if (ref.current === null) return;

		event.preventDefault();
		ref.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const onSubmit = async (data: FormValues) => {
		const reason =
			data.reasonSelect === CancelReason.OTHER
				? data.reasonOtherExplanation
				: t(
						`general.shipments.cancel-reason.${data.reasonSelect}`,
						data.reasonSelect,
					);
		const options: any = {
			data: reason!,
			params: exportFilters,
		};
		if (groupId) {
			options.id = groupId;
			mutateDeleteGroupById(options);
			return;
		}

		mutateCancelShipments(options);
	};

	if (!shipmentsStatus) {
		return <></>;
	}

	return (
		<Dialog open={isOpenModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(
							'pages.shipments_cancel.dialog.heading',
							'Cancel {{totalCancelShipments}} shipments',
							{ totalCancelShipments },
						)}
					</DialogTitle>
					<DialogDescription>
						{groupId
							? t(
									'pages.grouped_shipments_cancel.dialog.description',
									'You are about to cancel all the shipments from this group.',
								)
							: t(
									'pages.shipments_cancel.dialog.description',
									'Change the shipment filters to modify the selection to cancel.',
								)}
						{totalGroupShipments && (
							<p>
								{t(
									'pages.shipments_cancel.dialog.group-note',
									'Note: Any archived shipments in this group will be ignored.',
								)}
							</p>
						)}
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						ref={ref}
						className="space-y-4"
					>
						<FormField
							control={form.control}
							name="reasonSelect"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'pages.shipments_cancel.dialog.form.reason.label',
											'Why do you want to cancel the current shipment selection?',
										)}
									</FormLabel>
									<Select
										{...field}
										onValueChange={field.onChange}
										disabled={
											deleteGroupByIdIsLoading || cancelShipmentsIsLoading
										}
									>
										<FormControl>
											<SelectTrigger>
												<SelectValue
													placeholder={
														<span>
															{t(
																'pages.shipments_cancel.dialog.form.reason.placeholder',
																'Select a reason',
															)}
														</span>
													}
												/>
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{cancelReasons?.data?.map(
												(reasonCode: CancelReason, index: number) => (
													<SelectItem key={index} value={reasonCode}>
														{t(
															`general.shipments.cancel-reason.${reasonCode}`,
															reasonCode as string,
														)}
													</SelectItem>
												),
											)}
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>
						{watchReasonSelect === CancelReason.OTHER && (
							<FormField
								control={form.control}
								name="reasonOtherExplanation"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Textarea
												{...field}
												onChange={field.onChange}
												disabled={
													deleteGroupByIdIsLoading || cancelShipmentsIsLoading
												}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						)}
					</form>
				</Form>
				<DialogFooter>
					<DialogClose asChild>
						<Button
							disabled={deleteGroupByIdIsLoading || cancelShipmentsIsLoading}
							variant="outline"
							onClick={() => form.reset()}
						>
							{t('pages.shipments_cancel.dialog.button.cancel', 'Cancel')}
						</Button>
					</DialogClose>
					<Button
						disabled={deleteGroupByIdIsLoading || cancelShipmentsIsLoading}
						onClick={handleSubmit}
					>
						{deleteGroupByIdIsLoading || cancelShipmentsIsLoading ? (
							<>
								<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
								{t(
									'pages.shipments_cancel.dialog.button.loading',
									'Canceling...',
								)}
							</>
						) : (
							t('pages.shipments_cancel.dialog.button.submit', 'Confirm')
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
