import type { GetShipmentsFilterStatusesItem } from '@uturn/api/v2';
import type {
	IDatasource,
	IServerSideDatasource,
	SortModelItem,
} from '@uturn/ui-kit';
import dayjs from 'dayjs';
import type MeiliSearch from 'meilisearch';
import type { SearchParams } from 'meilisearch';
import { useMeiliSearch } from '@uturn/portal/meilisearch';
import type { SearchShipment } from '@uturn/portal/modules/shipments/components/shipments-table/types';

type TextFilter = {
	filterType: 'text';
	type: 'equals';
	filter: 'string';
};
type DateFilter = {
	filterType: 'date';
	type: 'equals' | 'before' | 'after';
	dateFrom: 'string';
};
type InRangeDateFilter = {
	filterType: 'date';
	type: 'inRange';
	dateFrom: 'string';
	dateUntil: 'string';
};
type FilterModel = Record<string, TextFilter | DateFilter | InRangeDateFilter>;

function getSymbolForType(type: 'equals' | 'before' | 'after'): string {
	switch (type) {
		case 'equals':
			return '=';
		case 'before':
			return '<';
		case 'after':
			return '>';
		default:
			throw new Error(`Unsupported type: ${type}`);
	}
}

const formatFilterModel = (filterModel: FilterModel): Array<string> => {
	const filterKeys = Object.keys(filterModel);

	const arr: string[] = [];
	filterKeys.forEach((key) => {
		const filter = filterModel[key];
		const formattedKey = key.replace('locations.', '');

		// For now we only support equals on text
		if (filter.filterType === 'text') {
			arr.push(`${formattedKey} = "${filter.filter}"`);
			return;
		}

		if (filter.filterType === 'date') {
			if (filter.type === 'inRange') {
				arr.push(
					`${formattedKey}Timestamp >= ${dayjs(
						filter.dateFrom,
					).unix()} AND ${formattedKey}Timestamp <= ${dayjs(
						filter.dateUntil,
					).unix()}`,
				);
				return;
			}

			if (filter.type === 'equals') {
				arr.push(
					`${formattedKey}Timestamp > ${dayjs(
						filter.dateFrom,
					).unix()} AND ${formattedKey}Timestamp <= ${dayjs(filter.dateFrom)
						.add(1, 'day')
						.unix()}`,
				);
				return;
			}

			arr.push(
				`${formattedKey}Timestamp ${getSymbolForType(filter.type)} ${Date.parse(
					filter.dateFrom,
				)}`,
			);
		}
	});

	return arr;
};

const determineFilters = (
	statuses: GetShipmentsFilterStatusesItem[],
	filterModel: FilterModel,
	groupId?: string,
) => {
	const filter = formatFilterModel(filterModel);

	if (statuses.length > 0) {
		filter.push(`shipmentStatus IN [${statuses.join(',')}]`);
	}

	if (groupId) {
		filter.push(`groupId = ${groupId}`);
	}

	return filter;
};

// TODO: should probably revisit this
const determineSorting = (sortModel: SortModelItem[]) => {
	const dateColumns = ['dateFrom', 'dateUntil', 'eta', 'arrival', 'departure'];

	const sort = sortModel.map((item) => {
		const parts = item.colId.split('.');

		if (dateColumns.includes(parts[1])) {
			return `${item.colId}Timestamp:${item.sort}`;
		}

		return `${item.colId}:${item.sort}`;
	});

	return sort;
};

const getSearchShipments = async (
	meilisearch: MeiliSearch,
	options: SearchParams,
) => {
	const shipmentsIndex = meilisearch.index(
		import.meta.env.VITE_MEILISEARCH_INDEX,
	);
	const result = await shipmentsIndex.search<SearchShipment>('*', options);

	return result;
};

export const useDatasource = (
	pageSize: number,
	groupId?: string,
	statuses: GetShipmentsFilterStatusesItem[] = [],
): IDatasource => {
	const meilisearch = useMeiliSearch();

	return {
		getRows: async (params) => {
			try {
				await meilisearch.health();

				const page = Math.ceil(params.startRow / pageSize);

				const result = await getSearchShipments(meilisearch, {
					filter: determineFilters(statuses, params.filterModel, groupId),
					sort: determineSorting(params.sortModel),
					limit: pageSize,
					offset: page * pageSize,
				});

				const lastRow =
					result.hits.length < pageSize
						? params.startRow + result.hits.length
						: -1;

				params.successCallback(result.hits, lastRow);
			} catch (err) {
				console.error(err);
				params.failCallback();
			}
		},
	};
};

export const useServerSideDatasource = (
	pageSize: number,
	groupId?: string,
	statuses: GetShipmentsFilterStatusesItem[] = [],
): IServerSideDatasource => {
	const meilisearch = useMeiliSearch();

	return {
		getRows: async (params) => {
			try {
				await meilisearch.health();

				const startRow = params.request.startRow ?? 0;
				const page = Math.ceil(startRow / pageSize);

				const result = await getSearchShipments(meilisearch, {
					filter: determineFilters(
						statuses,
						params.request.filterModel as FilterModel,
						groupId,
					),
					sort: determineSorting(params.request.sortModel),
					limit: pageSize,
					offset: page * pageSize,
				});

				const lastRow =
					result.hits.length < pageSize ? startRow + result.hits.length : -1;

				params.success({
					rowData: result.hits,
					rowCount: lastRow,
				});
			} catch (err) {
				console.error(err);
				params.fail();
			}
		},
	};
};
