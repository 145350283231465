import { DateTimePicker } from '@uturn/ui';
import { type CustomCellEditorProps, Input } from '@uturn/ui-kit';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = CustomCellEditorProps;

const DateTimePickerEditor: FC<Props> = ({
	value,
	onValueChange,
	api,
	rowIndex,
	column,
}) => {
	const { t } = useTranslation();
	const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);

	const onChangeHandler = (dateValue: Date | null) => {
		setDate(dateValue);
		onValueChange(dateValue);
	};

	const onCalenderCloseHandler = () => {
		api.stopEditing();
		api.setFocusedCell(rowIndex, column.getColId());
	};

	return (
		<DateTimePicker
			popperClassName="ag-custom-component-popup"
			dateFormat="dd-MM-yyyy, HH:mm"
			placeholderText="Select date"
			portalId="root"
			customInput={<Input />}
			selected={date}
			onChange={onChangeHandler}
			minDate={new Date()}
			timeCaption={t('general.time', 'Time')!}
			timeIntervals={10}
			showTimeSelect
			onCalendarClose={onCalenderCloseHandler}
			/**
			 * This is bad practice, we should try and avoid using this, for now this seems like our only option
			 * This is a very specific use case so it shouldn't be that bad because it only renders, thus, auto focus only when u start editing a cell
			 */
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus
		/>
	);
};

export default DateTimePickerEditor;
