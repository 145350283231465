import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
	type ValidationsResult,
	newValidationsResult,
	validationsError,
} from '@uturn/portal/types';

export function usePriceValidation() {
	const { t } = useTranslation();
	/**
	 * price is required only during publishing.
	 *
	 * @param price
	 * @param publishing Publishing or updating published shipment
	 * @param validationsResult
	 * @returns
	 */
	const validatePrice = (
		price: number,
		publishing: boolean,
		validationsResult?: ValidationsResult,
	): ValidationsResult => {
		let results = validationsResult ?? newValidationsResult();

		if (!publishing) {
			return results;
		}

		const priceSchema = z
			.number({
				required_error: t(
					'general.errors.form_validation.required',
					'Required.',
				)!,
				invalid_type_error: t(
					'general.errors.form_validation.nan',
					'Must be a number.',
				)!,
			})
			.max(99999, {
				message: t('general.errors.price.too_big', 'This is too expensive.')!,
			})
			.positive({
				message: t(
					'general.errors.form_validation.too_small',
					'Must be greater than 0.',
				)!,
			});
		try {
			priceSchema.parse(price);
		} catch (err) {
			if (err instanceof z.ZodError) {
				results = validationsError(
					results,
					err.issues[0].message,
					'price.quantity',
				);
			}
		}
		return results;
	};
	return { validatePrice };
}
