import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

/**
 * ns 'zod' default error messages are available
 * at [github.com/colinhacks/zod](https://github.com/colinhacks/zod/blob/main/src/locales/en.ts#L150)
 */
const zodI18nMap = makeZodI18nMap({ ns: ['formValidation', 'zod'] });
z.setErrorMap(zodI18nMap);

export { z };
