import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

export type ChangeRequestLocations = {
	currentLocations: any[];
	newLocations: any[];
};

interface ShipmentDetailContextType {
	readOnlyFields: string[];
	setReadOnlyFields: (fields: string[]) => void;
	changeRequestChanges: any[];
	setChangeRequestChanges: (changes: any[]) => void;
	changeRequestLocations: ChangeRequestLocations;
	setChangeRequestLocations: (
		changeRequestLocations: ChangeRequestLocations,
	) => void;
}

export const ShipmentDetailContext = createContext<ShipmentDetailContextType>({
	readOnlyFields: [],
	setReadOnlyFields: () => {},
	changeRequestChanges: [],
	setChangeRequestChanges: () => {},
	changeRequestLocations: {
		currentLocations: [],
		newLocations: [],
	},
	setChangeRequestLocations: () => {},
});

export const ShipmentDetailProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [readOnlyFields, setReadOnlyFields] = useState<string[]>([]);
	const [changeRequestLocations, setChangeRequestLocations] =
		useState<ChangeRequestLocations>({
			currentLocations: [],
			newLocations: [],
		});
	const [changeRequestChanges, setChangeRequestChanges] = useState<any[]>([]);

	const value = useMemo(
		() => ({
			readOnlyFields,
			setReadOnlyFields,
			changeRequestLocations,
			setChangeRequestLocations,
			changeRequestChanges,
			setChangeRequestChanges,
		}),
		[
			readOnlyFields,
			setReadOnlyFields,
			changeRequestLocations,
			setChangeRequestLocations,
			changeRequestChanges,
			setChangeRequestChanges,
		],
	);

	return (
		<ShipmentDetailContext.Provider value={value}>
			{children}
		</ShipmentDetailContext.Provider>
	);
};
