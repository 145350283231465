import {
	faCheck,
	faChevronDown,
	faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import type { OrganizationDto, ShippingLineDTO } from '@uturn/api/v1';
import {
	OrganizationSettingsDtoContainerStatus,
	OrganizationSettingsDtoPreferredDistanceUnit,
	OrganizationSettingsDtoShipmentCondition,
	OrganizationSettingsDtoTransportType,
	useFetchOrganizationById,
	useUpdateOrganization,
} from '@uturn/api/v1';
import { P } from '@uturn/ui';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	RadioGroup,
	RadioGroupItem,
	Separator,
	sonner,
} from '@uturn/ui-kit';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { MetaDataContext } from '@uturn/portal/context';

export function OrganizationShipmentSettings() {
	const { t } = useTranslation();

	const { orgId } = useParams();

	const { shipmentMetaData } = useContext(MetaDataContext);
	const [openShippingLine, setOpenShippingLine] = useState(false);
	const [shippingLineList, setShippingLineList] = useState<ShippingLineDTO[]>(
		[],
	);

	const handleOnChangeShippingLines = (e: string) => {
		const value = e;

		const filteredSuggestions = structuredClone(
			shipmentMetaData?.shippingLines,
		)!.filter((suggestion: ShippingLineDTO) =>
			suggestion.description.toLowerCase().includes(value),
		);

		setShippingLineList(filteredSuggestions);
	};

	useEffect(() => {
		if (shipmentMetaData?.shippingLines.length > 0) {
			setShippingLineList(
				structuredClone(shipmentMetaData?.shippingLines ?? []),
			);
		}
	}, []);

	const { data: organization } = useFetchOrganizationById(Number(orgId));

	const form = useForm<OrganizationDto>({
		defaultValues: organization!.data!,
	});

	const { mutate } = useUpdateOrganization({
		mutation: {
			onSuccess: () => {
				sonner.success(
					t(
						'pages.account.organisation.shipment-settings.form.submit.success.description',
						'Organisation has been updated!',
					),
				);
			},
			onError: () => {
				sonner.error(t('general.try-again-later', 'Please try again later.'));
			},
		},
	});

	useEffect(() => {
		form.reset(organization?.data);
	}, [organization, form.reset]);

	// @TODO: Move or remove this helper function if API can return the correct type
	const formatBooleanValue = ({
		value,
		options,
	}: {
		value: boolean | undefined | null;
		options: [string, string, ...string[]];
	}) => {
		if (value === undefined || value === null) return '';

		return value ? options[0] : options[1];
	};

	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.shipment-settings.title',
						'Organisation - Settings',
					)!
				}
			/>
			<Suspense
				fallback={
					<P>
						{t(
							'pages.account.organisation.shipment-settings.loading',
							'Loading shipment settings',
						)}
					</P>
				}
			>
				<div className="space-y-6">
					<div>
						<h3 className="text-lg font-medium">
							{t(
								'pages.account.organisation.shipment-settings.title',
								'Shipment settings',
							)}
						</h3>
						<p className="text-sm text-muted-foreground">
							{t(
								'pages.account.organisation.shipment-settings.description',
								'These settings will be used as default for the creation of all your shipments.',
							)}
						</p>
					</div>
					<Separator />
					<Form {...form}>
						<form
							className="space-y-8"
							onSubmit={form.handleSubmit((formData) => {
								mutate({
									id: Number(orgId),
									data: formData satisfies OrganizationDto,
								});
							})}
						>
							<FormField
								control={form.control}
								name="settings.shippingLineId"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.shipping-line.label',
												'Shipping line',
											)}
										</FormLabel>
										<Popover
											open={openShippingLine}
											onOpenChange={setOpenShippingLine}
										>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant="outline"
														role="combobox"
														className={twJoin(
															'w-full justify-between bg-transparent',
															!field.value && 'text-muted-foreground',
														)}
													>
														{field.value && field.value !== -1
															? shipmentMetaData?.shippingLines?.find(
																	(shippingLine: ShippingLineDTO) =>
																		shippingLine.id === field.value,
																)?.description
															: t(
																	'pages.account.organisation.shipment-settings.form.shipping-line.placeholder',
																	'Select shipping line',
																)}
														<Icon icon={faChevronDown} />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent align="start" className="w-full p-0">
												<Command>
													<CommandInput
														placeholder={t(
															'pages.account.organisation.shipment-settings.form.shipping-line.search.placeholder',
															'Search shipping line...',
														).toString()}
														onValueChange={handleOnChangeShippingLines}
													/>
													<CommandEmpty>
														{t(
															'pages.account.organisation.shipment-settings.form.shipping-line.search.empty',
															'No shipping line found.',
														)}
													</CommandEmpty>
													<CommandGroup className="h-[300px] overflow-y-scroll">
														{field.value && field.value !== -1 && (
															<CommandItem
																value="None"
																className="py-2 font-bold"
																onSelect={() => {
																	form.setValue('settings.shippingLineId', -1);
																	setOpenShippingLine(false);
																	setShippingLineList(
																		structuredClone(
																			shipmentMetaData?.shippingLines ?? [],
																		),
																	);
																}}
															>
																<Icon
																	icon={faCheck}
																	className={twJoin(
																		'mr-2 h-4 w-4',
																		!field.value ? 'opacity-100' : 'opacity-0',
																	)}
																/>
																{t('general.none', 'None')}
															</CommandItem>
														)}
														{shippingLineList.map(
															(shippingLine: ShippingLineDTO) => (
																<CommandItem
																	value={shippingLine.description}
																	key={shippingLine.code}
																	onSelect={() => {
																		const { id } = shippingLine;

																		if (shippingLine.id === field.value) {
																			form.setValue(
																				'settings.shippingLineId',
																				-1,
																			);
																		} else {
																			form.setValue(
																				'settings.shippingLineId',
																				id,
																			);
																		}

																		setOpenShippingLine(false);
																	}}
																>
																	<Icon
																		icon={faCheck}
																		className={twJoin(
																			'mr-2 h-4 w-4',
																			shippingLine.id === field.value
																				? 'opacity-100'
																				: 'opacity-0',
																		)}
																	/>
																	{shippingLine.description}
																</CommandItem>
															),
														)}
													</CommandGroup>
												</Command>
											</PopoverContent>
										</Popover>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.invoiceRequired"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.invoice-ref-required.label',
												'Invoice reference required',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e === 'yes');
												}}
												value={formatBooleanValue({
													value: field.value,
													options: ['yes', 'no'],
												})}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="yes" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.invoice-ref-required.values.yes',
															'Yes',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="no" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.invoice-ref-required.values.no',
															'No',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.transportType"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.transport-type.label',
												'Transport type',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e);
												}}
												value={field.value}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoTransportType.IMPORT
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.transport-type.values.import',
															'Import',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoTransportType.EXPORT
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.transport-type.values.export',
															'Export',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={OrganizationSettingsDtoTransportType.SHUNT}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.transport-type.values.shunt',
															'Shunt',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.fixedPrice"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.carrier-can.label',
												'Carrier can',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e === 'bid-accept');
												}}
												value={formatBooleanValue({
													value: field.value,
													options: ['bid-accept', 'only-bid'],
												})}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="only-bid" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.carrier-can.values.bid-only',
															'Bid only',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="bid-accept" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.carrier-can.values.bid-only-accept',
															'Bid or accept',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.requiresVat"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.goods-cleared.label',
												'Goods cleared',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e === 'regular');
												}}
												value={formatBooleanValue({
													value: field.value,
													options: ['regular', 'free'],
												})}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="free" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.goods-cleared.values.free-vat',
															'Free of VAT',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="regular" />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.goods-cleared.values.regular-vat',
															'Regular VAT',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.containerStatus"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.container-status.label',
												'Container status',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e);
												}}
												value={field.value}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoContainerStatus.FULL
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.container-status.values.full',
															'Full',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoContainerStatus.EMPTY
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.container-status.values.empty',
															'Empty',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.shipmentCondition"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.shipment-settings.form.shipment-conditions.label',
												'Terms and conditions',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(e);
												}}
												value={field.value}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoShipmentCondition.AVC
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.shipment-conditions.values.avc',
															'AVC',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoShipmentCondition.CMR
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'pages.account.organisation.shipment-settings.form.shipment-conditions.values.cmr',
															'CMR',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoShipmentCondition.RHA
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'general.default-shipment-values.shipment-conditions.values.rha',
															'RHA',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="settings.preferredDistanceUnit"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'organisation.default-shipment-values.form.preferred-distance-unit.label',
												'Preferred distance unit',
											)}
										</FormLabel>
										<FormControl>
											<RadioGroup
												onValueChange={field.onChange}
												value={field.value}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoPreferredDistanceUnit.kilometer
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'organisation.default-shipment-values.form.preferred-distance-unit.values.km',
															'Kilometers',
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem
															value={
																OrganizationSettingsDtoPreferredDistanceUnit.mile
															}
														/>
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{t(
															'organisation.default-shipment-values.form.preferred-distance-unit.values.mi',
															'Miles',
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('general.actions.save', 'Save')}
							</Button>
						</form>
					</Form>
				</div>
			</Suspense>
		</>
	);
}
