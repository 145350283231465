import {
	AdditionalRequirement,
	ContainerSizeEnum,
	PriceCalculatorTransportType,
	type PriceRequestDto,
} from '@uturn/api/v1';

export const defaultValues: PriceRequestDto = {
	transportType: PriceCalculatorTransportType.IMPORT,
	containerSize: ContainerSizeEnum.UNKNOWN,
	additionalRequirement: AdditionalRequirement.NONE,
	locations: [{}, {}, {}],
	email: '',
};
