import {
	LocationTypeEnum as ActionType,
	AdditionalRequirement,
	type AddressDTO,
	ConditionKey,
	ContainerSizeEnum,
	type PriceRequestDto,
	PriceCalculatorTransportType as TransportType,
} from '@uturn/api/v1';
import type { TFunction } from 'i18next';

export type PriceRequestFormProps = {
	defaultValues: PriceRequestDto;
	onSubmit: (data: PriceRequestDto) => void;
	onTripChange: (data: AddressDTO[]) => void;
	additionalRequirementEnabledFeatures?: ConditionKey[];
};

export const additionalRequirementOptions = (
	t: TFunction<'translation', undefined>,
): {
	label: string;
	value: AdditionalRequirement;
	conditionKey?: ConditionKey;
}[] => [
	{
		label: t(
			`pages.price-calculator.form.fields.additional-requirements.options.${AdditionalRequirement.NONE.toLowerCase()}`,
			'None',
		),
		value: AdditionalRequirement.NONE,
	},
	{
		label: t(
			`pages.price-calculator.form.fields.additional-requirements.options.${AdditionalRequirement.ADR.toLowerCase()}`,
			'ADR transport',
		),
		value: AdditionalRequirement.ADR,
		conditionKey: ConditionKey.ADR_TRANSPORT,
	},
	{
		label: t(
			`pages.price-calculator.form.fields.additional-requirements.options.${AdditionalRequirement.GENSET.toLowerCase()}`,
			'Gen-set transport',
		),
		value: AdditionalRequirement.GENSET,
		conditionKey: ConditionKey.GENERATOR_SET_TRANSPORT,
	},
];

export const transportTypeOptions = (
	t: TFunction<'translation', undefined>,
): {
	label: string;
	value: TransportType;
}[] => [
	{
		label: t(`general.transport-type.${TransportType.IMPORT}`, 'Import'),
		value: TransportType.IMPORT,
	},
	{
		label: t(`general.transport-type.${TransportType.EXPORT}`, 'Export'),
		value: TransportType.EXPORT,
	},
	{
		label: t(`general.transport-type.${TransportType.SHUNT}`, 'Shunt'),
		value: TransportType.SHUNT,
	},
];

export const containerSizeOptions = (
	t: TFunction<'translation', undefined>,
): {
	label: string;
	value: ContainerSizeEnum;
}[] => [
	{
		label: t('general.container-sizes.unknown', 'Unknown'),
		value: ContainerSizeEnum.UNKNOWN,
	},
	{
		label: t(`general.container-sizes.${ContainerSizeEnum.SIZE_20FT}`, '20FT'),
		value: ContainerSizeEnum.SIZE_20FT,
	},
	{
		label: t(`general.container-sizes.${ContainerSizeEnum.SIZE_30FT}`, '30FT'),
		value: ContainerSizeEnum.SIZE_30FT,
	},
	{
		label: t(`general.container-sizes.${ContainerSizeEnum.SIZE_40FT}`, '40FT'),
		value: ContainerSizeEnum.SIZE_40FT,
	},
	{
		label: t(`general.container-sizes.${ContainerSizeEnum.SIZE_45FT}`, '45FT'),
		value: ContainerSizeEnum.SIZE_45FT,
	},
];

export const locationTypeName = (
	t: TFunction<'translation', undefined>,
): Record<ActionType, string> => ({
	[ActionType.PICKUP]: t(
		`general.location-type.${ActionType.PICKUP.toLowerCase()}`,
		'Pick-up',
	),
	[ActionType.DROP]: t(
		`general.location-type.${ActionType.DROP.toLowerCase()}`,
		'Drop',
	),
	[ActionType.STOP]: t(
		`general.location-type.${ActionType.STOP.toLowerCase()}`,
		'Stop',
	),
	[ActionType.DELIVER]: t(
		`general.location-type.${ActionType.DELIVER.toLowerCase()}`,
		'Deliver',
	),
	[ActionType.LOAD]: t(
		`general.location-type.${ActionType.LOAD.toLowerCase()}`,
		'Load',
	),
});

export const locationLabels = (
	t: TFunction<'translation', undefined>,
): Record<TransportType, string[]> => ({
	[TransportType.IMPORT]: [
		locationTypeName(t)[ActionType.PICKUP],
		locationTypeName(t)[ActionType.DELIVER],
		locationTypeName(t)[ActionType.DROP],
	],
	[TransportType.EXPORT]: [
		locationTypeName(t)[ActionType.PICKUP],
		locationTypeName(t)[ActionType.LOAD],
		locationTypeName(t)[ActionType.DROP],
	],
	[TransportType.SHUNT]: [
		locationTypeName(t)[ActionType.PICKUP],
		locationTypeName(t)[ActionType.DROP],
	],
});
