import { type EventGroup } from './events-feed.types';

export function EventFeedGroupHeader({
	group,
	months,
}: {
	group: EventGroup;
	months: string[];
}) {
	return (
		<div className="pb-5 text-sm leading-6">
			<span>
				{months[group.date.getMonth()]} {group.date.getDate()},{' '}
				{group.date.getFullYear()}
			</span>
		</div>
	);
}

export function EventFeedGroupRowSeparator() {
	return (
		<span
			className="absolute left-3.5 top-7 -ml-px h-full w-0.5 bg-gray-200"
			aria-hidden="true"
		/>
	);
}
