import type { ShipmentListDTO } from '@uturn/api/v1';

export function getNextPrevShipments({
	shipments,
	currentShipmentNumber,
}: {
	shipments?: ShipmentListDTO[];
	currentShipmentNumber: number;
}): { next?: ShipmentListDTO; prev?: ShipmentListDTO } {
	if (!shipments) {
		return { next: undefined, prev: undefined };
	}

	const currentShipmentIndex = shipments?.findIndex(
		(shipment) => shipment.shipmentNumber === currentShipmentNumber,
	);

	if (!currentShipmentIndex || currentShipmentIndex === -1) {
		return { next: undefined, prev: undefined };
	}

	const next = shipments[currentShipmentIndex + 1];
	const prev = shipments[currentShipmentIndex - 1];

	return { next, prev };
}
