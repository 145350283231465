import { getInitialLocations } from '../utils/locations';
import type { TransportTypeCode } from '@uturn/portal/types/shipment';

interface SavedColumn {
	key: string;
	hidden: boolean;
}

interface SavedColumnGroups extends Record<string, SavedColumn[]> {}

type Column = {
	field: string;
	initialHide?: boolean;
	lockVisible?: boolean;
	order: number;
};

type ColumnGroup = {
	groupId: string;
	headerName: string;
	children: Column[];
};

type UseColumns = {
	columns: ColumnGroup[];
	requiredColumns: string[];
};

const useColumns = (transportType: TransportTypeCode): UseColumns => {
	const viewColumnsStorage = localStorage.getItem('multiCreateViewColumns');
	const parsedColumns = viewColumnsStorage
		? (JSON.parse(viewColumnsStorage) as SavedColumnGroups)
		: null;

	const initialLocations = getInitialLocations(transportType);

	const DEFAULT_LOCATION_COLUMNS = initialLocations.reduce<string[]>(
		(acc, _, index) => {
			const prefix = `location_${index + 1}`;
			acc.push(
				`${prefix}_type`,
				`${prefix}_location`,
				`${prefix}_from`,
				`${prefix}_until`,
				`${prefix}_reference`,
			);

			return acc;
		},
		[],
	);
	const DEFAULT_COLUMNS = [
		'containerType',
		'containerNumber',
		'shippingLine',
		'seal',
		'cargoDescription',
		'emptyContainer',
		'cargoGrossWeight',
		'price',
		'bidOnly',
		'invoiceReference',
		...DEFAULT_LOCATION_COLUMNS,
	];

	const REQUIRED_LOCATION_COLUMNS = initialLocations.reduce<string[]>(
		(acc, _, index) => {
			const prefix = `location_${index + 1}`;
			acc.push(`${prefix}_location`, `${prefix}_from`, `${prefix}_until`);

			return acc;
		},
		[],
	);
	const REQUIRED_COLUMNS = [
		'containerType',
		'cargoGrossWeight',
		'price',
		...REQUIRED_LOCATION_COLUMNS,
	];

	const COLUMNS = [
		{
			groupId: 'shipmentDetails',
			headerName: 'Shipment details',
			children: [
				{ field: 'containerType', headerName: 'Container type' },
				{ field: 'isoCode', headerName: 'ISO code' },
				{ field: 'generatorSet', headerName: 'Generator set' },
				{ field: 'containerNumber', headerName: 'Container number' },
				{ field: 'shippingLine', headerName: 'Shipping line' },
				{ field: 'seal', headerName: 'Seal' },
				{ field: 'cargoDescription', headerName: 'Cargo description' },
				{ field: 'requirements', headerName: 'Requirements' },
				{ field: 'unCode', headerName: 'UN code' },
				{ field: 'emptyContainer', headerName: 'Empty container' },
				{ field: 'cargoGrossWeight', headerName: 'Cargo gross weight' },
			],
		},
		{
			groupId: 'publishDetails',
			headerName: 'Publish details',
			children: [
				{ field: 'bidOnly', headerName: 'Bid only' },
				{ field: 'price', headerName: 'Price' },
				{ field: 'invoiceReference', headerName: 'Invoice reference' },
				{ field: 'vat', headerName: 'Regular VAT' },
				// TODO: preferredCarriers is a conditional column, not every user has permission to add preferred carriers
				{ field: 'preferredCarriers', headerName: 'Preferred carriers' },
				{ field: 'termsAndConditions', headerName: 'Terms & conditions' },
			],
		},
	];

	const getLocation = (index: number) => {
		const prefix = `location_${index}`;

		return [
			{ field: `${prefix}_type`, headerName: 'Type' },
			{ field: `${prefix}_location`, headerName: 'Location' },
			{ field: `${prefix}_from`, headerName: 'From' },
			{ field: `${prefix}_until`, headerName: 'Until' },
			{ field: `${prefix}_reference`, headerName: 'Reference' },
			{ field: `${prefix}_remarks`, headerName: 'Remarks' },
			{ field: `${prefix}_port`, headerName: 'Port' },
			{ field: `${prefix}_vesselName`, headerName: 'Vessel' },
			{ field: `${prefix}_shippingLine`, headerName: 'Shipping line' },
		];
	};

	const locations = [...Array(initialLocations.length)].map((_, i) => {
		const index = i + 1;

		return {
			groupId: `location_${index}`,
			headerName: `Location ${index}`,
			children: getLocation(index),
		};
	});

	const formattedColumns = [...COLUMNS, ...locations].map(
		(headerGroup): ColumnGroup => ({
			headerName: headerGroup.headerName,
			groupId: headerGroup.groupId,
			children: headerGroup.children.map((column) => {
				const index =
					(parsedColumns &&
						parsedColumns[headerGroup.groupId]?.findIndex(
							(entry) => entry.key === column.field,
						)) ??
					undefined;
				const savedColumn =
					parsedColumns && index
						? parsedColumns[headerGroup.groupId][index]
						: undefined;

				return {
					headerName: column.headerName,
					field: column.field,
					order: index,
					initialHide: savedColumn
						? savedColumn.hidden
						: !DEFAULT_COLUMNS.includes(column.field),
					lockVisible: REQUIRED_COLUMNS.includes(column.field),
				};
			}),
		}),
	);

	return {
		columns: formattedColumns,
		requiredColumns: REQUIRED_COLUMNS,
	};
};

export default useColumns;
