import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import type { FileRequest } from '@uturn/api/v1';
import {
	Button,
	FormItem,
	Icon,
	Input,
	Label,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	SheetClose,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from '@uturn/ui-kit';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileDropzone } from '@uturn/portal/components/file-dropzone';
import { MetaDataContext } from '@uturn/portal/context';
import { getFileTypeOptions } from '@uturn/portal/modules/shipments/utils';
import {
	initFileRequest,
	initFileTypeOptions,
} from '@uturn/portal/types/shipment';

export type AddDocumentProps = {
	saveDocument: (file: File, fileRequest: Partial<FileRequest>) => void;
};

// TODO: addDocument not refeactored yet
// NOTE: BE extension validation only.
// TODO: BE shipmentMetaData.fileTypes could provide filterable attributes
//       in order to dynamically derive allowedFileTypes.
// TODO: BE shipmentMetaData.fileTypes could also provide sorting attribute to
//       control the order of the options. Currentlly, allowedFileTypes dictates
//       the sorting.
// TODO: Add zod validation to form. Atm, the Save button is disabled until all
//       required fields have been provided. Bad UX!
export function AddDocument({ saveDocument }: AddDocumentProps): ReactNode {
	const { t } = useTranslation();
	const { shipmentMetaData } = useContext(MetaDataContext);

	const [fileUpload, setFileUpload] = useState<File>();
	const [fileRequest, setFileRequest] = useState(initFileRequest);
	const [fileTypeOptions, setFileTypeOptions] = useState(initFileTypeOptions);

	useEffect(() => {
		if (!shipmentMetaData?.fileTypes) return;
		setFileTypeOptions(getFileTypeOptions(shipmentMetaData.fileTypes, t));
	}, [shipmentMetaData?.fileTypes]);

	const handleOnChange = (value: string, field: string) => {
		setFileRequest((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	const handleOnDrop = (files: File[]) => {
		handleOnChange(files![0].name, 'fileName');
		setFileUpload(files![0]);
	};

	const handleOnDelete = () => {
		setFileUpload(undefined);
		setFileRequest((prevState) => ({
			...prevState,
			fileName: '',
		}));
	};

	const handleOnUpload = () => {
		if (!fileUpload) return;

		saveDocument(fileUpload, fileRequest);
	};

	return (
		<>
			<SheetHeader>
				<SheetTitle>
					{t(
						'pages.shipment-detail.modal.add-document.heading',
						'Add document',
					)}
				</SheetTitle>
			</SheetHeader>

			<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6">
				<div className="col-span-full">
					<FormItem>
						<Label className="required-asterix">
							{t(
								'pages.shipment-detail.modal.add-document.form.type.label',
								'Type',
							)}
						</Label>
						<Select
							onValueChange={(value) => handleOnChange(value, 'fileType')}
						>
							<SelectTrigger className="w-full">
								<SelectValue
									placeholder={
										<span>
											{t(
												'pages.shipment-detail.modal.add-document.form.type.placeholder',
												'Select a file type',
											)}
										</span>
									}
								/>
							</SelectTrigger>
							<SelectContent>
								{fileTypeOptions.map((option) => (
									<SelectItem key={option.value} value={option.value}>
										{option.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</FormItem>
				</div>
				<div className="col-span-full">
					<FormItem>
						<Label className="required-asterix">
							{t(
								'pages.shipment-detail.modal.add-document.form.description',
								'Description',
							)}
						</Label>
						<Input
							onChange={(e) => handleOnChange(e.target.value, 'description')}
						/>
					</FormItem>
				</div>
				<div className="col-span-full">
					{fileUpload ? (
						<section className="max-w-xl">
							<div className="bg-secondary-50/20 border-secondary-300 flex h-40 w-full appearance-none items-center justify-center rounded-md border-2 border-dashed p-4 transition focus:outline-none">
								<div className="flex items-center">
									<span className="text-sm">{fileRequest.fileName}</span>
									<Button variant="ghost" onClick={handleOnDelete}>
										<Icon icon={faTrashCan} className="-ml-1 size-4 pt-0.5" />
									</Button>
								</div>
							</div>
						</section>
					) : (
						<Dropzone onDrop={handleOnDrop}>
							{({ getRootProps, getInputProps }) => (
								<FileDropzone
									getInputProps={getInputProps}
									getRootProps={getRootProps}
								/>
							)}
						</Dropzone>
					)}
				</div>
				<div className="col-span-full">
					<SheetFooter>
						<SheetClose asChild>
							<Button
								type="button"
								size="sm"
								onClick={handleOnUpload}
								disabled={
									!fileUpload ||
									!fileRequest.fileType ||
									!fileRequest.description
								}
							>
								{t(
									'pages.shipment-detail.modal.add-document.form.submit',
									'Save',
								)}
							</Button>
						</SheetClose>
					</SheetFooter>
				</div>
			</div>
		</>
	);
}
