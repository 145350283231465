import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import type { Libraries } from '@react-google-maps/api';
import {
	GoogleMap as GMap,
	MAP_PANE,
	OverlayViewF,
	PolylineF,
	useJsApiLoader,
} from '@react-google-maps/api';
import { Icon } from '@uturn/ui-kit';
import { memo, useCallback, useEffect, useState } from 'react';

import { standard } from './map-config';
import { type Coordinates, type MapProps } from './map.types';

const containerStyle = {
	width: '100%',
	height: '100%',
};

const libraries: Libraries = ['places'];

// @TODO: move this component to the UI kit package if we decide to use it in other projects
const GoogleMap = ({
	initialLocation,
	locations,
	route,
	zoom = 8,
}: MapProps) => {
	const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
		libraries,
		language: 'en',
	});

	const onLoad = useCallback(function callback(map: google.maps.Map) {
		map.setCenter(initialLocation);
		map.setZoom(zoom);

		setGoogleMap(map);
	}, []);

	const onUnmount = useCallback(function callback() {
		setGoogleMap(null);
	}, []);

	useEffect(() => {
		if (!locations || locations.length === 0) return;

		if (googleMap) {
			const bounds = new google.maps.LatLngBounds();
			locations!.forEach((marker: Coordinates) => {
				bounds.extend(marker);
			});

			googleMap.fitBounds(bounds);
		}
	}, [googleMap, locations]);

	if (!isLoaded) return <></>;

	return (
		<GMap
			mapContainerStyle={containerStyle}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={{
				styles: standard,
				gestureHandling: 'greedy',
				streetViewControl: false,
				fullscreenControl: false,
				mapTypeControl: false,
				clickableIcons: false,
			}}
		>
			{locations!.length > 0 &&
				locations?.map((mark: Coordinates, i: number) => (
					<OverlayViewF
						mapPaneName={MAP_PANE}
						key={i}
						position={mark}
						getPixelPositionOffset={(width, height) => ({
							x: -(width / 2),
							y: -height,
						})}
					>
						<Icon
							icon={faLocationDot}
							className="size-7 text-primary drop-shadow"
						/>
					</OverlayViewF>
				))}

			{route && route.length > 0 && (
				<PolylineF
					path={route as Coordinates[]}
					options={{
						strokeColor: '#03ACCE',
						strokeWeight: 5,
					}}
				/>
			)}
		</GMap>
	);
};

export default memo(GoogleMap);
