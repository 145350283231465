import { z } from '@uturn/portal/zod';

/**
 * z.array(...).min(1, { message: 'Must select at least 1 requirement' }),
 */
export const requirementIdSchema = z.coerce.number().positive();

/** RequirementDTO */
export const requirementSchema = z.object({
	code: z.string().optional(),
	description: z.string().optional(),
	id: z.number().optional(),
});
