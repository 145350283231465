import { useGetMyCarriersList } from '@uturn/api/v1';
import { Suspense } from 'react';
import { useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CarriersTable } from '../components/table';
import { Permission } from '@uturn/portal/abac';
import { LoadingSpan } from '@uturn/portal/components';
import { Page } from '@uturn/portal/components/page';
import { sonner } from '@uturn/ui-kit';

export function CarriersOverview() {
	const { userHasPermissions } = useAbac();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: carriers } = useGetMyCarriersList({
		query: {
			enabled: userHasPermissions(Permission.INVITE_CARRIER),
		},
	});

	const addCarrierClick = () => {
		if (userHasPermissions(Permission.INVITE_CARRIER)) {
			return navigate('/carriers/add');
		}
		return sonner.error(
			t(
				'pages.carriers.actions-denied.add.description',
				'Cannot invite contract carrier while your organisation is pending approval by UTURN.',
			),
		);
	};

	return (
		<>
			<Helmet title={t('pages.carriers.pagetitle', 'My Carriers')!} />
			<Page
				title={t('pages.carriers.pagetitle', 'Carriers')!}
				primaryAction={{
					label: t('pages.carriers.actions.add', 'Add carrier'),
					onClick: addCarrierClick,
				}}
			>
				<Suspense fallback={<LoadingSpan />}>
					<CarriersTable data={carriers?.data ?? []} />
				</Suspense>
			</Page>
		</>
	);
}
