import type { EventHistoryDTO } from '@uturn/api/v1';
import { useGetAllShipmentEvents } from '@uturn/api/v1';
import { Sheet, SheetContent } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { groupedEventsByDate } from '../event-feed/events-feed.utils';

import { EventsFeed } from '@uturn/portal/modules/shipments/components';
import { getMonthsFromLocal } from '@uturn/portal/utils/get-months-from-local';

const pageSize = 20;
const firstPage = 0;
const months = getMonthsFromLocal({ localeName: navigator.language });

export function EventsHistorySheet({
	openSheet,
	setOpenSheet,
}: {
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
}) {
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState(0);
	const [lastPage, setLastPage] = useState(1);
	const [events, setEvents] = useState<EventHistoryDTO[][]>([]);

	const { data, isLoading } = useGetAllShipmentEvents(
		{ page, size: pageSize },
		{ query: { enabled: openSheet, suspense: false } },
	);

	const loadNextPage = () => {
		if (page >= firstPage && page <= lastPage) {
			setPage(page + 1);
		}
	};

	useEffect(() => {
		if (!data) return;

		const { data: pageData, pageIndex, totalElements } = data.data;
		const newEvents = structuredClone(events);
		newEvents[pageIndex] = pageData;

		if (totalElements !== total) {
			setTotal(totalElements);
			const newLastPage = Math.ceil(totalElements / pageSize);
			setLastPage(newLastPage);
		}

		setEvents(newEvents);
	}, [data]);

	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent>
				<EventsFeed
					events={groupedEventsByDate(events.flat())}
					months={months}
					loadNextPage={loadNextPage}
					isLoading={isLoading}
				/>
			</SheetContent>
		</Sheet>
	);
}
