import { type GetShipmentsParams, getShipments } from '@uturn/api/v2';
import { create } from 'zustand';
import type { MultiSelectShipmentStore, ShipmentState } from './type';

const defaultFilters: GetShipmentsParams = {
	viewType: 'OPEN',
	'filter.statuses': ['CONCEPT'],
};

export const useDraftedShipments = create<
	MultiSelectShipmentStore & ShipmentState
>((set, get) => ({
	shipments: [[]],
	isLoaded: false,
	totalShipments: 0,
	page: 0,
	pageLimit: 1,
	pageSize: 50,
	multiSelect: false,
	filters: defaultFilters,
	setMultiSelect: (state) => {
		set({ multiSelect: state });
	},
	fetch: async (page?: number) => {
		const currentPage = page || get().page;

		const { data } = await getShipments({
			...get().filters,
			page: currentPage.toString(),
		});

		const shipment = data.data || [];
		const newShipment = get().shipments;

		newShipment[currentPage] = shipment as any;

		set({
			shipments: newShipment,
			totalShipments: data.totalElements,
			page: currentPage,
			pageLimit: Math.ceil(data.totalElements! / get().pageSize),
			isLoaded: true,
		});
	},
	setPage: (page: number) => {
		const { pageLimit, shipments } = get();

		if (page > pageLimit || page < 0) {
			return;
		}

		shipments[page] = shipments[page] || [];

		set({ page, isLoaded: false, shipments });
		get().fetch(page);
	},
	setFilters: (filters: Partial<GetShipmentsParams>) => {
		set({ filters: { ...defaultFilters, ...filters } });
		get().fetch();
	},
}));
