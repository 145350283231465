import { Separator } from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SidebarNav } from '../org/layout';

export function AccountLayout() {
	const { t } = useTranslation();

	const sidebarNavItems = [
		{
			title: t('pages.account.navigation.profile', 'Profile'),
			href: `/account`,
		},
		{
			title: t('pages.account.navigation.notifications', 'Notifications'),
			href: `/account/notifications`,
		},
	];

	return (
		<>
			<Helmet title={t('pages.account.title', 'Account settings')!} />
			<div className="py-8 pt-6 @container/content md:block">
				<div className="space-y-0.5">
					<h2 className="text-3xl font-bold tracking-tight">
						{t('pages.account.title', 'Account settings')}
					</h2>
					<p className="text-muted-foreground">
						{t(
							'pages.account.description',
							'Manage the profile and settings for your account.',
						)}
					</p>
				</div>
				<Separator className="my-6" />
				<div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
					<aside className="-mx-4 lg:w-1/5">
						<SidebarNav items={sidebarNavItems} />
					</aside>
					<div className="flex-1 lg:max-w-2xl">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
