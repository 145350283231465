/// <reference types="google.maps" />
import { decode } from '@googlemaps/polyline-codec';
import {
	type AddressDTO,
	type AddressLookupResponse,
	Co2EmissionUnit,
	DistanceUnit,
	DurationUnit,
	type GetPlaceDetailsByPlaceIdParams,
	type LookUpShippingLocationParams,
	type PriceCalculatorTransportType,
	type PriceRequestDto,
	type PriceResponseDto,
	type RouteDetails,
	RouteInformationRequestTransportType,
	getPlaceDetailsByPlaceId,
	getRouteInformation,
	getSubscriptionByProduct,
	lookUpShippingLocationUnAuthorized,
} from '@uturn/api/v1';
import { type Coordinates } from '@uturn/ui';
import { create } from 'zustand';
import { getLocationQueryString } from '../utils';

const defaultRouteInformation: RouteDetails = {
	distance: {
		quantity: 0,
		unit: DistanceUnit.kilometer,
	},
	duration: {
		quantity: 0,
		unit: DurationUnit.hour,
	},
	co2Emission: {
		quantity: 0,
		unit: Co2EmissionUnit.kilogram,
	},
};

// TODO: this type can be removed once the api is updated
export type WebSocketPcSubscription = {
	remainingRequests: string;
	partyNumber: string;
};

export type PriceCalculatorState = {
	routeInformation: RouteDetails;
	setRouteInformation(routeInformation: RouteDetails): void;
	pcSubscription: {
		remainingRequests: string;
		tier: string;
	};
	route: Coordinates[];
	priceIndication: PriceResponseDto | null;
	priceRequest: PriceRequestDto | null;
	isLoaded: boolean;
	fetchLocations: (
		data: LookUpShippingLocationParams,
	) => Promise<AddressLookupResponse[]>;
	fetchPlacesLocation: (
		data: GetPlaceDetailsByPlaceIdParams,
	) => Promise<AddressLookupResponse>;
	fetchRouteInformation: ({
		locations,
		transportType,
	}: {
		locations: AddressDTO[];
		transportType: PriceCalculatorTransportType;
	}) => Promise<void>;
	resetRouteInformation: () => void;
	fetchSubscription: () => void;
	setRemainingRequests: (remainingRequests: string) => void;
};

export const usePriceCalculatorStore = create<PriceCalculatorState>(
	(set, get) => ({
		routeInformation: defaultRouteInformation,

		pcSubscription: {
			remainingRequests: '0',
			tier: '',
		},
		route: [],
		priceIndication: null,
		priceRequest: null,
		isLoaded: false,

		fetchLocations: async (data) => {
			if (data.searchText === '') {
				return [];
			}

			const result = await lookUpShippingLocationUnAuthorized(data);

			if (result.status !== 200) {
				return [];
			}

			return result.data;
		},

		fetchPlacesLocation: async (data) => {
			const result = await getPlaceDetailsByPlaceId(data);

			if (result.status !== 200) {
				return [];
			}

			return result.data;
		},

		fetchSubscription: async () => {
			// TODO add new get subscription api call when v3 api is ready
			const subscriptions = await getSubscriptionByProduct(1);

			const subscription = {
				remainingRequests:
					subscriptions.data.activeSubscription?.remainingRequests!.toString() ??
					'0',
				tier: subscriptions.data.activeSubscription?.tierName ?? '',
			};

			set({ pcSubscription: subscription, isLoaded: true });
		},

		fetchRouteInformation: async ({
			locations,
			transportType,
		}: {
			locations: any[];
			transportType: PriceCalculatorTransportType;
		}) => {
			const firstLocation = {
				location: getLocationQueryString(locations[0]),
				countryCode:
					locations[0].countryCode! ?? locations[0].country.countryCode,
			};

			const secondLocation = {
				location: getLocationQueryString(locations[1]),
				countryCode:
					locations[1].countryCode! ?? locations[1].country.countryCode,
			};

			const request = {
				firstLocation: firstLocation.location,
				firstLocationCountryCode: firstLocation.countryCode,
				secondLocation: secondLocation.location,
				secondLocationCountryCode: secondLocation.countryCode,

				...(transportType !== 'SHUNT' && {
					thirdLocation: getLocationQueryString(locations[2]),
					thirdLocationCountryCode:
						locations[2].countryCode! ?? locations[2].country.countryCode,
				}),

				transportType:
					RouteInformationRequestTransportType[
						transportType.toLocaleUpperCase() as keyof typeof RouteInformationRequestTransportType
					],
			};

			const { data: routeInformation } = await getRouteInformation(request);
			set({ routeInformation });
		},

		setRouteInformation: (routeInformation: RouteDetails) => {
			set({ routeInformation });
		},

		setRemainingRequests: (remainingRequests: string) => {
			set({ pcSubscription: { ...get().pcSubscription, remainingRequests } });
		},

		resetRouteInformation: () => {
			set({ routeInformation: defaultRouteInformation });
		},
	}),
);

export async function getDirections(locations: Coordinates[]) {
	if (locations.length < 2) {
		return;
	}

	const directionsService = new google.maps.DirectionsService() as any;

	let routeArray: Coordinates[] = [];

	const requests = [];

	for (let i = 0; i < locations.length - 1; i += 1) {
		const origin = locations[i];
		const destination = locations[i + 1];

		const request: any = {
			origin: { lat: origin.lat, lng: origin.lng },
			destination: { lat: destination.lat, lng: destination.lng },
			travelMode: google.maps.TravelMode.DRIVING,
		};

		const routePromise = new Promise((resolve, reject) => {
			directionsService.route(request, (result: any, status: any) => {
				if (status === 'OK' && result?.routes?.length > 0) {
					const routeResult = decode(result.routes[0].overview_polyline);
					const routeDecoded = routeResult.map((r) => ({
						lat: r[0],
						lng: r[1],
					}));
					resolve(routeDecoded);
				} else {
					reject(new Error('Error calculating route'));
				}
			});
		});

		requests.push(routePromise);
	}

	try {
		const routes = await Promise.all(requests);
		routes.forEach((route) => {
			routeArray = [...routeArray, ...route];
		});

		return routeArray;
	} catch (error) {
		console.error('Error calculating routes:', error);
	}
}
