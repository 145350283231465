import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import {
	type OrganizationDto,
	useFetchOrganizationById,
	useUpdateOrganization,
} from '@uturn/api/v1';
import { phoneDialCodes } from '@uturn/ui';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator,
	sonner,
} from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const countryOptions = phoneDialCodes.map((country) => ({
	label: country.name,
	value: country.code,
}));

export function OrganizationBilling() {
	const { orgId } = useParams();
	const { t } = useTranslation();
	const {
		data: { data },
	} = useFetchOrganizationById(Number(orgId));

	const { mutate } = useUpdateOrganization({
		mutation: {
			onSuccess: () => {
				sonner.success(
					t(
						'pages.account.organisation.billing.form.submit.success.description',
						'Organisation has been updated!',
					),
				);
			},
			onError: () => {
				sonner.error(t('general.try-again-later', 'Please try again later'));
			},
		},
	});

	const form = useForm<OrganizationDto>({
		defaultValues: data!,
	});

	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.billing.title',
						'Organisation - Billing',
					)!
				}
			/>
			<div className="space-y-6">
				<div>
					<h3 className="text-lg font-medium">
						{t('pages.account.organisation.billing.title', 'Billing')}
					</h3>
					<p className="text-sm text-muted-foreground">
						{t(
							'pages.account.organisation.billing.description',
							'Manage your billing information',
						)}
					</p>
				</div>
				<Separator />
				<Form {...form}>
					<form
						className="space-y-8"
						onSubmit={form.handleSubmit((formData) => {
							mutate({
								id: Number(orgId),
								data: formData satisfies OrganizationDto,
							});
						})}
					>
						<div className="grid grid-cols-1 gap-3">
							<FormField
								control={form.control}
								disabled
								name="financialDetails.currency"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.general.fields.invoice-currency',
												'Invoice currency',
											)}
										</FormLabel>
										<FormControl>
											<FormFieldReadOnly value={field.value} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								disabled
								name="financialDetails.vatNumber"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.general.fields.vat-number',
												'Tax number (VAT)',
											)}
										</FormLabel>
										<FormControl>
											{field.value !== '' ? (
												<FormFieldReadOnly value={field.value!} />
											) : (
												<Input {...field} />
											)}
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="grid grid-cols-2 gap-3">
								<FormField
									control={form.control}
									disabled
									name="financialDetails.bankDetails.accountNumber"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.general.fields.account-number',
													'Bank account number',
												)}
											</FormLabel>
											<FormControl>
												{field.value !== '' ? (
													<FormFieldReadOnly value={field.value!} />
												) : (
													<Input {...field} />
												)}
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									disabled
									name="financialDetails.bankDetails.bic"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.general.fields.bic',
													'BIC',
												)}
											</FormLabel>
											<FormControl>
												{field.value !== '' ? (
													<FormFieldReadOnly value={field.value!} />
												) : (
													<Input {...field} />
												)}
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-3">
							<h3 className="text-md font-heading col-span-full font-medium tracking-tight">
								{t(
									'pages.account.organisation.billing.form.chamber-of-commerce.title',
									'Chamber of commerce',
								)}
							</h3>
							<FormField
								control={form.control}
								name="financialDetails.chamberOfCommerce.number"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.chamber-of-commerce.fields.number',
												'Number',
											)}
										</FormLabel>
										<FormControl>
											{field.value !== '' ? (
												<FormFieldReadOnly value={field.value!} />
											) : (
												<Input {...field} />
											)}
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="address.countryCode"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.chamber-of-commerce.fields.country.label',
												'Country',
											)}
										</FormLabel>
										{field.value !== '' ? (
											<FormFieldReadOnly
												value={
													countryOptions.filter(
														(country) => country.value === field.value,
													)[0].label
												}
											/>
										) : (
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<FormControl>
													<SelectTrigger>
														<SelectValue
															placeholder={
																<span>
																	{t(
																		'pages.account.organisation.billing.form.chamber-of-commerce.fields.country.placeholder',
																		'Select where you are located',
																	)}
																</span>
															}
														/>
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{countryOptions.map((country) => {
														return (
															<SelectItem
																key={country.value}
																value={country.value}
															>
																{country.label}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<div className="space-y-3">
							<h3 className="text-md font-heading font-medium tracking-tight">
								{t(
									'pages.account.organisation.billing.form.invoice-address.title',
									'Invoice address',
								)}
							</h3>
							<div className="grid grid-cols-[3fr,_1fr] gap-3">
								<FormField
									control={form.control}
									name="financialDetails.address.street"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.street',
													'Street',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="financialDetails.address.houseNumber"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.house-number',
													'House number',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid grid-cols-[2fr,_4fr] gap-3 ">
								<FormField
									control={form.control}
									name="financialDetails.address.postalCode"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.postal-code',
													'Postal code',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="financialDetails.address.city"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.city',
													'City',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<FormField
								control={form.control}
								name="financialDetails.address.countryCode"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.invoice-address.fields.country.label',
												'Country',
											)}
										</FormLabel>
										<Select
											onValueChange={field.onChange}
											defaultValue={field.value}
										>
											<FormControl>
												<SelectTrigger>
													<SelectValue
														placeholder={
															<span>
																{t(
																	'pages.account.organisation.billing.form.invoice-address.fields.country.placeholder',
																	'Select where you are located',
																)}
															</span>
														}
													/>
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{countryOptions.map((country) => {
													return (
														<SelectItem
															key={country.value}
															value={country.value}
														>
															{country.label}
														</SelectItem>
													);
												})}
											</SelectContent>
										</Select>
										<FormMessage />
									</FormItem>
								)}
							/>

							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('general.actions.save', 'save')}
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
