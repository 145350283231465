import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	meiliSearchIndex,
	useMeiliSearch,
	useMeiliSearchKey,
} from '@uturn/portal/meilisearch';
import { getApiErrorMessage } from '@uturn/portal/utils';

export const useFacetSearch = (facetName: string, groupId?: string) => {
	const meiliSearch = useMeiliSearch();
	const queryClient = useQueryClient();
	const queryKey = useMeiliSearchKey();

	return useQuery({
		queryKey: ['facet-search', facetName, groupId],
		queryFn: async () => {
			await meiliSearch.health();

			const shipmentsIndex = meiliSearchIndex(meiliSearch);
			try {
				const result = await shipmentsIndex.searchForFacetValues({
					facetName,
					filter: groupId ? `groupId = ${groupId}` : undefined,
				});
				return result;
			} catch (err) {
				// TODO: Revisit this.
				queryClient.invalidateQueries({ queryKey });
				console.error(
					'useFacetSearch',
					new Date().toJSON(),
					getApiErrorMessage(err),
				);
				return null;
			}
		},
	});
};
