import { FileType } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';

export const detailsSchema = z.string();

/**
 * fileRequests isn't on the form as such.
 * // TODO: More work is needed to validate this.
 */
export const fileRequestsSchema = z
	.object({
		description: z.string(),
		fileName: z.string(),
		fileType: z.nativeEnum(FileType),
	})
	.array();
