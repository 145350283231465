import {
	faCircleExclamation,
	faCircleXmark,
	faClock,
	faContainerStorage,
	faFileLines,
	faKey,
	faLock,
	faLockOpen,
	faSackDollar,
} from '@fortawesome/pro-regular-svg-icons';
import { PortbaseStatusDTOStatus, useGetStatusByLocation } from '@uturn/api/v1';
import type {
	AcceptStatus,
	AcceptStatusType,
	RejectReason,
} from '@uturn/api/v1';
import { H6 } from '@uturn/ui';
import type { IconProps } from '@uturn/ui-kit';
import { Callout, CalloutIcon, CalloutTitle, Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

type StatusType = AcceptStatusType | 'REJECTED';

const PortbaseIcon = ({
	iconSize,
	type,
	status,
}: {
	iconSize: string;
	type: StatusType;
	status: boolean | undefined;
}) => {
	if (!type) return null;

	const icon: Record<StatusType, IconProps['icon']> = {
		AVAILABLE: faContainerStorage,
		BLOCKED: faKey,
		BOOKING_APPROVED: status ? faLockOpen : faLock,
		CARGO_OPENING: faClock,
		COMMERCIAL_RELEASE: faSackDollar,
		CUSTOMS_DOCUMENT: faFileLines,
		SIZETYPE_APPROVED: faKey,
		REJECTED: faCircleXmark,
	};

	return (
		<Icon
			className={twJoin(
				iconSize,
				status === undefined && 'text-muted-foreground',
				status ? 'text-green-500' : 'text-destructive',
			)}
			icon={icon[type]}
		/>
	);
};

const Status = ({
	iconSize,
	status,
}: {
	iconSize: string;
	status: AcceptStatus;
}) => {
	return (
		<div className="group relative">
			<PortbaseIcon
				iconSize={iconSize}
				type={status.type!}
				status={status.ok}
			/>
			<div className="absolute -top-6 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 rounded-md bg-white px-4 py-2 shadow-lg ring-1 ring-black ring-opacity-5 group-hover:block">
				<span className="text-sm">
					{status.ok ? status.type : status.remark}
				</span>
			</div>
		</div>
	);
};

const Rejected = ({
	iconSize,
	reasons,
}: {
	iconSize: string;
	reasons: RejectReason[];
}) => {
	if (!reasons.length) {
		return null;
	}

	return (
		<div className="group relative">
			<PortbaseIcon
				iconSize={iconSize}
				type={PortbaseStatusDTOStatus.REJECTED}
				status={false}
			/>
			{reasons.map((reason) => (
				<div
					key={reason.code}
					className="absolute -top-6 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 rounded-md bg-white px-4 py-2 shadow-lg ring-1 ring-black ring-opacity-5 group-hover:block"
				>
					<p className="text-sm">{PortbaseStatusDTOStatus.REJECTED}</p>
					<p className="text-xs text-muted-foreground">
						{reason.parsedReason || reason.terminalDescription}
					</p>
				</div>
			))}
		</div>
	);
};

export function LocationPortbaseStatus({
	locationActionId,
}: {
	locationActionId: number;
}) {
	const { t } = useTranslation();
	const { data: portbaseData } = useGetStatusByLocation(locationActionId);

	const hasData = () => {
		if (!portbaseData) return false;
		if (!portbaseData.data) return false;
		if (Object.keys(portbaseData.data).length === 0) return false;
		return true;
	};

	const {
		available,
		blocked,
		customsDocument,
		commercialRelease,
		bookingApproved,
		cargoOpening,
		rejectReasons,
	} = portbaseData?.data ?? {};

	if (!hasData()) return <></>;

	return (
		<div className="flex w-max flex-col gap-2">
			<H6>{t('component.portbase.status', 'Portbase status')}</H6>
			<div className="flex items-center gap-3">
				{bookingApproved && (
					<Status iconSize="w-4 h-4" status={bookingApproved} />
				)}
				{available && <Status iconSize="w-5.5 h-5.5" status={available} />}
				{blocked && <Status iconSize="w-4 h-4" status={blocked} />}
				{customsDocument && (
					<Status iconSize="w-4 h-4" status={customsDocument} />
				)}
				{commercialRelease && (
					<Status iconSize="w-4.5 h-4.5" status={commercialRelease} />
				)}
				{cargoOpening && <Status iconSize="w-4 h-4" status={cargoOpening} />}
				{rejectReasons && (
					<Rejected iconSize="w-4 h-4" reasons={rejectReasons} />
				)}
			</div>
		</div>
	);
}

export function LocationPortbaseStatusDenied() {
	const { t } = useTranslation();
	return (
		<div className="flex w-max flex-col gap-2">
			<H6>{t('component.portbase.status', 'Portbase status')}</H6>
			<div className="flex items-center gap-3">
				<Callout>
					<CalloutIcon>
						<Icon icon={faCircleExclamation} />
					</CalloutIcon>
					<CalloutTitle>
						<strong>
							{t(
								'component.portbase.status.denied.title',
								'Waiting for approval:',
							)}
						</strong>
						:{' '}
						{t(
							'component.portbase.status.denied.description',
							'Cannot access the Portbase statuses while your organisation is pending approval by UTURN.',
						)}
					</CalloutTitle>
				</Callout>
			</div>
		</div>
	);
}
