import type { QueryKey } from '@tanstack/react-query';
import type { ButtonProps } from '@uturn/ui-kit';
import { Button } from '@uturn/ui-kit';
import { useState } from 'react';
import { QuotesSheet } from '../../modules/shipments/components/quotes-sheet';

interface Props {
	quoteCount: number;
	shipmentNumber: number;
	size?: ButtonProps['size'];
	onAssign?: () => void;
	onDecline?: () => void;
	parentQueryKey?: QueryKey;
}

export default function QuoteButton({
	quoteCount,
	shipmentNumber,
	size,
	onAssign,
	onDecline,
	parentQueryKey,
}: Props) {
	const [openSheet, setOpenSheet] = useState(false);

	return (
		<>
			<Button
				size={size}
				variant="secondary"
				className="relative"
				onClick={() => setOpenSheet(true)}
			>
				Quotes
				{quoteCount > 0 && (
					<div className="absolute right-0 top-[12.5%] inline-flex h-4 min-w-4 translate-x-[50%] translate-y-[-50%] items-center justify-center rounded-full bg-orange-600 p-1 text-[.66rem] leading-none text-white">
						{quoteCount > 99 ? '99+' : quoteCount}
					</div>
				)}
			</Button>
			<QuotesSheet
				onAssign={onAssign}
				onDecline={onDecline}
				shipmentNumber={shipmentNumber}
				openSheet={openSheet}
				setOpenSheet={setOpenSheet}
				parentQueryKey={parentQueryKey}
			/>
		</>
	);
}
