import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { OpenShipments as OpenShipmentsEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupDetailContext } from '../../providers/shipment-group-detail';
import {
	RequirementBadges,
	ShipmentPage,
} from '@uturn/portal/modules/shipments/components';
import { formatPrice } from '@uturn/portal/utils';

function PublishedShipmentEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<OpenShipmentsEmptyState className="md:h-80" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t(
					'pages.shipments.group-detail.published.emptyState.title',
					'Time to start shipping!',
				)}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.group-detail.published.emptyState.description',
					"We noticed that you don't have any open shipments in your group right now. Publish a shipment from your a concepts first.",
				)}
			</p>
		</EmptyState>
	);
}

export function PublishedGroupShipments() {
	const { t } = useTranslation();
	const { groupName } = useContext(ShipmentGroupDetailContext);

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.containerNumber'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.targetPrice', 'target price'),
			value: formatPrice(
				shipment.price?.quantity ?? 0,
				shipment.price?.currencyCode ?? CurrencyType.EUR,
			),
			visibleOnMultiSelect: true,
		},
		{
			label: t('pages.shipments.details.requirements'),
			value: <RequirementBadges requirements={shipment.requirements} />,
		},
	];

	return (
		<>
			<Helmet
				title={
					t(
						'pages.shipments.groups.published.title',
						'{{groupName}} - Published shipments',
						{
							groupName,
						},
					)!
				}
			/>
			<ShipmentPage
				enableGroupFilter={false}
				EmptyState={PublishedShipmentEmptyState}
				details={shipmentDetails}
				hasQuotes
			/>
		</>
	);
}
