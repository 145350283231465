import { type QueryKey, useQueryClient } from '@tanstack/react-query';

import type { GroupSummary } from '@uturn/api/v1';
import { useUpdateGroupsById } from '@uturn/api/v1';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Input,
	Label,
	sonner
} from '@uturn/ui-kit';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

export function RenameShipmentGroupModal({
	group,
	groupsQueryKey,
	openModal,
	setOpenModal,
}: {
	group: GroupSummary;
	groupsQueryKey: QueryKey;
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
}) {
	const { t } = useTranslation();

	const [name, setName] = useState(group.name);

	const queryClient = useQueryClient();

	const onSuccess = () => {
		queryClient.invalidateQueries({ queryKey: groupsQueryKey });
		sonner.success(
			t(
				'pages.shipments.modal.rename-shipment-group.submit.success.description',
				'Group has been renamed successfully.',
			),
		);

		setOpenModal(false);
	};

	const renameShipmentGroup = useUpdateGroupsById({
		mutation: {
			onSuccess,
		},
	});

	const handleSubmit = () => {
		if (!name.length) return;

		renameShipmentGroup.mutate({
			id: group.id,
			data: {
				name,
			},
		});
	};

	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(
							'pages.shipments.modal.rename-shipment-group.heading',
							'Rename shipment',
						)}
					</DialogTitle>
				</DialogHeader>

				<div className="flex flex-col gap-2.5">
					<Label>
						{t('pages.shipments.modal.rename-shipment.label', 'Group name')}
					</Label>
					<Input value={name} onChange={(e) => setName(e.target.value)} />
				</div>

				<DialogFooter>
					<DialogClose asChild>
						<Button variant="outline">
							{t(
								'pages.shipments.modal.rename-shipment-group.cancel',
								'Cancel',
							)}
						</Button>
					</DialogClose>
					<Button
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						{t('pages.shipments.modal.rename-shipment-group.confirm', 'Save')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
