import { faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import type { QueryKey } from '@tanstack/react-query';
import type { FileRequest, UturnFileDTO } from '@uturn/api/v1';
import {
	Button,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormMessage,
	Icon,
	Label,
	Sheet,
	SheetContent,
	SheetTrigger,
	Textarea,
} from '@uturn/ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddDocument } from './add-document';
import { ConfirmDeleteDocumentModal } from './confirm-delete-document-modal';
import { ShipmentDocumentTile } from './shipment-document-tile';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { isFieldReadOnly } from '@uturn/portal/modules/shipments/utils';
import type { Document } from '@uturn/portal/types/shipment';

export type AdditionalInformationProps = {
	initDocuments: any[];
	setDocuments: (documents: Document[]) => void;
	documentQueryKey?: QueryKey;
};

export function AdditionalInformation({
	initDocuments,
	setDocuments,
	documentQueryKey,
}: AdditionalInformationProps) {
	const { t } = useTranslation();
	const { control, getValues } = useFormContext<FormValues>();

	const { readOnlyFields } = useContext(ShipmentDetailContext);

	const [hasDocuments, setHasDocuments] = useState(false);

	const [documentFiles, setDocumentFiles] = useState<Document[]>([]);

	const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false);
	const [toDeleteDocument, setToDeleteDocument] = useState<Document>();

	const saveDocument = (file: File, fileRequest: Partial<FileRequest>) => {
		setDocumentFiles((prevState) => [...prevState, { file, fileRequest }]);
	};

	const removeFile = (index: number) => {
		setDocumentFiles((prevState) => prevState.filter((_, i) => i !== index));
	};

	useEffect(() => {
		if (documentFiles.length > 0) setHasDocuments(true);
		setDocuments(documentFiles);
	}, [documentFiles]);

	useEffect(() => {
		if (initDocuments && initDocuments.length > 0) setHasDocuments(true);
	}, [initDocuments]);

	return (
		<>
			<div className="grid w-full grid-cols-1 gap-3 sm:grid-cols-6">
				<div className="sm:col-span-full">
					<h3 className="font-heading text-lg font-medium tracking-tight">
						{t(
							'pages.create_shipment.shipment-details.additional_info.heading',
							'Additional information',
						)}
					</h3>
				</div>
				<div className="sm:col-span-full">
					<FormField
						control={control}
						name="details"
						render={({ field }) => (
							<FormItem>
								{isFieldReadOnly(readOnlyFields, 'details') ? (
									<FormFieldReadOnly value={getValues('details')} />
								) : (
									<FormControl>
										<Textarea {...field} />
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				{}
				{hasDocuments && (
					<div className="flex flex-col gap-2 sm:col-span-4 md:col-span-3">
						<Label>
							{t(
								'pages.create_shipment.shipment-details.additional_info.documents',
								'Documents',
							)}
						</Label>
						{initDocuments &&
							initDocuments.map((doc, index) => (
								<ShipmentDocumentTile
									key={index}
									document={doc}
									removeFile={() => {
										setToDeleteDocument(doc);
										setOpenDeleteFileModal(true);
									}}
								/>
							))}
						{!initDocuments &&
							documentFiles.map((file, index) => (
								<div
									key={index}
									className="bg-secondary-100/40 border-secondary-200/60 mt-2 flex items-center justify-between rounded-lg border py-4 pl-5 pr-2"
								>
									<span className="text-secondary-700 flex flex-col text-sm">
										<span className="font-bold">
											{file.fileRequest.fileType}
										</span>
										<span className="mb-2">{file.fileRequest.description}</span>
										<span>{file.fileRequest.fileName}</span>
									</span>
									<Button
										variant="ghost"
										onClick={(e) => {
											e.preventDefault();
											removeFile(index);
										}}
									>
										<Icon
											icon={faTrashCan}
											className="text-secondary-600 size-4"
										/>
									</Button>
								</div>
							))}
					</div>
				)}
				{isFieldReadOnly(readOnlyFields, 'details') ? null : (
					<div className="sm:col-span-full">
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="secondary" size="sm" formNoValidate>
									<Icon icon={faPlus} className="mr-1.5" />
									{t(
										'pages.create_shipment.shipment-details.additional_info.add_doc',
										'Add document',
									)}
								</Button>
							</SheetTrigger>
							<SheetContent>
								<AddDocument saveDocument={saveDocument} />
							</SheetContent>
						</Sheet>
					</div>
				)}
			</div>
			<ConfirmDeleteDocumentModal
				openModal={openDeleteFileModal}
				setOpenModal={setOpenDeleteFileModal}
				documentQueryKey={documentQueryKey ?? undefined}
				toDeleteDocument={(toDeleteDocument as UturnFileDTO) ?? undefined}
			/>
		</>
	);
}
