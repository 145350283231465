import {
	AccordionContent,
	AccordionTrigger,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';

export function ShipmentNumberSorting() {
	const { filters, setFilters } = useContext(FilterContext);
	const { t } = useTranslation();
	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.sortingTitle', 'Sort by shipment ID')}
			</AccordionTrigger>
			<AccordionContent>
				<Select
					onValueChange={(e) => {
						setFilters({ sortBy: 'SHIPMENT_NUMBER', sortOrder: e });
					}}
					defaultValue={filters.sortOrder ?? undefined}
				>
					<SelectTrigger>
						<SelectValue
							placeholder={<span>{t('general.desc', 'Descending')}</span>}
						/>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="ASC">{t('general.asc', 'Ascending')}</SelectItem>
						<SelectItem value="DESC">
							{t('general.desc', 'Descending')}
						</SelectItem>
					</SelectContent>
				</Select>
			</AccordionContent>
		</>
	);
}
