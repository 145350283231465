import { ScrollArea, SheetHeader, SheetTitle } from '@uturn/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EventFeedGroupHeader,
	EventFeedGroupRowSeparator,
} from './events-feed-group';
import { EventsFeedItem, EventsFeedType } from './events-feed-item';
import { type EventsFeedProps } from './events-feed.types';
import { LoadingSpan } from '@uturn/portal/components';
import { useElementOnScreen } from '@uturn/portal/hooks/useElementOnScreen';

export function EventsFeed({
	events,
	months,
	isLoading = false,
	loadNextPage,
}: EventsFeedProps) {
	const { t } = useTranslation();

	const [containerRef, isVisible] = useElementOnScreen({
		root: null,
		rootMargin: '0px',
		threshold: 0.8,
	});

	useEffect(() => {
		if (!isLoading && isVisible && loadNextPage) {
			loadNextPage();
		}
	}, [isLoading, isVisible, loadNextPage]);

	return (
		<>
			<SheetHeader>
				<SheetTitle>
					{t('general.event_feed.modal.heading', 'Events')}
				</SheetTitle>
			</SheetHeader>
			<ScrollArea className="size-full">
				<ul className="-mb-8 mr-5">
					{events.map((group, indexGroup) => (
						<div key={`${indexGroup}-${group.date.toJSON()}`}>
							<EventFeedGroupHeader group={group} months={months} />
							{group.events.map((event, indexEvent) => {
								const lastEvent = group.events.length === indexEvent + 1;
								return (
									<li key={event.eventId}>
										<div className="relative pb-8">
											{!lastEvent && <EventFeedGroupRowSeparator />}
											<div className="relative flex space-x-6">
												<EventsFeedType eventType={event.eventType} />
												<EventsFeedItem {...event} />
											</div>
										</div>
									</li>
								);
							})}
						</div>
					))}
				</ul>
				<div
					ref={containerRef as React.MutableRefObject<HTMLDivElement>}
					className="h-10 w-full"
				>
					{isLoading && <LoadingSpan />}
				</div>
			</ScrollArea>
		</>
	);
}
