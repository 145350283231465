import type { AveragePriceDto, MarketTrendDto } from '@uturn/api/v1';
import {
	AverageLine,
	Chart,
	type ChartData,
	type ChartYValue,
} from '@uturn/ui';
import {
	Button,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
	Popover,
	PopoverContent,
	PopoverTrigger,
	sonner,
} from '@uturn/ui-kit';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LearnMorePopoverContent = ({
	adrSurcharge,
	genSetSurcharge,
	avaragePrice,
}: {
	adrSurcharge: number;
	genSetSurcharge: number;
	avaragePrice: number;
}) => {
	const { t } = useTranslation();
	const hasSurcharge = adrSurcharge > 0 || genSetSurcharge > 0;

	return hasSurcharge ? (
		<p className="grid auto-cols-auto gap-2 text-sm">
			<span>
				{t(
					'pages.price-calculator.indication.average-base-price',
					'Average base price:',
				)}
			</span>
			<span className="text-right">
				€ {Math.round(avaragePrice - adrSurcharge - genSetSurcharge)}
			</span>
			<span className="col-span-2 pr-10 text-xs">
				{t(
					'pages.price-calculator.indication.graph-tooltip-description',
					'Within the base price all costs for fuel, road taxes and 2 hours free for delivery or loading time are included. Only the matching fee and unforeseen costs (like waiting time) are excluded.',
				)}
			</span>
			<span className="">{adrSurcharge > 0 ? 'ADR' : 'GenSet'} surcharge</span>
			<span className="text-right">
				€ {Math.round(adrSurcharge + genSetSurcharge)}
			</span>
			<hr className="col-span-2 my-2" />
			<span className="col-span-2 text-right">
				{t(
					'pages.price-calculator.indication.average-total',
					'Average total {{ amount }}',
					{
						amount: `€ ${Math.round(avaragePrice)}`,
					},
				)}
			</span>
		</p>
	) : (
		<p className="text-sm text-muted-foreground">
			{t(
				'pages.price-calculator.indication.graph-tooltip-description',
				'Within the price all costs for fuel, road taxes and 2 hours free for delivery or loading time are included. Only the matching fee and unforeseen costs (like waiting time) are excluded.',
			)}
		</p>
	);
};

export function PriceIndicationResult({
	result,
}: {
	result: { averagePrice: AveragePriceDto; marketTrend: MarketTrendDto };
}) {
	const { t } = useTranslation();
	const inputReference = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		inputReference.current?.focus();
	}, []);

	const { averagePrice, marketTrend } = result;

	const yValues: ChartYValue[] = marketTrend?.priceDataPoints?.yAxisPlots?.map(
		(value: number, index: number) => {
			const label =
				marketTrend?.priceDataPoints?.yAxisLabel?.[index] === 0
					? 'Average'
					: `${marketTrend?.priceDataPoints?.yAxisLabel?.[index]}%`;

			return {
				label,
				value: value!,
			} satisfies ChartYValue;
		},
	) as ChartYValue[];

	const data: ChartData[] = marketTrend?.priceDataPoints?.dataPoints?.map(
		(dataPoint, index) =>
			({
				name: marketTrend.priceDataPoints?.label?.[index]!.split('/')[0] ?? '',
				x: Math.round(dataPoint!),
			}) satisfies ChartData,
	) as ChartData[];

	const averageValue = averagePrice?.prediction?.price?.quantity ?? 0;

	const adrSurcharge =
		averagePrice?.prediction?.predictionFeature?.priceSurcharges?.adr
			.quantity ?? 0;
	const genSetSurcharge =
		averagePrice?.prediction?.predictionFeature?.priceSurcharges?.genSet
			.quantity ?? 0;

	return (
		<>
			<CardHeader>
				<CardTitle>
					{t('pages.price-calculator.result.title', 'Result')}
				</CardTitle>
				<CardDescription className="flex flex-col gap-2">
					{t(
						'pages.price-calculator.result.subheading',
						'The price calculator shows an indication of the current average marketprice for this shipment.',
					)}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col items-start justify-start gap-1.5">
					<h3 className="font-heading text-lg font-medium tracking-tight">
						{t(
							'pages.price-calculator.indication.graph-title',
							'Price indication',
						)}
					</h3>
					<p className="text-sm text-muted-foreground">
						{t(
							'pages.price-calculator.indication.graph-description',
							'This is an indication of the current average market price',
						)}
					</p>
					<Popover>
						<PopoverTrigger asChild>
							<Button variant="outline" size="sm">
								{t('general.learn-more', 'Learn more')}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-96" align="start">
							<div className="grid gap-4">
								<div className="space-y-2">
									<h4 className="font-medium leading-none">
										{t(
											'pages.price-calculator.indication.graph-tooltip-title',
											"What's included",
										)}
									</h4>
									<LearnMorePopoverContent
										adrSurcharge={adrSurcharge}
										genSetSurcharge={genSetSurcharge}
										avaragePrice={averageValue}
									/>
								</div>
								<div className="grid gap-2"></div>
							</div>
						</PopoverContent>
					</Popover>
				</div>
				<div className="mt-2 flex flex-col gap-5">
					<AverageLine
						min={Math.round(
							averagePrice?.prediction?.lowerBound?.quantity ?? 0,
						)}
						max={Math.round(
							averagePrice?.prediction?.upperBound?.quantity ?? 0,
						)}
						average={Math.round(averagePrice?.prediction?.price?.quantity ?? 0)}
					/>
					<p className="mx-auto text-xs text-muted-foreground">
						{t(
							'pages.price-calculator.indication.tip',
							'Tip: shipments published for a higher than average price get matched 32% faster.',
						)}
					</p>
				</div>
				<div className="mt-10 flex h-[22rem] flex-col gap-4 pr-6">
					<h3 className="font-heading text-lg font-medium tracking-tight">
						{t(
							'pages.price-calculator.martket-price-trend.graph-title',
							'	Market price trend',
						)}
					</h3>
					<Chart
						data={data}
						yValues={yValues}
						minYvalue={averageValue * 0.6}
						maxYvalue={averageValue * 1.4}
						ticks={yValues.map((yValue) => yValue.value)}
						tickFormatter={(value: number) => {
							const matchingLabel = yValues.find(
								(yValue) => yValue.value === value,
							);

							return matchingLabel ? matchingLabel.label : '';
						}}
						yValueCustomLine={{ yValue: averageValue }}
						tooltipYdescription="€"
						tooltipVisible={false}
					/>
				</div>
			</CardContent>
			<CardFooter>
				<div className="mt-16 flex w-full flex-row justify-end gap-2">
					<Button
						onClick={() => {
							sonner.success(
								t(
									'general.copied-description',
									'The link has been copied to your clipboard',
								),
							);

							navigator.clipboard.writeText(window.location.href);
						}}
						variant="secondary"
					>
						{t(
							'pages.price-calculator.share-price-indication',
							'	Share price indication',
						)}
					</Button>
					<Link to="/shipments/add">
						<Button ref={inputReference}>
							{t('pages.price-calculator.find-capacity', 'Find capacity')}
						</Button>
					</Link>
				</div>
			</CardFooter>
		</>
	);
}
