import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import type {
	ShippingLineDTO,
	UturnUserRequest,
	UturnUserResponse,
} from '@uturn/api/v1';
import {
	UserSettingsDtoContainerStatus,
	UserSettingsDtoTransportType,
	useFetchUturnUserById,
	useUpdateUserDetails,
} from '@uturn/api/v1';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	RadioGroup,
	RadioGroupItem,
	sonner,
} from '@uturn/ui-kit';

import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { MetaDataContext } from '@uturn/portal/context';

export function EditDefaultValuesModal({
	openModal,
	setOpenModal,
	onSuccess,
}: {
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
	onSuccess?: (data: UturnUserResponse) => void;
}) {
	const { t } = useTranslation();
	const formRef = useRef<HTMLFormElement>(null);

	const [openShippingLine, setOpenShippingLine] = useState(false);
	const [shippingLineList, setShippingLineList] = useState<ShippingLineDTO[]>(
		[],
	);

	const { shipmentMetaData, metadata } = useContext(MetaDataContext);

	const handleOnChangeShippingLines = (e: string) => {
		const value = e;

		const filteredSuggestions = structuredClone(
			shipmentMetaData?.shippingLines,
		)!.filter((suggestion: ShippingLineDTO) =>
			suggestion.description?.toLowerCase().includes(value),
		);

		setShippingLineList(filteredSuggestions);
	};

	useEffect(() => {
		if (shipmentMetaData?.shippingLines.length > 0) {
			setShippingLineList(
				structuredClone(shipmentMetaData?.shippingLines ?? []),
			);
		}
	}, []);

	const { data: user, queryKey } = useFetchUturnUserById(
		metadata!.user?.id as number,
		{},
	);

	const form = useForm<UturnUserRequest>({
		defaultValues: user?.data,
	});

	const client = useQueryClient();

	const { mutate } = useUpdateUserDetails({
		mutation: {
			onSuccess: (data) => {
				sonner.success(
					t(
						'pages.addshipment.manual.settings.form.submit.success.description',
						'Your prefilled values have been updated!',
					),
				);

				client.invalidateQueries(queryKey);
				setOpenModal(false);
				if (onSuccess) {
					onSuccess(data.data);
				}
			},
			onError: () => {
				sonner.error(t('general.try-again-later', 'Please try again later.'));
			},
		},
	});

	useEffect(() => {
		form.reset(user?.data);
	}, [user, form.reset]);

	const handleSubmit = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	// @TODO: Move or remove this helper function if API can return the correct type
	const formatBooleanValue = ({
		value,
		options,
	}: {
		value: boolean | undefined | null;
		options: [string, string, ...string[]];
	}) => {
		if (value === undefined || value === null) return '';

		return value ? options[0] : options[1];
	};

	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(
							'pages.addshipment.manual.modal.edit-default-values.heading',
							'Update my prefilled values',
						)}
					</DialogTitle>
					<DialogDescription>
						{t(
							'pages.addshipment.manual.modal.edit-default-values.subheading',
							'Every new shipment you create will use the following values.',
						)}
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((formData) => {
							mutate({
								id: metadata!.user?.id as number,
								data: formData satisfies UturnUserRequest,
							});
						})}
						ref={formRef}
						className="space-y-4"
					>
						<FormField
							control={form.control}
							name="settings.shippingLineId"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'general.default-shipment-values.shipping-line.label',
											'Shipping line',
										)}
									</FormLabel>
									<Popover
										open={openShippingLine}
										onOpenChange={setOpenShippingLine}
									>
										<PopoverTrigger asChild>
											<FormControl>
												<Button
													variant="outline"
													role="combobox"
													className={twJoin(
														'w-full justify-between',
														!field.value && 'text-muted-foreground',
													)}
												>
													{field.value !== -1 && field.value
														? shipmentMetaData?.shippingLines?.find(
																(shippingLine: ShippingLineDTO) =>
																	shippingLine.id === field.value,
															)?.description
														: t(
																'pages.addshipment.manual.settings.form.shipping-line.placeholder',
																'Select shipping line',
															)}
													<Icon icon={faChevronDown} />
												</Button>
											</FormControl>
										</PopoverTrigger>
										<PopoverContent align="start" className="w-full p-0">
											<Command>
												<CommandInput
													placeholder={t(
														'pages.addshipment.manual.settings.form.shipping-line.dropdown.placeholder',
														'Search shipping line...',
													).toString()}
													onValueChange={handleOnChangeShippingLines}
												/>
												<CommandEmpty>No shipping line found.</CommandEmpty>
												<CommandGroup className="h-[300px] overflow-y-scroll">
													{field.value && field.value !== -1 && (
														<CommandItem
															value="None"
															className="py-2 font-bold"
															onSelect={() => {
																form.setValue('settings.shippingLineId', -1);
																setOpenShippingLine(false);
																setShippingLineList(
																	structuredClone(
																		shipmentMetaData?.shippingLines ?? [],
																	),
																);
															}}
														>
															<Icon
																icon={faCheck}
																className={twJoin(
																	'mr-2 h-4 w-4',
																	!field.value ? 'opacity-100' : 'opacity-0',
																)}
															/>
															None
														</CommandItem>
													)}
													{shippingLineList.map(
														(shippingLine: ShippingLineDTO) => (
															<CommandItem
																value={shippingLine.description}
																key={shippingLine.code}
																onSelect={() => {
																	const { id } = shippingLine;

																	if (shippingLine.id === field.value) {
																		form.setValue(
																			'settings.shippingLineId',
																			-1,
																		);
																	} else {
																		form.setValue(
																			'settings.shippingLineId',
																			id,
																		);
																	}

																	setOpenShippingLine(false);
																	setShippingLineList(
																		structuredClone(
																			shipmentMetaData?.shippingLines ?? [],
																		),
																	);
																}}
															>
																<Icon
																	icon={faCheck}
																	className={twJoin(
																		'mr-2 h-4 w-4',
																		shippingLine.id === field.value
																			? 'opacity-100'
																			: 'opacity-0',
																	)}
																/>
																{shippingLine.description}
															</CommandItem>
														),
													)}
												</CommandGroup>
											</Command>
										</PopoverContent>
									</Popover>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="settings.transportType"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'general.default-shipment-values.transport-type.label',
											'Transport type',
										)}
									</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e);
											}}
											defaultValue={field.value}
											className="flex flex-col space-y-1"
										>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem
														value={UserSettingsDtoTransportType.IMPORT}
													/>
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.transport-type.values.import',
														'Import',
													)}
												</FormLabel>
											</FormItem>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem
														value={UserSettingsDtoTransportType.EXPORT}
													/>
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.transport-type.values.export',
														'Export',
													)}
												</FormLabel>
											</FormItem>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem
														value={UserSettingsDtoTransportType.SHUNT}
													/>
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.transport-type.values.shunt',
														'Shunt',
													)}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="settings.fixedPrice"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'general.default-shipment-values.carrier-can.label',
											'Carrier can',
										)}
									</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e === 'bid-accept');
											}}
											value={formatBooleanValue({
												value: field.value,
												options: ['bid-accept', 'bid-only'],
											})}
											className="flex flex-col space-y-1"
										>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem value="bid-only" />
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.carrier-can.values.bid-only',
														'Bid only',
													)}
												</FormLabel>
											</FormItem>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem value="bid-accept" />
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.carrier-can.values.bid-only-accept',
														'Bid or accept',
													)}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="settings.requiresVat"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'general.default-shipment-values.goods-cleared.label',
											'Goods cleared',
										)}
									</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e === 'regular');
											}}
											value={formatBooleanValue({
												value: field.value,
												options: ['regular', 'free'],
											})}
											className="flex flex-col space-y-1"
										>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem value="free" />
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.goods-cleared.values.free-vat',
														'Free of VAT',
													)}
												</FormLabel>
											</FormItem>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem value="regular" />
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.goods-cleared.values.regular-vat',
														'Regular VAT',
													)}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="settings.containerStatus"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'general.default-shipment-values.container-status.label',
											'Container status',
										)}
									</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e);
											}}
											defaultValue={field.value}
											className="flex flex-col space-y-1"
										>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem
														value={UserSettingsDtoContainerStatus.FULL}
													/>
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.container-status.values.full',
														'Full',
													)}
												</FormLabel>
											</FormItem>
											<FormItem className="flex items-center space-x-3 space-y-0">
												<FormControl>
													<RadioGroupItem
														value={UserSettingsDtoContainerStatus.EMPTY}
													/>
												</FormControl>
												<FormLabel className="cursor-pointer font-normal">
													{t(
														'general.default-shipment-values.container-status.values.empty',
														'Empty',
													)}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
					</form>
				</Form>
				<DialogFooter>
					<DialogClose asChild>
						<Button variant="outline">
							{t(
								'pages.addshipment.manual.modal.edit-default-value.cancel',
								'Cancel',
							)}
						</Button>
					</DialogClose>
					<Button
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						{t(
							'pages.addshipment.manual.modal.edit-default-value.save-values',
							'Update',
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
