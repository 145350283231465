import type {
	Co2EmissionUnit,
	CurrencyDTO,
	CurrencyType,
	DistanceUnit,
	DurationUnit,
	WeightUnit,
} from '@uturn/api/v1';
import type { ShippingLocationDto } from '@uturn/api/v2';
import { type AxiosError, type AxiosResponse } from 'axios';

export function copyValue(value: string) {
	navigator.clipboard.writeText(value);
}

// TODO: implement user setting instead of window.navigator.language
const browserPreferredLanguage = window.navigator.language; // 'nl-NL',

export function formatPrice(amount: number, currency: CurrencyType | string) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'currency',
		currency,
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	}).format(amount);
}

/**
 * @deprecated
 * Use formatPrice instead.
 */
export function priceToCurrency(
	amount: number,
	currency: CurrencyType | string,
) {
	return formatPrice(amount, currency);
}

export function currencySymbol(currency: string) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'currency',
		currency,
	})
		.formatToParts(1)
		.find((x) => x.type === 'currency')?.value;
}

export function formatDistance(amount: number, unit: DistanceUnit) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'unit',
		unit,
		unitDisplay: 'short',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	}).format(amount);
}

export function formatCo2Emission(
	amount: number,
	unit: Co2EmissionUnit | string,
) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'unit',
		unit,
		unitDisplay: 'short',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	}).format(amount);
}

export function formatWeight(amount: number, unit: WeightUnit | string) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'unit',
		unit,
		unitDisplay: 'short',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	}).format(amount);
}

export function formatDuration(amount: number, unit: DurationUnit | string) {
	return new Intl.NumberFormat(browserPreferredLanguage, {
		style: 'unit',
		unit,
		unitDisplay: 'short',
		maximumFractionDigits: 1,
		minimumFractionDigits: 0,
	}).format(amount);
}

const defaultCurrencySign = '€';

/**
 * Returns currency sign for a given currency code.
 * @param currencyCode
 * @param currencies
 * @returns currencySign
 * @example getCurrencySign('EUR', shipmentMetaData?.currencies) => "€"
 */
export function getCurrencySign(
	currencyCode?: string,
	currencies?: CurrencyDTO[],
): string {
	if (!currencyCode || !currencies) {
		return defaultCurrencySign;
	}
	return (
		currencies.find((currency) => currency.code === currencyCode)?.sign ??
		defaultCurrencySign
	);
}

export const inputDatetimeFormatOptions: Intl.DateTimeFormatOptions = {
	dateStyle: 'short',
	timeStyle: 'short',
};

export const outputDateFormatOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
};

export const outputDatetimeFormatOptions: Intl.DateTimeFormatOptions = {
	...outputDateFormatOptions,
	hour: '2-digit',
	minute: '2-digit',
};

export function formatDate(
	date: string | undefined,
	options: Intl.DateTimeFormatOptions = {},
): string | undefined {
	if (!date) return undefined;
	return new Intl.DateTimeFormat(browserPreferredLanguage, options).format(
		new Date(date),
	);
}

export function formatDatetime(
	date: string | undefined,
	options: Intl.DateTimeFormatOptions = inputDatetimeFormatOptions,
): string | undefined {
	return formatDate(date, options);
}

export function downloadFile(request: Promise<unknown>, fileName: string) {
	return (request as Promise<AxiosResponse>).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
}

export function queryDownloadFile(response: any, fileName: string) {
	const fileBlob = response.data;
	const blob = new Blob([fileBlob], {
		type: 'application/octet-stream',
	});

	const extension = fileBlob.type.substring(fileBlob.type.lastIndexOf('/') + 1);

	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');

	link.href = url;
	link.setAttribute('download', `${fileName}.${extension}`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function sortData(
	data: [Record<string, number | string>],
	key: string,
	direction: 'asc' | 'desc',
) {
	return [...data].sort((a, b) => {
		if (a[key] === null || a[key] === undefined) {
			return 1;
		}
		if (b[key] === null || b[key] === undefined) {
			return -1;
		}
		if (
			(a[key] === null && b[key] === null) ||
			(a[key] === undefined && b[key] === undefined)
		) {
			return 0;
		}
		return (
			a[key].toString().localeCompare(b[key]!.toString(), 'en', {
				numeric: true,
			}) * (direction === 'asc' ? 1 : -1)
		);
	});
}

export function getLocationQueryString(location: any) {
	if (location === undefined) {
		return '';
	}

	if (typeof location === 'string') {
		return location;
	}

	// TODO: Clean this garbage up
	return [
		location.name,
		location.street
			? location.street +
				(location.houseNumber ? ` ${location.houseNumber}` : '')
			: undefined,
		location.post ? `${location.postalCode} ${location.city}` : location.city,
		location.countryCode,
	]
		.filter((part) => part !== undefined)
		.join(', ');
}

/**
 * Return location city.
 * When city isn't available, show a combination of postal and country code.
 *
 * @param location ShippingLocationDto
 * @returns
 *
 * @see ShipmentCard
 */
export function getLocationCity(location: ShippingLocationDto): string {
	if (location === undefined) {
		return '';
	}

	if (location.address?.city) {
		return location.address.city;
	}

	if (location.name && location.address?.countryCode) {
		if (location.address?.postalCode) {
			return `${location.name}, ${location.address.postalCode}, ${location.address.countryCode}`;
		}
		return `${location.name}, ${location.address.countryCode}`;
	}

	return location.name;
}

export function toTitle(data: string) {
	return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
}

export function getApiErrorMessage(error: AxiosError | any): string {
	if ('response' in error) {
		if (error.response?.data?.message) {
			return error.response.data.message;
		}
		if (error.response?.data) {
			if (typeof error.response.data === 'object') {
				if ('error' in error.response.data) {
					return error.response.data.error;
				}
				return JSON.stringify(error.response.data); // Unsupported error type
			}
			return error.response.data;
		}
	}
	if ('message' in error) {
		if (error.message) {
			return error.message;
		}
	}
	if ('error' in error) {
		return error.error;
	}
	return JSON.stringify(error); // Unsupported error type
}

export function isSelected<T>(value: T, items: T[]): boolean {
	return items.findIndex((item) => item === value) !== -1;
}

/**
 * @deprecated
 * Use formatDate or formatDatetime instead.
 */
export function dateFormatter(options: Intl.DateTimeFormatOptions = {}) {
	return new Intl.DateTimeFormat(browserPreferredLanguage, options);
}

/**
 * @deprecated
 */
export function stringRound(num: string, decimals: number) {
	if (!Number(num)) return num;

	return Math.round(Number(num) * decimals) / decimals;
}

/**
 * Checks if the pathname is a shipment number
 * @deprecated
 * @param pathname pathname to check if it is a shipment number
 * @returns
 */
export function pathIsShipmentNumber(pathname: string): boolean {
	const path = pathname.replace('/', '');

	return path.length === 8 && path[0] === '7' && !Number.isNaN(Number(path));
}
