import {
	faExclamationTriangle,
	faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
	Button,
	DialogClose,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Icon,
	ScrollArea,
} from '@uturn/ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeRequestSection } from './change-request-section';
import type { ChangeRequestProps, SectionType } from './change-request.types';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';

export function ChangeRequest({ handleSubmit }: ChangeRequestProps) {
	const { t } = useTranslation();
	const [sections, setSections] = useState<SectionType[]>([]);
	const { changeRequestChanges } = useContext(ShipmentDetailContext);

	useEffect(() => {
		setSections(changeRequestChanges);
	}, [changeRequestChanges]);

	return (
		<>
			<DialogHeader>
				<DialogTitle>
					{t(
						'pages.shipment-detail.modal.change-request.heading',
						'Update contract of carriage',
					)}
				</DialogTitle>
				<DialogDescription>
					<Icon
						icon={faExclamationTriangle}
						className="mr-2 size-4 text-destructive"
					/>
					{t(
						'pages.shipment-detail.modal.change-request.subheading',
						'Be aware that the carrier must first confirm these changes.',
					)}
				</DialogDescription>
			</DialogHeader>
			<ScrollArea>
				<div className="flex min-h-screen w-full flex-col gap-8 text-left md:min-h-fit">
					<div className="-ml-12 w-[calc(100%+6rem)] overflow-y-scroll px-12 pb-8 pt-2 md:h-[45vh]">
						{sections &&
							sections.map((section, index) => (
								<ChangeRequestSection key={index} {...section} />
							))}
					</div>
				</div>
			</ScrollArea>
			<DialogFooter className="flex w-full sm:justify-between">
				<p className="flex items-center gap-1.5 text-sm leading-6 text-muted-foreground">
					<Icon icon={faInfoCircle} className="size-4" />
					{t(
						'pages.shipment-detail.modal.change-request.remark',
						'You can keep track of your changes in the Event Log.',
					)}
				</p>
				<div className="flex justify-end gap-4">
					<DialogClose asChild>
						<Button variant="outline">
							{t('pages.shipment-detail.modal.change-request.cancel', 'Cancel')}
						</Button>
					</DialogClose>
					<DialogClose asChild>
						<Button onClick={() => handleSubmit()}>
							{t(
								'pages.shipment-detail.modal.change-request.confirm',
								'Send request',
							)}
						</Button>
					</DialogClose>
				</div>
			</DialogFooter>
		</>
	);
}
