import type { CustomCellEditorProps } from '@uturn/ui-kit';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@uturn/ui-kit';
import { type FC, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useShipmentLocationSuggestions } from '@uturn/portal/hooks/use-shipment-location.suggestions';
import { formatSuggestion } from '@uturn/portal/utils/format-address-lookup-suggestions';
import isMetaKey from '@uturn/portal/utils/is-meta-key';

type Props = CustomCellEditorProps;

const LocationAutoCompleteEditor: FC<Props> = ({
	value: data,
	onValueChange,
	api,
	rowIndex,
	column,
	eventKey,
}) => {
	const { locations, getLocations } = useShipmentLocationSuggestions();
	const searchRef = useRef<HTMLInputElement>(null);
	const [searchValue, setSearchValue] = useState(
		eventKey && !isMetaKey(eventKey) ? eventKey : '',
	);
	const [value, setValue] = useState(data);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(true);
	}, []);

	const debouncedGetLocations = useDebouncedCallback((searchTerm) => {
		getLocations(searchTerm);
	}, 1000);

	const onSelect = (location: any) => {
		setValue(location);
		onValueChange(location);
		setOpen(false);

		/**
		 * After selecting an item, we want to stop editing the cell, but doing so removes the focus from the cell.
		 * So after calling .stopEditing() we need to set the focus back to the cell
		 */
		api.stopEditing();
		api.setFocusedCell(rowIndex, column.getColId());
	};

	const onValueChangeHandler = (search: string) => {
		setSearchValue(search);

		if (search.length < 2) {
			return;
		}

		debouncedGetLocations(search);
	};

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => {
				setOpen(isOpen);

				if (isOpen) {
					return;
				}

				/**
				 * Whenever the popover is closed, we want to stop editing the cell, but doing so removes the focus from the cell.
				 * So after calling .stopEditing() we need to set the focus back to the cell
				 */
				api.stopEditing();
				api.setFocusedCell(rowIndex, column.getColId());
			}}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full"
					name="selectButton"
				>
					{value && formatSuggestion(value)}
				</Button>
			</PopoverTrigger>
			{/* className "ag-custom-component-popup" is required for the popover to work in combination with ag-grid option stopEditingWhenCellsLoseFocus=true */}
			<PopoverContent className="ag-custom-component-popup max-h-[300px] w-[300px] overflow-y-scroll p-0">
				<Command shouldFilter={false}>
					<CommandInput
						ref={searchRef}
						value={searchValue}
						onValueChange={onValueChangeHandler}
						placeholder="Search..."
						onSelect={(params) => {
							/**
							 * params.nativeEvent has a global "Event" type, which can be MouseEvent or KeyboardEvent
							 * that's why we check if key exists on the event to check for the meta key after
							 *
							 * The reason we do the following, is because we can't overwrite the select behavior
							 * and don't want to actually select the value in the input unless the user selects using a meta key (e.g. ctrl + a, ctrl + <, etc)
							 */
							if (
								'key' in params.nativeEvent &&
								isMetaKey(params.nativeEvent.key as string)
							) {
								return;
							}

							searchRef?.current?.setSelectionRange(
								searchValue.length,
								searchValue.length,
							);
						}}
					/>
					<CommandEmpty>No results</CommandEmpty>
					<CommandGroup>
						{locations.map((location) => (
							<CommandItem
								key={location.placeId}
								onSelect={() => onSelect(location)}
							>
								{formatSuggestion(location)}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default LocationAutoCompleteEditor;
