import { RouteType } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';

export const numberOfCopiesToBeCreatedSchema = z.number();

export const newGroupSchema = z.object({
	name: z.string().optional(),
});

const routeKpiSchema = z.object({
	operator: z.string().optional(),
	routeType: z.nativeEnum(RouteType).optional(),
});

const routeKpiSelectionSchema = z.object({
	isSelected: z.boolean().optional(),
});

/**
 * Route KPI's
 * routeKpis useState variables to replace with zod schema
 * in <ShipmentMapSection setRouteKpis={setRouteKpis}>
 */
export const routeKpisSchema = routeKpiSchema
	.merge(routeKpiSelectionSchema)
	.array();
