import { type QueryKey, useQueryClient } from '@tanstack/react-query';
import { type UturnFileDTO, useDeleteDocument } from '@uturn/api/v1';

import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	sonner,
} from '@uturn/ui-kit';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ConfirmDeleteDocumentModal({
	toDeleteDocument,
	documentQueryKey,
	openModal,
	setOpenModal,
}: {
	toDeleteDocument?: UturnFileDTO;
	documentQueryKey?: QueryKey;
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
}) {
	const { t } = useTranslation();

	const [triggerDeleteDocument, setTriggerDeleteDocument] = useState(false);

	const queryClient = useQueryClient();

	useDeleteDocument(toDeleteDocument?.uuid ?? '', {
		query: {
			enabled: triggerDeleteDocument,
			onSuccess: () => {
				setTriggerDeleteDocument(false);
				queryClient.invalidateQueries({ queryKey: documentQueryKey });
				sonner.success(
					t(
						'pages.shipment-detail.modal.delete-document.submit.success.description',
						'Document deleted successfully.',
					),
				);
			},
			onError: () => {
				setTriggerDeleteDocument(false);
				sonner.error(
					t(
						'pages.shipment-detail.modal.delete-document.submit.error.description',
						'Error deleting document',
					),
				);
			},
			suspense: false,
		},
	});

	if (!toDeleteDocument) return null;

	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(
							'pages.shipment-detail.modal.delete-document.title',
							'Delete document',
						)}
					</DialogTitle>
					<DialogDescription>
						{t(
							'pages.shipment-detail.modal.delete-document.description',
							'Are you sure that you want to delete {{ document }}?',
							{
								document: `${toDeleteDocument.fileTypeDescription} - ${toDeleteDocument.description}`,
							},
						)}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<DialogClose asChild>
						<Button variant="outline">
							{t('general.actions.cancel', 'Cancel')}
						</Button>
					</DialogClose>
					<DialogClose asChild>
						<Button
							variant="destructive"
							onClick={() => {
								setTriggerDeleteDocument(true);
							}}
						>
							{t(
								'pages.shipment-detail.modal.delete-document.confirm',
								'Delete',
							)}
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
