import type { ShipmentDtoShipmentStatus } from '@uturn/api/v2';
import type { TFunction } from 'i18next';

export const shipmentStatusName = (
	t: TFunction<'translation', undefined>,
): Record<ShipmentDtoShipmentStatus, string> => ({
	CONCEPT: t('general.shipments.status.concept', 'Concept'),
	PUBLISHED: t('general.shipments.status.published', 'Published'),
	CARRIER_ASSIGNED: t('general.shipments.status.assigned', 'Assigned'),
	CANCELLED: t('general.shipments.status.cancelled', 'Cancelled'),
	COMPLETED: t('general.shipments.status.completed', 'Completed'),
	QUOTED: t('general.shipments.status.quoted', 'Quoted'),
	EXECUTED: t('general.shipments.status.executed', 'Executed'),
	UNKNOWN: '',
	COST_DECLARED: t('general.shipments.status.cost_declared', 'Cost declared'),
});
