import type { AddressDTO } from '@uturn/api/v1';
import { createContext } from 'react';

export const PriceCalculatorContext = createContext<{
	locations: AddressDTO[];
	setLocations: (locations: AddressDTO[]) => void;
}>({
	locations: [],
	setLocations: () => {},
});
