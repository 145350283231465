import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { InvoicesTable } from '../components/table';
import { LoadingSpan, Page } from '@uturn/portal/components';

export function InvoicesOverview() {
	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t('pages.invoices.title', 'Invoices')!} />
			<Page title={t('pages.invoices.title', 'Invoices')!}>
				<Suspense fallback={<LoadingSpan />}>
					<InvoicesTable />
				</Suspense>
			</Page>
		</>
	);
}
