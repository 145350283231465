// TODO: Rename file to form-fetch.ts
//       As there is no update form schema here anymore.
import {
	Co2EmissionUnit,
	DistanceUnit,
	DurationUnit,
	RouteType,
} from '@uturn/api/v2';
import { z } from '@uturn/portal/zod';

const co2EmissionSchema = z.object({
	quantity: z.coerce.number().nonnegative(),
	unit: z.nativeEnum(Co2EmissionUnit).default('kilogram'),
});

const distanceSchema = z.object({
	quantity: z.coerce.number().nonnegative(),
	unit: z.nativeEnum(DistanceUnit).default('kilometer'),
});

const durationSchema = z.object({
	quantity: z.coerce.number().nonnegative(),
	unit: z.nativeEnum(DurationUnit).default('hour'),
});

const routeDetailsSchema = z.object({
	co2Emission: co2EmissionSchema.optional(),
	distance: distanceSchema.optional(),
	duration: durationSchema.optional(),
});

const chosenRouteSchema = z.object({
	operatorId: z.string().optional(),
	routeDetails: routeDetailsSchema.optional(),
	routeType: z.nativeEnum(RouteType).optional(),
	validationMessages: z.string().array().optional(),
});

export const chosenRoutesSchema = chosenRouteSchema.array();
