import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Input,
	Label,
} from '@uturn/ui-kit';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export function PriceRequestLeadForm({
	onSubmit,
	openModal,
	setOpenModal,
}: {
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
	onSubmit: (email: string) => void;
}) {
	const { t } = useTranslation();
	const emailRef = useRef<HTMLInputElement>(null);
	const [error, hasError] = useState(false);
	const emailSchema = z.string().email();

	const emailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (error) {
			if (emailSchema.safeParse(e.target.value ?? '').success) {
				hasError(false);
			}
		}
	};
	const inputError = error
		? t('TODO', 'Enter a valid e-mail address')
		: undefined;

	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t('pages.price-calculator.modal.title', 'Request price')}
					</DialogTitle>
					<DialogDescription>
						{t(
							'pages.price-calculator.body',
							"Just leave your email address below and we'll get back to you as soon as possible.",
						)}
					</DialogDescription>
				</DialogHeader>
				<Label>{t('general-email', 'E-mail')}</Label>
				<Input
					ref={emailRef}
					onChange={emailOnChange}
					placeholder="name@company.com"
				/>
				{inputError && (
					<span className="text-sm text-destructive">{inputError}</span>
				)}
				<DialogFooter>
					<DialogClose asChild>
						<Button variant="outline">
							{t('pages.price-calculator.modal.close', 'Cancel')}
						</Button>
					</DialogClose>
					<Button
						onClick={() => {
							if (
								emailSchema.safeParse(emailRef.current?.value ?? '').success
							) {
								onSubmit(emailRef.current?.value ?? '');
								setOpenModal(false);
							} else {
								hasError(true);
							}
						}}
					>
						{t('pages.price-calculator.modal.lead-send', 'Let’s go!')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
