import type { ShipmentStatusCount } from '@uturn/api/v1';
import { useTranslation } from 'react-i18next';

export function ShipmentGroupStatusTile({
	shipmentStatusCount,
}: {
	shipmentStatusCount: ShipmentStatusCount;
}) {
	const { t } = useTranslation();

	return (
		<div className="w-24 rounded bg-secondary px-3 py-2 text-left">
			<h4 className="flex flex-col">
				<span className="text-sm text-muted-foreground">
					{t(
						`pages.shipments.groups.overview.card.status-tile.${shipmentStatusCount.shipmentStatus}`,
						'Archived',
					)}
				</span>
				<span className="text-3xl font-bold leading-8 tracking-tight">
					{shipmentStatusCount.count}
				</span>
			</h4>
		</div>
	);
}
