import type { AddressLookupResponse } from '@uturn/api/v1';
import {
	Button,
	Sheet,
	SheetContent,
	SheetTrigger,
	Stars,
} from '@uturn/ui-kit';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentLocationOpeningHours } from './shipment-location-opening-hours';
import { LoadingSpan } from '@uturn/portal/components';

export type ShipmentLocationInfoProps = {
	data: AddressLookupResponse;
};

export function ShipmentLocationInfo({ data }: ShipmentLocationInfoProps) {
	const { t } = useTranslation();
	return (
		<div className="sm:col-span-full">
			<div className="flex items-center justify-between">
				{data.rating?.averageRating && (
					<div className="flex gap-2 text-sm text-gray-500">
						<Stars rating={data.rating?.averageRating} /> (
						{t('TODO', '{{ amount }} reviews', {
							amount: data.rating.nrOfTimesRated,
						})}
					</div>
				)}

				<Sheet>
					<SheetTrigger asChild>
						<Button variant="secondary" formNoValidate>
							{t('general.opening-hours', 'Opening hours')}
						</Button>
					</SheetTrigger>
					<SheetContent>
						<Suspense fallback={<LoadingSpan />}>
							<ShipmentLocationOpeningHours id={data.id!} />
						</Suspense>
					</SheetContent>
				</Sheet>
			</div>
		</div>
	);
}
