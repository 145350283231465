import { zodResolver } from '@hookform/resolvers/zod';
import type { CreateLaneDto } from '@uturn/api/v1';
import { useFetchOrganizationById, useSaveLaneDetails } from '@uturn/api/v1';
import { Button, Form, sonner } from '@uturn/ui-kit';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { z } from 'zod';
import { ContainerForm } from '../components/form/container-form';
import { RouteForm } from '../components/form/route-form';
import { ShipmentForm } from '../components/form/shipment-form';
import { formSchema } from '../schema/form-schema';
import { Page } from '@uturn/portal/components';
import { MetaDataContext } from '@uturn/portal/context';

export function LaneCreate() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			transportType: 'IMPORT',
			route: [
				{
					locationType: 'PICKUP',
				},
				{
					locationType: 'DELIVER',
				},
				{
					locationType: 'DROP',
				},
			],
		},
	});

	const { mutate, isLoading, isSuccess, data } = useSaveLaneDetails();

	const { metadata } = useContext(MetaDataContext);
	const { data: organisation } = useFetchOrganizationById(
		Number(metadata?.user?.organisation?.id) ?? 0,
	);

	useEffect(() => {
		if (organisation?.data.financialDetails?.currency === undefined) return;

		form.setValue(
			'pricePerContainer.currencyCode',
			organisation.data.financialDetails.currency,
		);
	}, [organisation]);

	useEffect(() => {
		if (!isSuccess) return;

		navigate('../');
		sonner.success(
			t('pages.lanes.create.form.submit.success.description', {
				lane: data.data,
				defaultValue:
					'Lane {{ lane }} has been published. Carriers can now see and quote on your lane.',
			}),
		);
	}, [isSuccess, navigate]);

	function onSubmit(formData: z.infer<typeof formSchema>) {
		mutate({
			data: formData as unknown as CreateLaneDto,
		});
	}

	return (
		<>
			<Helmet title={t('pages.lanes.create.title', 'Create lane')!} />
			<Page
				backButton={{
					label: t('pages.lanes.back', 'Back'),
					href: '..',
				}}
				title={t('pages.lanes.create.title', 'Create lane')!}
				className="m-auto md:max-w-[550px]"
			>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-6 pb-64"
					>
						<ShipmentForm />
						<ContainerForm />
						<RouteForm />

						<div className="flex justify-end">
							<Button disabled={isLoading} type="submit" variant="default">
								{t('pages.lanes.create.submit', 'Create lane')}
							</Button>
						</div>
					</form>
				</Form>
			</Page>
		</>
	);
}
