import { countryCodeSchema } from './country-code';
import type { locationSchema } from './location-action';
import {
	locationActionConceptSchema,
	locationActionPublishSchema,
} from './location-action';
import { z } from '@uturn/portal/zod';

export const formLocationConceptSchema = z.object({
	countryCode: countryCodeSchema.optional(),
	action: locationActionConceptSchema.optional(),
});

export const formLocationPublishSchema = z.object({
	countryCode: countryCodeSchema.optional(),
	action: locationActionPublishSchema.optional(),
});

export type ZodLocation =
	| z.infer<typeof formLocationConceptSchema>
	| z.infer<typeof formLocationPublishSchema>;

export type ZodLocationAction =
	| z.infer<typeof locationActionConceptSchema>
	| z.infer<typeof locationActionPublishSchema>;

export type ZodLocationActionLocation = z.infer<typeof locationSchema>;
