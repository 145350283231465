import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';

export const isShipmentArchived = (
	status: ShipmentStatus | undefined = ShipmentStatus.UNKNOWN,
): boolean => {
	return (
		status === ShipmentStatus.CANCELLED ||
		status === ShipmentStatus.COMPLETED ||
		status === ShipmentStatus.EXECUTED
	);
};

export const isShipmentMatched = (
	status: ShipmentStatus | undefined = ShipmentStatus.UNKNOWN,
): boolean => {
	return (
		status === ShipmentStatus.CARRIER_ASSIGNED ||
		status === ShipmentStatus.COST_DECLARED ||
		isShipmentArchived(status)
	);
};

export const isShipmentPublished = (
	status: ShipmentStatus | undefined = ShipmentStatus.UNKNOWN,
): boolean => {
	return (
		status === ShipmentStatus.PUBLISHED || status === ShipmentStatus.QUOTED
	);
};

export const isShipmentCancellable = (
	status: ShipmentStatus | undefined = ShipmentStatus.UNKNOWN,
): boolean => {
	return (
		status === ShipmentStatus.CONCEPT ||
		status === ShipmentStatus.PUBLISHED ||
		status === ShipmentStatus.QUOTED ||
		status === ShipmentStatus.CARRIER_ASSIGNED
	);
};
