import { detailsSchema, fileRequestsSchema } from './additional-information';
import {
	cargoDescriptionSchema,
	grossWeightSchema,
	unCodeSchema,
} from './cargo';
import {
	equipmentSchema,
	generatorSetSchema,
	sealSchema,
	shippingLineIdSchema,
	shippingLineSchema,
	tareWeightSchema,
} from './container';
import {
	newGroupSchema,
	numberOfCopiesToBeCreatedSchema,
	routeKpisSchema,
} from './form-create';
import { chosenRoutesSchema } from './form-update';
import {
	formLocationConceptSchema,
	formLocationPublishSchema,
} from './location';
import {
	containerStatusSchema,
	containerTypeSchema,
	invoiceRequiredSchema,
	transportTypeSchema,
	typeOfShipmentSchema,
} from './metadata';
import {
	conditionsSchema,
	dutiesPaidSchema,
	fixedPriceSchema,
	preferredCarrierIdsSchema,
	priceSchema,
	publishUntilDateSchema,
	shipperReferenceSchema,
} from './publish-details';
import { requirementIdSchema } from './requirements';
import { z } from '@uturn/portal/zod';

const coreFormSchema = z.object({
	// createShipment
	numberOfCopiesToBeCreated: numberOfCopiesToBeCreatedSchema.optional(),
	newGroup: newGroupSchema.optional(),
	// createShipment/updateShipment
	routeKpis: routeKpisSchema.optional(), // from POST and PUT Shipment
	// fetchShipment
	routes: chosenRoutesSchema.optional(), // from GET Shipment
	// container
	equipment: equipmentSchema, // 🔴 Required
	generatorSet: generatorSetSchema.optional(),
	shippingLineId: shippingLineIdSchema.optional(),
	shippingLine: shippingLineSchema.optional(), // Do we need this?
	seal: sealSchema.optional(),
	tareWeight: tareWeightSchema.optional(),
	// cargo
	grossWeight: grossWeightSchema.optional(), // 🟠 Required Conditionaly (see apps/portal/src/modules/shipments/validation/gross-weight.ts)
	unCode: unCodeSchema.optional(),
	cargoDescription: cargoDescriptionSchema.optional(),
	// requirements
	requirementIds: requirementIdSchema.array().optional(),
	// additional-information
	details: detailsSchema.optional(),
	fileRequests: fileRequestsSchema.optional(), // ⏰ (+ Documents not refactored yet)
	// publish-detail
	preferredCarrierIds: preferredCarrierIdsSchema.optional(), // from POST/PUT Shipment (preferredCarriersIds POST shipment)
	price: priceSchema.optional(), // 🟠 Required Conditionaly (see apps/portal/src/modules/shipments/validation/price.ts)
	fixedPrice: fixedPriceSchema.optional(),
	shipperReference: shipperReferenceSchema.optional(), // 🟠 Required Conditionaly (see apps/portal/src/modules/shipments/validation/shipper-reference.ts)
	dutiesPaid: dutiesPaidSchema.optional(),
	conditions: conditionsSchema.optional(),
	// metadata
	// TODO: Add isCrossBorderShipment, isGBRShipment here
	transportType: transportTypeSchema,
	typeOfShipment: typeOfShipmentSchema,
	containerType: containerTypeSchema,
	containerStatus: containerStatusSchema,
	invoiceRequired: invoiceRequiredSchema,
});

const conceptFormSchema = coreFormSchema.extend({
	// createShipment
	concept: z.literal(true),
	// locations
	locations: formLocationConceptSchema.array().min(2), // 🔴 Required
	// publish-detail
	publishUntilDate: publishUntilDateSchema.optional(),
});

const publishFormSchema = coreFormSchema.extend({
	// createShipment
	concept: z.literal(false),
	// locations
	locations: formLocationPublishSchema.array().min(2), // 🔴 Required
	// publish-detail
	publishUntilDate: publishUntilDateSchema, // 🔴 Required
});

// TODO: Add translations via use github.com/aiji42/zod-i18n.
export const formSchema = z
	.discriminatedUnion('concept', [conceptFormSchema, publishFormSchema])
	.transform((value) => ({
		...value,
		/**
		 * Removes empty routes from the form and transforms them into the
		 * correct locationActions format for API endpoint.
		 *
		 * The BE expects a location.id for each locationAction. If none are
		 * available, the FE should send the location.googlePlaceId instead.
		 * But not both!!!!
		 *
		 * Miscellaneous:
		 * - Fix FE locationActionId's from FE managed route locations.
		 * - Fix shippingLineId from inserted route locations.
		 */
		locationActions: value.locations
			.filter((item) => {
				const { action } = item;
				const { location } = action ?? {};
				const { id, googlePlaceId } = location ?? {};
				return id || googlePlaceId;
			})
			.map((item, index) => {
				const { action } = item;
				const {
					id: locationActionId,
					location,
					shippingLine,
					shippingLineId,
					...rest
				} = action ?? {};
				const { id: locationActionLocationId, googlePlaceId } = location ?? {};
				return {
					...rest,
					id:
						typeof locationActionId === 'number' ? locationActionId : undefined,
					shippingLineId:
						(shippingLineId ?? 0) < 1 ? undefined : shippingLineId,
					sequenceNumber: index + 1,
					location: {
						...(locationActionLocationId
							? { id: locationActionLocationId }
							: { googlePlaceId }),
					},
				};
			}),
	}));

export type FormValues = z.infer<typeof formSchema>;
