import type { RouteObject } from 'react-router-dom';
import { AuthSignIn } from '../modules/auth/pages/sign-in';
import { PublicPriceCalculator } from '../modules/price-calculator/pages/unauthenticated';
import { AuthResetPassword } from '@uturn/portal/modules/auth/pages/reset';

export const publicRoutes: RouteObject[] = [
	{
		path: '/sign-in',
		Component: () => <AuthSignIn />,
	},
	{
		path: '/price-calculator/:id?',
		Component: () => <PublicPriceCalculator />,
	},
	{
		path: '/reset-password',
		Component: () => <AuthResetPassword />,
	},
];
