import { CurrencyType } from '@uturn/api/v1';
import type {
	AgGridReact,
	ColDef,
	CustomCellRendererProps,
	NestedFieldPaths,
} from '@uturn/ui-kit';
import { Badge } from '@uturn/ui-kit';
import dayjs from 'dayjs';
import type { RefObject } from 'react';
import { Link, useLocation } from 'react-router-dom';
import QuoteButton from '@uturn/portal/components/quote-button/quote-button';
import type { SearchShipment } from '@uturn/portal/modules/shipments/components/shipments-table/types';
import type { StatusTab } from '@uturn/portal/types/shipments';
import { formatPrice, formatWeight } from '@uturn/portal/utils';

export interface Column<T> {
	headerName?: string;
	groupId?: string;
	children?: {
		headerName?: string;
		colId: keyof T | string;
		hidden?: boolean;
	}[];
}

const matchLocationColId = (colId: string, property: string) =>
	!!colId.match(`location_[0-9]*.${property}`);

const TEXT_FILTER_DEFAULTS = {
	filter: 'agTextColumnFilter',
	floatingFilter: true,
	filterParams: {
		filterOptions: ['equals'],
		suppressAndOrCondition: true,
	},
};

const useColumnDefs = (
	columns: Column<SearchShipment>[],
	type: StatusTab,
	ref: RefObject<AgGridReact>,
) => {
	const location = useLocation();

	return columns.map((columnGroup) => ({
		headerName: columnGroup.headerName,
		marryChildren: true,
		groupId: columnGroup.groupId,
		children: columnGroup.children?.map(
			({ colId, headerName, hidden }): ColDef<SearchShipment> => ({
				/**
				 * // Default options should also edit the header menu to only include necessary items (e.g. auto-size but not pin)
				 *
				 * See https://www.ag-grid.com/javascript-data-grid/column-menu/
				 */
				field: colId as NestedFieldPaths<SearchShipment>,
				headerName,
				initialHide: !!hidden,
				minWidth: 125,
				sortable: false,
				...(colId === 'shipmentNumber' && {
					...TEXT_FILTER_DEFAULTS,
					minWidth: 175,
					maxWidth: 175,
					lockVisible: true,
					lockPosition: true,
					sortable: true,
					cellRenderer: (params: CustomCellRendererProps<SearchShipment>) =>
						params.value && (
							<span>
								<span className="text-muted-foreground">#</span>
								<Link
									className="text-primary"
									to={`/shipments/${params.value}`}
									state={{ parentLocation: location }}
								>
									{params.value}
								</Link>
							</span>
						),
				}),
				...(colId === 'invoiceReference' && {
					...TEXT_FILTER_DEFAULTS,
					sortable: true,
					cellRenderer: (params: CustomCellRendererProps<SearchShipment>) =>
						params.value && (
							<span>
								<span className="text-muted-foreground">#</span>
								{params.value}
							</span>
						),
				}),
				...((colId === 'targetPrice.quantity' ||
					colId === 'agreedPrice.quantity') && {
					valueFormatter: (params) =>
						params.value && params.value !== 0
							? formatPrice(
									params.value,
									params.data?.currency ?? CurrencyType.EUR,
								)
							: '',
				}),
				...(colId === 'containerType' && {
					cellRenderer: (params: CustomCellRendererProps<SearchShipment>) =>
						params.value && <Badge variant="secondary">{params.value}</Badge>,
				}),
				...(colId === 'weight.quantity' && {
					valueFormatter: (params) =>
						params.value && params.value !== 0
							? formatWeight(
									params.value,
									params.data?.weight.unit ?? 'kilogram',
								)
							: '',
				}),
				...(colId === 'quoteCount' && {
					lockVisible:
						type === 'assigned' || type === 'drafted' || type === 'archived',
					hide:
						type === 'assigned' || type === 'drafted' || type === 'archived',
					cellRenderer: (params: CustomCellRendererProps<SearchShipment>) =>
						params.value !== undefined &&
						params.value > 0 && (
							<QuoteButton
								onAssign={() => {
									/**
									 * We flash the cell first to indicate that the action was successfull
									 * and then we remove the quote from the list, hence the delay
									 */
									ref.current?.api.flashCells({ rowNodes: [params.node] });

									setTimeout(() => {
										ref.current?.api.applyServerSideTransaction({
											remove: [params.data],
										});
									}, 300);
								}}
								onDecline={() => {
									/**
									 * We flash the cell first to indicate that the action was successfull
									 * and then we remove the quote from the list, hence the delay
									 */
									ref.current?.api.flashCells({ rowNodes: [params.node] });
									ref.current?.api.applyServerSideTransaction({
										update: [
											{
												...params.data,
												quoteCount: params.data?.quoteCount
													? Number(params.data.quoteCount) - 1
													: 0,
											},
										],
									});
								}}
								size="xs"
								quoteCount={params.value}
								shipmentNumber={params.data?.shipmentNumber as number}
							/>
						),
				}),
				// Columns to enable text filter for
				...((matchLocationColId(colId, 'location.name') ||
					colId === 'creator.name') && {
					...TEXT_FILTER_DEFAULTS,
				}),
				// Date columns (with filter)
				...((colId === 'publishUntil' ||
					matchLocationColId(colId, 'dateFrom') ||
					matchLocationColId(colId, 'dateUntil') ||
					matchLocationColId(colId, 'eta') ||
					matchLocationColId(colId, 'arrival') ||
					matchLocationColId(colId, 'departure')) && {
					sortable: true,
					minWidth: 225,
					filter: 'agDateColumnFilter',
					floatingFilter: true,
					filterParams: {
						filterOptions: ['equals', 'before', 'after', 'inRange'],
						suppressAndOrCondition: true,
					},
					valueFormatter: (params) => {
						return (
							params.value &&
							dayjs(params.value).tz('GMT').format('DD/MM/YYYY HH:mm')
						);
					},
				}),
				...(colId === 'preferred_carriers' && {
					valueGetter: (params) =>
						params.data?.preferred_carriers?.map((carrier) => carrier.name),
				}),
			}),
		),
	}));
};

export default useColumnDefs;
