import { Invoices as InvoicesEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { useTranslation } from 'react-i18next';

export function OverviewEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<InvoicesEmptyState className="md:h-96" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t('pages.invoices.emptyState.title', 'Return the favor!')}
			</h2>
			<p>
				{t(
					'pages.invoices.emptyState.description',
					'Invoices will be send via email, but you can download them here as well.',
				)}
			</p>
		</EmptyState>
	);
}
