export function getMonthsFromLocal({
	localeName = 'en-US',
	monthFormat = 'long',
}: {
	localeName?: string;
	monthFormat?: 'long' | 'short';
}) {
	const applyFormat = new Intl.DateTimeFormat(localeName, {
		month: monthFormat,
	}).format;
	return [...Array(12).keys()].map((m) => applyFormat(new Date(2021, m)));
}
