import type {
	AddressDTO,
	PriceRequestDto,
	PriceResponseDto,
} from '@uturn/api/v1';
import { Card } from '@uturn/ui-kit';
import { motion } from 'framer-motion';
import { PriceIndicationResult } from './price-indication-result';
import { PriceRequestDetails } from './price-request-details';

export function PriceCalculatorResult({
	priceRequest,
	priceResponse,
	onRequestEdit,
	onRequestReset,
	locations,
}: {
	priceRequest: PriceRequestDto;
	locations: AddressDTO[];
	priceResponse: PriceResponseDto;
	onRequestEdit: () => void;
	onRequestReset: () => void;
}) {
	return (
		<>
			<motion.div
				initial={{ opacity: 0, translateY: 15 }}
				animate={{ opacity: 1, translateY: 0 }}
				exit={{
					opacity: 0,
					translateY: 15,
					transition: { delay: 0.3, ease: 'easeInOut' },
				}}
				transition={{ duration: 0.3 }}
			>
				<Card key="locked">
					<PriceRequestDetails
						locations={locations}
						details={priceRequest}
						editRequest={onRequestEdit}
						createNewRequest={onRequestReset}
					/>
				</Card>
			</motion.div>

			<motion.div
				initial={{ opacity: 0, translateY: 10 }}
				animate={{
					opacity: 1,
					translateY: 0,
					transition: {
						delay: 0.2,
						duration: 0.3,
						ease: 'easeInOut',
					},
				}}
				exit={{
					opacity: 0,
					translateY: 10,
					transition: { delay: 0, ease: 'easeInOut' },
				}}
			>
				<Card>
					<PriceIndicationResult result={priceResponse} />
				</Card>
			</motion.div>
		</>
	);
}
