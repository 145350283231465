import ContentLoader from 'react-content-loader';

export function QuoteSkeleton() {
	return (
		<ContentLoader width="100%" height="300">
			<rect x="0" y="0" rx="3" ry="3" width="40%" height="40" />

			<rect x="0" y="60" rx="3" ry="3" width="45%" height="20" />
			<rect x="0" y="100" rx="3" ry="3" width="15%" height="15" />
			<rect x="35%" y="100" rx="3" ry="3" width="30%" height="15" />

			<rect x="0" y="150" rx="3" ry="3" width="25%" height="20" />
			<rect x="0" y="190" rx="4" ry="4" width="100%" height="60" />
		</ContentLoader>
	);
}
