import { InfoRouteCardSkeleton } from '@uturn/portal/components/info-route-card';

export function ShipmentSkeletonLoader() {
	return (
		<>
			<InfoRouteCardSkeleton />
			<InfoRouteCardSkeleton />
			<InfoRouteCardSkeleton />
		</>
	);
}
