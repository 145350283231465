import { Dialog, DialogContent } from '@uturn/ui-kit';
import { ChangeRequest } from './change-request';
import type { ChangeRequestProps } from './change-request.types';

export function ChangeRequestModal({
	openModal,
	setOpenModal,
	changeRequestProps,
}: {
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
	changeRequestProps: ChangeRequestProps;
}) {
	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent className="md:w-[70vw] md:max-w-[70vw]">
				<ChangeRequest {...changeRequestProps} />
			</DialogContent>
		</Dialog>
	);
}
