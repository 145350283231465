import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import type { EventHistoryDTO } from '@uturn/api/v1';
import { Accordion, SlideOverContext } from '@uturn/ui';
import { Icon } from '@uturn/ui-kit';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { EventType } from './events-feed.types';
import { getIconProps } from './events-feed.utils';

export function EventsFeedType({
	eventType,
}: {
	eventType: string | undefined;
}) {
	const iconProps = getIconProps(
		EventType[eventType as keyof typeof EventType],
	);
	return (
		<div>
			<span
				className={`${iconProps.eventColor.backgroundColor} flex size-8 items-center justify-center rounded-full`}
			>
				<Icon
					icon={iconProps.name}
					className={`size-4 ${iconProps.eventColor.iconColor}`}
					aria-hidden="true"
				/>
			</span>
		</div>
	);
}

export function EventsFeedItem({
	shipmentNumber,
	createdOn,
	eventType,
	description,
	userName,
	initiatedBy,
}: EventHistoryDTO) {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const { handleSlideOver } = useContext(SlideOverContext);

	const createdOnDate = new Date(createdOn ?? '');
	const { t, i18n } = useTranslation();

	const time = `${createdOnDate.toLocaleTimeString(i18n.language, {
		minute: '2-digit',
		hour: '2-digit',
	})}`;

	return (
		<div className="w-full">
			<div className="flex justify-between gap-x-4">
				<div className="py-0.5 text-sm font-bold leading-6">
					<Link
						to={`/shipments/${shipmentNumber}`}
						onClick={() => handleSlideOver()}
					>
						{shipmentNumber}
					</Link>
				</div>
				<div className="text-secondary-600 flex-none py-0.5 text-sm leading-6">
					<span>{time}</span>
				</div>
			</div>
			{description ? (
				<>
					<Accordion
						title={
							<div className="flex w-full cursor-pointer items-start justify-between gap-x-4">
								<div className="text-sm leading-6">
									<span className="mr-1">{eventType}</span>
									<span>
										{t(
											'general.event_feed.author',
											'by {{ name }} via {{ source }}',
											{
												name: userName,
												source: initiatedBy,
											},
										)}
									</span>
								</div>
								<span
									className={twJoin('transform', isExpanded && 'rotate-180')}
								>
									<Icon icon={faChevronDown} />
								</span>
							</div>
						}
						expanded={isExpanded}
						setExpanded={setIsExpanded}
					>
						<div className="w-4/5 text-sm leading-6 text-muted-foreground">
							<p>{description}</p>
						</div>
					</Accordion>
				</>
			) : (
				<div className="w-4/5 text-sm leading-6">
					<span className="mr-1">{eventType}</span>
					<span>
						{t('general.event_feed.author', 'by {{ name }} via {{ source }}', {
							name: userName,
							source: initiatedBy,
						})}
					</span>
				</div>
			)}
		</div>
	);
}
