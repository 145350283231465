import type { FileTypeDTO } from '@uturn/api/v1';
import type { TFunction } from 'i18next';
import type { FileTypeOption } from '@uturn/portal/types/shipment';
import { allowedFileTypes } from '@uturn/portal/types/shipment';

export const getFileTypeOptions = (
	fileTypes: FileTypeDTO[],
	t: TFunction<'translation', undefined>,
): FileTypeOption[] => {
	return fileTypes
		.filter(
			(fileType) =>
				!!fileType.code &&
				!!fileType.name &&
				allowedFileTypes.includes(fileType.code),
		)
		.map((fileType) => {
			const { code, name } = fileType;
			const option: FileTypeOption = {
				label: t(`general.file-types.${code!}`, name!),
				value: code!,
				order: allowedFileTypes.indexOf(code!),
			};
			return option;
		})
		.sort((a, b) => a.order - b.order);
};
