/* eslint-disable jsx-a11y/no-autofocus */
import { CommandInput } from '@uturn/ui-kit';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstantSearch } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';

export function CustomSearchBox({
	handleSearchClose,
	query,
	refine,
}: {
	query: string;
	refine: (newQuery: string) => void;
	handleSearchClose: () => void;
}) {
	// TODO: Currently this is hardcoded for shipments, but it should be dynamic
	// Rather go for a solution where you "click" on the first element, instead of doing the action here
	const { results } = useInstantSearch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [inputValue, setInputValue] = useState(query);
	const inputRef = useRef<HTMLInputElement>(null);

	function setQuery(newQuery: string) {
		if (newQuery.length > 2) {
			refine(newQuery);
		}
	}

	return (
		<form
			className="relative"
			action=""
			role="search"
			noValidate
			onSubmit={(event) => {
				event.preventDefault();
				event.stopPropagation();

				if (inputRef.current) {
					inputRef.current.blur();
				}
			}}
			onReset={(event) => {
				event.preventDefault();
				event.stopPropagation();

				setQuery('');

				if (inputRef.current) {
					inputRef.current.focus();
				}
			}}
		>
			<CommandInput
				autoFocus
				ref={inputRef}
				placeholder={t('header.search.placeholder', 'Search shipments')!}
				value={inputValue}
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck={false}
				maxLength={512}
				onInputChange={(value) => {
					setQuery(value);
				}}
				onValueChange={setInputValue}
				debounceDelay={400}
				onKeyDown={(event) => {
					if (event.key === 'Enter' && results.hits.length > 0) {
						event.preventDefault();
						event.stopPropagation();
						navigate(`/search?q=${inputValue}`);
						handleSearchClose();
					}
				}}
			/>
		</form>
	);
}
