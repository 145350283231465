import { type Route, type RouteKpiRequestDto, RouteType } from '@uturn/api/v2';
import type { TFunction } from 'i18next';
import type { AvailableRoute } from '@uturn/portal/types/shipment';

/**
 * The qualify check is for shipments unmatched shipments
 * in the future. This message looks odds in matched
 * shipments from the past and the message is irrelevant.
 *
 * Contract Shipment (Preferred Carriers selected)
 *   => Multimodel must be unselected and disabled.
 */
const availableRoute = (
	route: AvailableRoute,
	isContractShipment: boolean,
	isShipmentMatched: boolean,
): AvailableRoute => {
	const result = { ...route };

	if (isShipmentMatched) {
		result.isValid = true;
		result.isSelected = true;
		result.isDisabled = true;
		result.validationMessages = [];
	}

	if (result.routeType === RouteType.MULTIMODAL && isContractShipment) {
		result.isSelected = false;
		result.isDisabled = true;
	}

	return result;
};

export const toAvailableRoutes = (
	t: TFunction<'translation', undefined>,
	routes: Route[],
	isContractShipment: boolean,
	isShipmentMatched: boolean,
) => {
	const newAvailableRoutes: AvailableRoute[] =
		routes
			.filter(
				(route: Route) =>
					route.routeType === RouteType.TRUCK || route.operatorId,
			)
			.map((route: Route) => {
				const isValid = !route.validationMessages?.length;
				const isSelected = isValid;
				const isDisabled = route.routeType === RouteType.TRUCK;
				const validationTitle = route.validationMessages?.length
					? t(
							'pages.create_shipment.possible_routes.headings.validation',
							'Qualify for {{routeType}} by changing:',
							{ routeType: route.routeType },
						)
					: undefined;

				return availableRoute(
					{
						...route,
						isValid,
						isSelected,
						isDisabled,
						validationTitle,
					},
					isContractShipment,
					isShipmentMatched,
				);
			}) ?? [];

	return newAvailableRoutes;
};

export const defaultRouteKpis: RouteKpiRequestDto[] = [
	{
		routeType: RouteType.TRUCK,
		isSelected: true,
	},
];

export const toRouteKpis = (
	t: TFunction<'translation', undefined>,
	routes: AvailableRoute[] | Route[],
	isContractShipment: boolean,
	isShipmentMatched: boolean,
	selected?: boolean[],
): RouteKpiRequestDto[] => {
	let availableRoutes: AvailableRoute[] = [];
	if ('isSelected' in routes) {
		availableRoutes = <AvailableRoute[]>routes;
	} else {
		availableRoutes = toAvailableRoutes(
			t,
			<Route[]>routes,
			isContractShipment,
			isShipmentMatched,
		);
	}
	const newRouteKpis = availableRoutes.map((route, index) => {
		const routeKpi: RouteKpiRequestDto = {
			operator: route.operatorId,
			routeType: route.routeType,
			isSelected: selected ? selected[index] : route.isSelected,
		};
		return routeKpi;
	});

	return newRouteKpis ?? [];
};
