import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import type { CustomCellEditorProps } from '@uturn/ui-kit';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	cn,
} from '@uturn/ui-kit';
import { type FC, useEffect, useRef, useState } from 'react';
import isMetaKey from '@uturn/portal/utils/is-meta-key';

export type Item = { value: string; label: string };

type Props = CustomCellEditorProps & { values: Item[] };

/**
 * Known issues
 *
 * - The popover is not positioned properly, especially on Container Type this is noticable since it touches the border of the screen/goes off screen
 */
const SelectEditor: FC<Props> = ({
	value: data,
	onValueChange,
	values,
	api,
	rowIndex,
	column,
	eventKey,
}) => {
	const searchRef = useRef<HTMLInputElement>(null);
	const [searchValue, setSearchValue] = useState(
		eventKey && !isMetaKey(eventKey) ? eventKey : '',
	);
	const [value, setValue] = useState(data);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(true);
	}, []);

	const onSelect = (item: Item) => {
		setValue(item);
		onValueChange(item);
		setOpen(false);

		/**
		 * After selecting an item, we want to stop editing the cell, but doing so removes the focus from the cell.
		 * So after calling .stopEditing() we need to set the focus back to the cell
		 */
		api.stopEditing();
		api.setFocusedCell(rowIndex, column.getColId());
	};

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => {
				setOpen(isOpen);

				if (isOpen) {
					return;
				}

				/**
				 * Whenever the popover is closed, we want to stop editing the cell, but doing so removes the focus from the cell.
				 * So after calling .stopEditing() we need to set the focus back to the cell
				 */
				api.stopEditing();
				api.setFocusedCell(rowIndex, column.getColId());
			}}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full"
					name="selectButton"
				>
					{value?.label}
				</Button>
			</PopoverTrigger>
			{/* className "ag-custom-component-popup" is required for the popover to work in combination with ag-grid option stopEditingWhenCellsLoseFocus=true */}
			<PopoverContent className="ag-custom-component-popup max-h-[300px] w-[300px] overflow-y-scroll p-0">
				<Command
					filter={(valueToFilter, search) => {
						if (valueToFilter.includes(search.toLowerCase())) {
							return 1;
						}

						return 0;
					}}
				>
					<CommandInput
						ref={searchRef}
						value={searchValue}
						onValueChange={setSearchValue}
						placeholder="Search..."
						onSelect={(params) => {
							/**
							 * params.nativeEvent has a global "Event" type, which can be MouseEvent or KeyboardEvent
							 * that's why we check if key exists on the event to check for the meta key after
							 *
							 * The reason we do the following, is because we can't overwrite the select behavior
							 * and don't want to actually select the value in the input unless the user selects using a meta key (e.g. ctrl + a, ctrl + <, etc)
							 */
							if (
								'key' in params.nativeEvent &&
								isMetaKey(params.nativeEvent.key as string)
							) {
								return;
							}

							searchRef?.current?.setSelectionRange(
								searchValue.length,
								searchValue.length,
							);
						}}
					/>
					<CommandEmpty>No results</CommandEmpty>
					<CommandGroup>
						{values.map((item) => (
							<CommandItem
								key={item.value}
								value={item.label}
								onSelect={() => onSelect(item)}
							>
								<Icon
									icon={faCheck}
									className={cn(
										'mr-2 h-4 w-4',
										value === item ? 'opacity-100' : 'opacity-0',
									)}
								/>
								{item.label}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default SelectEditor;
