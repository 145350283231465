import type { CustomCellEditorProps } from '@uturn/ui-kit';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { formatWeight } from '@uturn/portal/utils';
import isMetaKey from '@uturn/portal/utils/is-meta-key';

type Props = CustomCellEditorProps;

/**
 * Known issues
 * - AG Grid is not made with a 8xp grid, Tailwind uses a 8px grid, so we need to explicitly set the values (e.g. 15px and 45px).
 *   This is a temporary solution, we should preferably update the AG Grid theme to use a 8px grid.
 */
const WeightCellEditor: FC<Props> = ({
	value: data,
	onValueChange,
	eventKey,
}) => {
	const [value, setValue] = useState(
		eventKey && !isMetaKey(eventKey) ? eventKey : (data.quantity ?? ''),
	);
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (eventKey && !isMetaKey(eventKey)) {
			onValueChange({
				unit: data.unit,
				quantity: eventKey,
			});
		}
	}, [data.unit, eventKey, onValueChange]);

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
		}
	}, []);

	return (
		<div className="relative">
			<input
				inputMode="numeric"
				className="hide-arrow size-full border-transparent pl-[15px] pr-[45px] text-sm focus:border-transparent focus:ring-0"
				ref={ref}
				value={value}
				onChange={(e) => {
					if (!/^\d*$/.test(e.target.value)) {
						return;
					}

					setValue(e.target.value);
					onValueChange({
						unit: data.unit,
						quantity: e.target.value ? Number(e.target.value) : '',
					});
				}}
			/>
			<span className="absolute right-0 bg-muted px-[15px]">
				{formatWeight(0, data.unit).split(/\s+/).pop()}
			</span>
		</div>
	);
};

export default WeightCellEditor;
