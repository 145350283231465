import {
	ConditionType,
	type CurrencyType,
	type ShipmentRequirementDTO,
	WeightUnit,
} from '@uturn/api/v1';
import {
	type ShipmentDto,
	ShipmentDtoShipmentStatus as ShipmentStatus,
} from '@uturn/api/v2';
import {
	defaultTransportTypeLocationActions,
	fromZodLocations,
	toZodLocations,
} from './locations';
import { guessTransportType } from './transport-type';
import type {
	FormValues,
	ZodLocation,
} from '@uturn/portal/modules/shipments/schema';
import type {
	OrganizationDefaultValues,
	TransportTypeCode,
} from '@uturn/portal/types/shipment';
import { ContainerStatus, TypeOfShipment } from '@uturn/portal/types/shipment';
import { removeEmptyDataFields } from '@uturn/portal/utils/remove-empty-data-fields';

export const grossWeightUndefined = 0; // TODO: => null?
export const priceUndefined = 0; // TODO: => null?

/**
 * For some reason setting the object directly does change the field state.
 */
export const shouldUpdate = {
	shouldTouch: true,
	shouldDirty: true,
};

/**
 * For some reason setting the object directly does not trigger the validation
 * so we need to set the values individually and then set shouldValidate to true.
 *
 * Might be a worth to revisit this in the future.
 *
 * ```ts
 * form.setValue(
 *   `locations.${index}`,
 *   {
 *      action: { location: { id: locationId } },
 *      countryCode,
 *   },
 *   { shouldValidate: true }
 * );
 * ```
 */
export const shouldRevalidate = {
	shouldTouch: true,
	shouldDirty: true,
	shouldValidate: true, // Required to remove any validation error
};

export type CopyOptions = {
	shipmentRequirements: ShipmentRequirementDTO[];
	shippingRequirementExceptions: string[];
};

export const fromApi = (data: ShipmentDto): FormValues => {
	const {
		shipmentUnitDescription, // parse for other fields or itself
		requirements,
		locationActions,
		preferredCarriers,
		routes,
		publishUntil,
		shipmentStatus,
		tsoStatus, //               exclude fields:
		carrier,
		pendingChangeRequest,
		quotes,
		id,
		number,
		shipmentsInBatch,
		batchId,
		group,
		...rest
	} = data;

	return {
		...rest,
		concept: shipmentStatus === ShipmentStatus.CONCEPT,
		containerType: shipmentUnitDescription ?? '',
		transportType: guessTransportType(locationActions),
		typeOfShipment: TypeOfShipment.SINGLE, // group?.name ? TypeOfShipment.GROUP : TypeOfShipment.SINGLE,
		numberOfCopiesToBeCreated: 1, // shipmentsInBatch ?? 1,
		newGroup: undefined, // group?.name ? { name: group.name } : undefined,
		containerStatus: rest.grossWeight?.quantity
			? ContainerStatus.FULL
			: ContainerStatus.EMPTY,
		invoiceRequired: !!rest.shipperReference,
		shippingLineId: rest.shippingLine?.id,
		requirementIds: requirements.map((item) => item.id!) ?? [],
		locations: toZodLocations(locationActions),
		preferredCarrierIds: preferredCarriers?.map((item) => item.orgId!) ?? [],
		routes: routes.routes,
		publishUntilDate: publishUntil ?? '',
	} as FormValues;
};

export const fromForm = (data: Partial<FormValues>, id?: number) => {
	// CreateShipment
	if (!id) {
		const {
			transportType,
			typeOfShipment,
			containerType,
			containerStatus,
			invoiceRequired,
			shippingLine,
			preferredCarrierIds,
			locations,
			...restSubmitData
		} = data;

		const toSubmitData = {
			...restSubmitData,
			preferredCarriersIds: preferredCarrierIds ?? [], // TODO: Remove this when CreateShipment type gets fixed too.
		};

		// Already z.schema transformed
		if (!locations || toSubmitData?.locationActions?.length) {
			return removeEmptyDataFields(toSubmitData);
		}

		const { locationActions } = fromZodLocations(locations as ZodLocation[]);

		return removeEmptyDataFields({
			...toSubmitData,
			locationActions,
		});
	}

	// UpdateShipment
	const {
		concept,
		transportType,
		typeOfShipment,
		containerType,
		containerStatus,
		invoiceRequired,
		shippingLine,
		locations,
		newGroup,
		numberOfCopiesToBeCreated,
		routes,
		...toSubmitData
	} = data;

	// Already z.schema transformed
	if (!locations || toSubmitData?.locationActions?.length) {
		return removeEmptyDataFields(toSubmitData);
	}

	const { locationActions } = fromZodLocations(locations);

	return removeEmptyDataFields({
		...toSubmitData,
		locationActions,
	});
};

export const toStore = (
	transportType: TransportTypeCode,
	typeOfShipment: TypeOfShipment,
	initData: Partial<FormValues>,
	orgDefaultValues: OrganizationDefaultValues,
	currencyCode: CurrencyType,
): Partial<FormValues> => {
	const defaultContainerStatus =
		initData?.containerStatus ?? ContainerStatus.FULL;
	return {
		...initData,
		concept: true,
		numberOfCopiesToBeCreated: initData.numberOfCopiesToBeCreated ?? 1,
		equipment: {
			containerNumber: initData.equipment?.containerNumber ?? '',
			isoType: initData.equipment?.isoType ?? '',
			shipmentUnitId: initData.equipment?.shipmentUnitId ?? 0,
		},
		grossWeight: {
			quantity:
				initData.grossWeight?.quantity ||
				(defaultContainerStatus === ContainerStatus.EMPTY
					? 0
					: grossWeightUndefined),
			unit: initData.grossWeight?.unit ?? WeightUnit.kilogram,
		},
		requirementIds: initData.requirementIds ?? [],
		locations: defaultTransportTypeLocationActions[
			transportType
		] as Partial<ZodLocation>,
		preferredCarrierIds: initData.preferredCarrierIds ?? [],
		price: {
			quantity: initData.price?.quantity ?? priceUndefined,
			currencyCode: initData.price?.currencyCode ?? currencyCode,
		},
		fixedPrice: initData.fixedPrice ?? true,
		publishUntilDate: initData.publishUntilDate ?? undefined,
		shipperReference: initData.shipperReference ?? '',
		conditions: orgDefaultValues?.conditions ?? ConditionType.AVC,
		transportType,
		typeOfShipment,
		containerStatus: defaultContainerStatus,
		invoiceRequired: orgDefaultValues?.invoiceRequired ?? false,
	} as Partial<FormValues>;
};

export const copyLocations = (data: Partial<FormValues>) => {
	const now = new Date();
	return (
		data?.locations?.map((item) => {
			const { action } = item;
			const { id, arrival, departure, eta, dateFrom, dateUntil, ...rest } =
				action ?? {};
			return {
				...item,
				action: {
					...rest,
					id: crypto.randomUUID(),
					dateFrom: dateFrom && new Date(dateFrom) < now ? '' : dateFrom,
					dateUntil: dateUntil && new Date(dateUntil) < now ? '' : dateUntil,
				},
			};
		}) ?? []
	);
};

export const copyRequirements = (
	data: Partial<FormValues>,
	options: CopyOptions,
) => {
	return (
		data?.requirementIds
			?.filter((requirementId: number | string) => {
				const items = options.shipmentRequirements ?? [];
				if (items.length === 0) {
					return false;
				}
				const itemId = Number(requirementId);
				const itemCode = items.find(
					(item) => item.id && itemId === item.id,
				)?.code;
				if (!itemCode) {
					return false;
				}
				return !options.shippingRequirementExceptions.includes(itemCode);
			})
			.map((requirementId: number | string) => Number(requirementId)) ?? []
	);
};
