import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import type { OrganizationUserDto } from '@uturn/api/v1';
import { useFetchOrganizationById } from '@uturn/api/v1';

import {
	AccordionContent,
	AccordionTrigger,
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Icon,
	MultiSelectBadge,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { FilterContext } from './context';
import { MetaDataContext } from '@uturn/portal/context';

export function UserFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const { t } = useTranslation();
	const { metadata } = useContext(MetaDataContext);

	const {
		data: {
			data: { users },
		},
	} = useFetchOrganizationById(metadata?.user?.organisation!.id ?? -1, {
		query: {
			enabled: metadata?.user?.organisation!.id !== null,
			refetchOnWindowFocus: false,
		},
	});

	const types =
		typeof filters['filter.userIds'] === 'string'
			? [filters['filter.userIds']]
			: (filters['filter.userIds'] ?? []);

	const handleOnChange = (userID: string, select: boolean) => {
		if (select) {
			setFilters({ 'filter.userIds': [...types, Number(userID)] });
		} else {
			setFilters({
				'filter.userIds': types.filter((s: any) => s !== userID),
			});
		}
	};

	const SelectedUsers = ({ userIds }: { userIds: string[] }) => {
		if (!userIds) return <></>;

		return userIds.map((userId: string) => {
			if (!userId) return <></>;

			const selectedUser = users!.find(
				(organizationUser: OrganizationUserDto) =>
					organizationUser.id === Number(userId),
			);

			return (
				<MultiSelectBadge
					key={userId}
					label={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
					removeIcon={<Icon icon={faXmark} />}
					removeValue={() => handleOnChange(userId, false)}
					disableRemoveValue={false}
				/>
			);
		});
	};

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.users.title', 'Users')}
			</AccordionTrigger>
			<AccordionContent>
				<div className="grid gap-1">
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant="outline"
								role="combobox"
								className={twJoin(
									'w-full justify-between items-center bg-transparent',
									types && types.length < 1 && 'text-muted-foreground',
								)}
							>
								<div className="flex">
									{types && types?.length < 1 ? (
										'Select users'
									) : (
										<SelectedUsers userIds={types} />
									)}
								</div>
							</Button>
						</PopoverTrigger>
						<PopoverContent align="start" className="w-full p-0">
							<Command className="max-h-[300px]">
								<CommandInput
									placeholder={t(
										'pages.shipments.filters.users.placeholder',
										'Search users...',
									).toString()}
								/>
								<CommandEmpty>
									{t(
										'pages.shipments.filters.users.no-results',
										'No users found.',
									)}
								</CommandEmpty>
								<CommandGroup className="overflow-y-scroll">
									{users?.map((user: OrganizationUserDto) => {
										const isSelected = types?.indexOf(user.id!.toString()) >= 0;

										return (
											<CommandItem
												value={`${user.firstName} ${user.lastName}`}
												key={user.id?.toString()}
												onSelect={() => {
													handleOnChange(user.id!.toString(), !isSelected);
												}}
											>
												<Icon
													icon={faCheck}
													className={twJoin(
														'mr-2 h-4 w-4',
														isSelected ? 'opacity-100' : 'opacity-0',
													)}
												/>
												{user.firstName} {user.lastName}
											</CommandItem>
										);
									})}
								</CommandGroup>
							</Command>
						</PopoverContent>
					</Popover>
				</div>
			</AccordionContent>
		</>
	);
}
