/* eslint-disable @typescript-eslint/no-use-before-define */
import { Card, CardContent, CardHeader, Skeleton } from '@uturn/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin, twMerge } from 'tailwind-merge';

export type InfoRouteCardRouteProps = {
	name: string;
	description?: React.ReactNode;
	placement: 'end' | 'middle' | 'start';
};

export function InfoRouteCardSkeleton() {
	return (
		<Card className="h-min cursor-pointer transition-shadow duration-300 @container/shipment hover:shadow-md dark:hover:bg-muted/20">
			<InfoRouteCardContent
				className="pt-6"
				routes={
					<div className="grid gap-3">
						<Skeleton className="h-8" />
						<Skeleton className="h-8" />
						<Skeleton className="h-8" />
					</div>
				}
			>
				<Skeleton className="h-6 w-2/3" />
				<Skeleton className="h-6 w-1/2" />
				<Skeleton className="h-6 w-1/2" />
			</InfoRouteCardContent>
		</Card>
	);
}

export function InfoRouteCard({ children }: React.PropsWithChildren<unknown>) {
	return (
		<Card className="relative h-min cursor-pointer transition-shadow duration-300 @container/shipment hover:shadow-md dark:hover:bg-muted/20">
			{children}
		</Card>
	);
}

export function InfoRouteCardHeader({
	children,
	compact,
}: React.PropsWithChildren<{ compact?: boolean }>) {
	return (
		<CardHeader className={twMerge('pb-4', compact && 'pt-3.5 px-4 pb-3')}>
			<div className="flex items-center justify-between">{children}</div>
		</CardHeader>
	);
}

function Route({
	name,
	description,
	placement,
	compact,
}: InfoRouteCardRouteProps & { compact?: boolean }) {
	const showTopLine = placement !== 'start';
	const showBottomLine = placement !== 'end';

	return (
		<div
			className={twJoin(
				'items-center grid',
				compact
					? 'grid-cols-[12px_minmax(0,_1fr)] gap-1'
					: 'grid-cols-[16px_minmax(0,_1fr)] gap-2',
			)}
		>
			<div
				className={twJoin(
					'grid  h-full justify-items-center',
					compact
						? 'grid-rows-[minmax(0,_1fr)_12px_minmax(0,_1fr)]'
						: 'grid-rows-[minmax(0,_1fr)_16px_minmax(0,_1fr)]',
				)}
			>
				{showTopLine ? <div className="w-1 bg-primary" /> : <span />}
				<div className="size-full rounded-xl bg-primary" />
				{showBottomLine ? <div className="w-1 bg-primary" /> : <span />}
			</div>

			<div
				className={twMerge(
					'bg-secondary px-3 py-2 rounded-lg m-1',
					compact && 'truncate px-2 py-1 grid grid-flow-col m-0.5',
				)}
			>
				<div
					className={twMerge(
						'font-bold text-accent-foreground',
						compact && 'truncate',
					)}
				>
					{name}
				</div>
				{description}
			</div>
		</div>
	);
}

export type InfoRouteCardLabelProps = {
	title: string;
	clickable?: boolean;
};

export function InfoRouteCardLabel({
	title,
	clickable,
	children,
}: React.PropsWithChildren<InfoRouteCardLabelProps>) {
	return (
		<div className="flex flex-col gap-1">
			<h5 className="text-sm font-medium leading-none">{title}</h5>
			<span
				className={`text-sm text-muted-foreground ${
					clickable ? 'z-10 w-max' : ''
				}`}
			>
				{children}
			</span>
		</div>
	);
}

export function InfoRouteCardRoutes({
	routes,
	compact,
}: {
	routes: Omit<InfoRouteCardRouteProps, 'placement'>[];
	compact?: boolean;
}) {
	const { t } = useTranslation();

	return (
		<InfoRouteCardLabel title={t('general.route', 'Route')}>
			<div className="grid">
				{routes.map((route, index) => {
					let placement: 'end' | 'middle' | 'start' = 'middle';

					if (index === 0) {
						placement = 'start';
					} else if (index === routes.length - 1) {
						placement = 'end';
					}

					return (
						<Route
							compact={compact}
							key={index}
							{...route}
							placement={placement}
						/>
					);
				})}
			</div>
		</InfoRouteCardLabel>
	);
}

export function InfoRouteCardContent({
	children,
	className,
	routes,
	compact,
}: React.PropsWithChildren<{
	routes?: React.ReactNode;
	className?: string;
	compact?: boolean;
}>) {
	return (
		<CardContent className={twJoin(className, compact && 'pb-3.5 px-4')}>
			<div className="min-content grid grid-cols-1 gap-4 @2xl/shipment:grid-cols-2">
				<div
					className={
						compact
							? 'flex min-w-min flex-wrap content-start gap-x-6 gap-y-3'
							: 'grid content-start gap-2'
					}
				>
					{children}
				</div>

				{routes}
			</div>
		</CardContent>
	);
}
