import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { type QuoteDto, useGetShipment } from '@uturn/api/v2';
import { Avatar, Icon } from '@uturn/ui';
import {
	Badge,
	Button,
	Card,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Separator,
	Stars,
	Textarea,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@uturn/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@uturn/portal/utils';

const useShipmentsCompletedFormatter = () => {
	const { t } = useTranslation();

	const format = (shipmentsCompleted: number) => {
		if (shipmentsCompleted >= 0 && shipmentsCompleted <= 50) {
			return '0-50';
		}

		if (shipmentsCompleted >= 51 && shipmentsCompleted <= 200) {
			return '51-200';
		}

		if (shipmentsCompleted >= 201 && shipmentsCompleted <= 1000) {
			return '201-1000';
		}

		if (shipmentsCompleted >= 1001) {
			return '1000+';
		}

		return t('general.na', 'N/A');
	};

	return {
		format,
	};
};

export function SingleQuote({
	onAssignQuote,
	onDeclineQuote,
	quote,
	shipmentNumber,
	defaultOpen = false,
}: {
	onAssignQuote: (quoteId: number) => void;
	onDeclineQuote: (quoteId: number, declineReason?: string) => void;
	quote: QuoteDto;
	shipmentNumber: number;
	defaultOpen?: boolean;
}) {
	const { data } = useGetShipment(shipmentNumber);
	const { t } = useTranslation();
	const [open, setOpen] = useState(defaultOpen);
	const [isDeclinePending, setIsDeclinePending] = useState(false);
	const [declineReason, setDeclineReason] = useState('');
	const { format } = useShipmentsCompletedFormatter();

	return (
		<AnimatePresence mode="wait">
			<Card className="p-4">
				<div className="flex items-center justify-between gap-4">
					<div className="min-w-0 flex-1">
						<div className="flex gap-2">
							<div className="relative">
								<Avatar round size="3rem" name={quote.carrier.name} />
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<div className="absolute bottom-0 right-[-4px] flex size-4 items-center justify-center rounded-full border border-white bg-primary">
												<Icon className="text-white" size="xs" icon={faCheck} />
											</div>
										</TooltipTrigger>
										<TooltipContent side="bottom">
											This carrier is verified
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
							<div className="min-w-0">
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<h2 className="truncate">{quote.carrier.name}</h2>
										</TooltipTrigger>
										<TooltipContent>{quote.carrier.name}</TooltipContent>
									</Tooltip>
								</TooltipProvider>
								<div className="flex items-center gap-1">
									<Stars
										size="sm"
										rating={quote.carrier.rating.averageRating}
									/>
									<span className="text-sm">
										({quote.carrier.rating.totalRatings})
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="min-w-0">
						<div className="flex">
							<Button
								size="xs"
								className="rounded-e-none"
								onClick={() => onAssignQuote(quote.id as number)}
							>
								{t('shipment.quote.assign-for-price', 'Assign for {{price}}', {
									price: formatPrice(
										quote.price.quantity,
										quote.price.currencyCode,
									),
								})}
							</Button>
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button size="xs" className="rounded-s-none border-l">
										<Icon icon={faEllipsisVertical} />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end" className="text-sm">
									<DropdownMenuItem
										className="text-red-600"
										onClick={() => setIsDeclinePending(true)}
									>
										{t('shipment.quote.decline-quote', 'Decline quote')}
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
						</div>
					</div>
				</div>
				<AnimatePresence>
					{isDeclinePending && (
						<motion.div
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: 'auto', opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
						>
							<div className="pt-4">
								<Textarea
									placeholder={t(
										'shipment.quote.decline-reason.placeholder',
										'Reason for declining quote (optional)',
									).toString()}
									value={declineReason}
									onChange={(e) => setDeclineReason(e.target.value)}
								/>
								<div className="flex justify-end pt-4">
									<Button
										variant="link"
										onClick={() => setIsDeclinePending(false)}
									>
										{t('shipment.quote.decline-reason.cancel', 'Cancel')}
									</Button>
									<Button
										variant="destructive"
										onClick={() =>
											onDeclineQuote(quote.id as number, declineReason)
										}
									>
										{t('shipment.quote.decline-reason.decline', 'Decline')}
									</Button>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				<Separator className="my-4" />
				<div>
					{quote.condition && (
						<div className="mb-2">
							<h3 className="text-sm text-muted-foreground">
								{t('shipment.quote.quote-condition.label', 'Quote condition')}
							</h3>
							<p className="text-md">{quote.condition}</p>
						</div>
					)}
					<AnimatePresence>
						{open && (
							<motion.div
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: 'auto', opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
							>
								<div className="space-y-2 pb-2">
									<div className="flex">
										<div className="flex-1">
											<h3 className="mb-1 text-sm text-muted-foreground">
												{t(
													'shipment.quote.carrier.active-since.label',
													'Active since',
												)}
											</h3>
											<p className="text-md">{`${new Date(
												quote.carrier.activeSince,
											).getMonth()}/${new Date(
												quote.carrier.activeSince,
											).getFullYear()}`}</p>
										</div>
										<div className="flex-1">
											<h3 className="mb-1 text-sm text-muted-foreground">
												{t(
													'shipment.quote.carrier.shipments.label',
													'Shipments',
												)}
											</h3>
											{format(quote.carrier.shipmentsCompleted)}
										</div>
										<div className="flex-1">
											<h3 className="mb-1 text-sm text-muted-foreground">
												{t(
													'shipment.quote.carrier.capacity.label',
													'Truck Capacity',
												)}
											</h3>
											<p className="text-md">
												{quote.carrier.trucksOwned !== 0
													? quote.carrier.trucksOwned
													: t('general.na', 'N/A')}
											</p>
										</div>
									</div>
									{data?.data.requirements &&
										data.data.requirements.length > 0 && (
											<div>
												<h3 className="mb-1 text-sm text-muted-foreground">
													{t(
														'shipment.quote.carrier.capabilities.label',
														'Capabilities',
													)}
												</h3>
												<div className="flex flex-wrap gap-2">
													{data?.data.requirements.map((requirement) => (
														<Badge
															key={requirement.id}
															className="bg-green-500 hover:bg-green-600"
														>
															<Icon icon={faCheck} className="mr-1" />
															{requirement.description}
														</Badge>
													))}
												</div>
											</div>
										)}
								</div>
							</motion.div>
						)}
					</AnimatePresence>
					<Button
						variant="link"
						className="reveal-carrier-information h-auto p-0"
						onClick={() => setOpen(!open)}
					>
						{!open
							? t(
									'shipment.quote.carrier.more-carrier-information.label',
									'More carrier information',
								)
							: t(
									'shipment.quote.carrier.less-carrier-information.label',
									'Less carrier information',
								)}
					</Button>
				</div>
			</Card>
		</AnimatePresence>
	);
}
