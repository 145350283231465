import {
	DevTools,
	FormatSimple,
	I18nextPlugin,
	Tolgee,
	withTolgee,
} from '@tolgee/i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './translation/de.json';
import deZod from './translation/de.json';
import deForm from './translation/de.json';
import en from './translation/en.json';
import enZod from './translation/en.json';
import enForm from './translation/en.json';

import es from './translation/es.json';
import esZod from './translation/es.json';
import esForm from './translation/es.json';
import fr from './translation/fr.json';
import frZod from './translation/fr.json';
import frForm from './translation/fr.json';

import nl from './translation/nl.json';
import nlZod from './translation/nl.json';
import nlForm from './translation/nl.json';

export const availableLanguages = [
	{ code: 'en', country: 'gb' },
	{ code: 'de', country: 'de' },
	{ code: 'nl', country: 'nl' },
	{ code: 'fr', country: 'fr' },
	{ code: 'es', country: 'es' },
];

const enTranslations = {
	'en:translation': en,
	'en:zod': enZod,
	'en:formValidation': enForm,
};
const deTranslations = {
	'de:translation': de,
	'de:zod': deZod,
	'de:formValidation': deForm,
};
const frTranslations = {
	'fr:translation': fr,
	'fr:zod': frZod,
	'fr:formValidation': frForm,
};
const nlTranslations = {
	'nl:translation': nl,
	'nl:zod': nlZod,
	'nl:formValidation': nlForm,
};
const esTranslations = {
	'es:translation': es,
	'es:zod': esZod,
	'es:formValidation': esForm,
};

const tolgee = Tolgee()
	.use(DevTools())
	.use(I18nextPlugin())
	.use(FormatSimple())
	.init({
		apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
		apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
		defaultLanguage: 'en',
		defaultNs: 'translation',
		availableLanguages: availableLanguages.map(({ code }) => code),
		staticData: {
			...enTranslations,
			...deTranslations,
			...frTranslations,
			...nlTranslations,
			...esTranslations,
		},
	});

withTolgee(i18n, tolgee)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		ns: ['translation', 'zod', 'formValidation'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		detection: {
			convertDetectedLanguage: (lng) => lng.split('-')[0],
		},
	});

export default i18n;
export const locales = i18n;
