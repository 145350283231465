import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteCarrier } from '@uturn/api/v1';
import type { InviteCarrierRequest } from '@uturn/api/v1';
import { Card } from '@uturn/ui';
import {
	Button,
	CardDescription,
	CardHeader,
	CardTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Textarea,
	sonner,
} from '@uturn/ui-kit';
import { AllowedTo } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Permission } from '@uturn/portal/abac';
import { Page } from '@uturn/portal/components/page';

// TODO: Figure out how to add a type to this
const formSchema = z.object({
	contactOrganisationName: z.string().min(1),
	contactName: z.string().min(1),
	contactEmail: z.string().email(),
	inviteMessage: z.string(),
});

export function CarriersInvite() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { mutate } = useInviteCarrier({
		mutation: {
			onSuccess: () => {
				navigate('/carriers');
			},
			onError: () => {
				sonner.error(
					t(
						'pages.add-carrier.form.submit.error.description',
						'Was not able to add new carrier.',
					),
				);
			},
		},
	});

	const form = useForm<InviteCarrierRequest>({
		resolver: zodResolver(formSchema),
	});

	const onValidSendRequest = async (data: InviteCarrierRequest) => {
		mutate({ data });
	};

	return (
		<>
			<Helmet title={t('pages.add-carrier.title', 'Add Carrier')!} />
			<Page
				backButton={{
					label: t('general.back', 'Back'),
					href: '/carriers',
				}}
				title={t('pages.add-carrier.title', 'Add Carrier')!}
				className={'m-auto md:max-w-[550px]'}
			>
				<Card>
					<AllowedTo
						perform={Permission.INVITE_CARRIER}
						no={() => (
							<CardHeader>
								<CardTitle>
									{t(
										'pages.carriers.actions-denied.add.title',
										'Waiting for approval',
									)}
								</CardTitle>
								<CardDescription>
									{t(
										'pages.carriers.actions-denied.add.description',
										'Cannot invite contract carrier while your organisation is pending approval by UTURN.',
									)}
								</CardDescription>
							</CardHeader>
						)}
					>
						<Form {...form}>
							<form
								onSubmit={form.handleSubmit(onValidSendRequest)}
								className="space-y-8"
							>
								<FormField
									control={form.control}
									name="contactOrganisationName"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{
													t(
														'pages.add-carrier.form.organisation.label',
														'Organisation name',
													)!
												}
												*
											</FormLabel>
											<FormControl>
												<Input
													placeholder={
														t(
															'pages.add-carrier.form.organisation.placeholder',
															'Acme corp',
														)!
													}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="contactName"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{
													t(
														'pages.add-carrier.form.name.label',
														'Contact name',
													)!
												}
												*
											</FormLabel>
											<FormControl>
												<Input
													placeholder={
														t(
															'pages.add-carrier.form.name.placeholder',
															'John',
														)!
													}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="contactEmail"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{
													t(
														'pages.add-carrier.form.email.label',
														'Contact email',
													)!
												}
												*
											</FormLabel>
											<FormControl>
												<Input
													placeholder={
														t(
															'pages.add-carrier.form.email.placeholder',
															'john_doe@example.com',
														)!
													}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="inviteMessage"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('pages.add-carrier.form.message.label', 'Message')!}
											</FormLabel>
											<FormControl>
												<Textarea
													placeholder={t(
														'pages.add-carrier.form.message.placeholder',
														'I would like to invite you to join as a carrier.',
													).toString()}
													className="resize-none"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<Button type="submit">
									{t('pages.add-carrier.form.button', 'Add carrier')}
								</Button>
							</form>
						</Form>
					</AllowedTo>
				</Card>
			</Page>
		</>
	);
}
