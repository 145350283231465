import { GetShipmentsFilterStatusesItem as ShipmentStatus } from '@uturn/api/v2';
import type { TFunction } from 'i18next';

/** see CancelShipmentsParams or DeleteGroupByIdParams */
export type DefaultFilters = {
	/**
	 * shipment status
	 */
	'filter.statuses'?: ShipmentStatus[];
	/**
	 * The ids of which groups to include. Ungrouped shipments are always included.
	 * If empty only ungrouped shipments are returned.
	 * If null no filter is applied.
	 *
	 *
	 * @todo // TODO: Empty meaning [] and null meaning undefined in JS, right?
	 * Why does the GroupFilers component uses '' to exclude Groups from shipment's
	 * list then? and instead, would an undefined value achieve this the desired
	 * behaviour?
	 */
	'filter.groups'?: number[];
};

export type StatusTab =
	| 'published'
	| 'assigned'
	| 'drafted'
	| 'archived'
	| 'groups';

export type StatusOption = {
	label: string;
	value: ShipmentStatus;
	navTab: StatusTab;
	count?: number;
};

export const statusFilters = (
	t: TFunction<'translation', undefined>,
): StatusOption[] => [
	{
		label: t('pages.shipments.filters.status.published', 'Published'),
		value: ShipmentStatus.PUBLISHED,
		navTab: 'published',
	},
	{
		label: t('pages.shipments.filters.status.quoted', 'Quoted'),
		value: ShipmentStatus.QUOTED,
		navTab: 'published',
	},
	{
		label: t(
			'pages.shipments.filters.status.carrierAssigned',
			'Carrier assigned',
		),
		value: ShipmentStatus.CARRIER_ASSIGNED,
		navTab: 'assigned',
	},
	{
		label: t('pages.shipments.filters.status.costDeclared', 'Cost declared'),
		value: ShipmentStatus.COST_DECLARED,
		navTab: 'assigned',
	},
	{
		label: t('pages.shipments.filters.status.concept', 'Concept'),
		value: ShipmentStatus.CONCEPT,
		navTab: 'drafted',
	},
	{
		label: t('pages.shipments.filters.status.cancelled', 'Cancelled'),
		value: ShipmentStatus.CANCELLED,
		navTab: 'archived',
	},
	{
		label: t('pages.shipments.filters.status.completed', 'Completed'),
		value: ShipmentStatus.COMPLETED,
		navTab: 'archived',
	},
	{
		label: t('pages.shipments.filters.status.executed', 'Executed'),
		value: ShipmentStatus.EXECUTED,
		navTab: 'archived',
	},
];

export const defaultStatuses = (
	t: TFunction<'translation', undefined>,
	navTab: StatusTab,
): ShipmentStatus[] => {
	return statusFilters(t)
		.filter((item) => item.navTab === navTab)
		.map((item) => item.value);
};

export const defaultStatusFilters = (
	t: TFunction<'translation', undefined>,
	navTab: StatusTab,
): DefaultFilters => {
	return {
		'filter.statuses': defaultStatuses(t, navTab),
	};
};
