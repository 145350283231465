import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useGetDashboard } from '@uturn/api/v1';
import {
	Button,
	Callout,
	CalloutIcon,
	CalloutTitle,
	Icon,
	sonner,
} from '@uturn/ui-kit';
import type { AxiosError } from 'axios';
import { Suspense } from 'react';
import { AllowedTo, useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission } from '@uturn/portal/abac';
import { LoadingSpan, Page } from '@uturn/portal/components';
import { getApiErrorMessage } from '@uturn/portal/utils';

function AccessDenied() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Callout variant="destructive">
			<CalloutIcon>
				<Icon icon={faInfoCircle} />
			</CalloutIcon>
			<CalloutTitle>
				<b>{t('pages.dashboard.forbidden.title', 'Access denied')}</b>:{' '}
				{t(
					'pages.dashboard.forbidden.description',
					'You need "Shipper Admin" role to access this page.',
				)}
			</CalloutTitle>
			<Button
				variant="destructive"
				size="sm"
				className="ml-auto flex items-center gap-3"
				onClick={(e) => {
					navigate('/');
				}}
			>
				{t('pages.dashboard.forbidden.action', 'Back')}
			</Button>
		</Callout>
	);
}

export function DashboardInsights() {
	const { t } = useTranslation();
	const { userHasPermissions } = useAbac();

	const { data: metabase } = useGetDashboard({
		query: {
			enabled: userHasPermissions(Permission.METABASE_API),
			select: (data) => data.data,
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.dashboard.api-error',
						'Could not load dashboard. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	return (
		<>
			<Helmet title={t('pages.dashboard.title', 'Data insight').toString()} />
			<Page title={t('pages.dashboard.title', 'Data insight').toString()}>
				<AllowedTo perform={Permission.METABASE_API} no={AccessDenied}>
					<Suspense fallback={<LoadingSpan />}>
						<iframe
							className="h-screen w-full"
							title="uturn-dashboard"
							src={metabase?.url}
						></iframe>
					</Suspense>
				</AllowedTo>
			</Page>
		</>
	);
}
