import { ShipmentLocationActionRequestDtoLocationActionType as ActionType } from '@uturn/api/v1';
import { useTranslation } from 'react-i18next';

export const useLocationTypeTranslation = () => {
	const { t } = useTranslation();
	return {
		[ActionType.PICKUP]: t('general.location-type.pickup', 'Pick-up'),
		[ActionType.DROP]: t('general.location-type.drop', 'Drop'),
		[ActionType.STOP]: t('general.location-type.stop', 'Stop'),
		[ActionType.DELIVER]: t('general.location-type.deliver', 'Deliver'),
		[ActionType.LOAD]: t('general.location-type.load', 'Load'),
	};
};
