import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import {
	type ShipmentLocationActionRequestDto as LocationActionPOST, // POST means from POST shipment
	type ShipmentLocationActionRequestDtoLocationActionType as LocationActionTypePOST, // POST means from POST shipment
	ShipmentLocationActionUpdateDtoLocationActionType as LocationActionTypePUT, // PUT means from PUT shipment
} from '@uturn/api/v1';
import {
	DistanceUnit,
	DurationUnit,
	type Route,
	RouteType,
	WeightUnit,
	useFetchDistanceAndRouteDetails,
} from '@uturn/api/v2';
import {
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
	Checkbox,
	Icon,
} from '@uturn/ui-kit';
import { type AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Permission } from '@uturn/portal/abac';
import type {
	FormValues,
	ZodLocation,
} from '@uturn/portal/modules/shipments/schema';
import {
	defaultRouteKpis,
	fromZodLocations,
	shouldRevalidate,
	shouldUpdate,
	toAvailableRoutes,
	toRouteKpis,
} from '@uturn/portal/modules/shipments/utils';
import type {
	AnyLocationAction,
	AvailableRoute,
} from '@uturn/portal/types/shipment';
import {
	formatCo2Emission,
	formatDistance,
	formatDuration,
	getApiErrorMessage,
	toTitle,
} from '@uturn/portal/utils';
import { convertToIsoDateWithTimezone } from '@uturn/portal/utils/convert-to-iso-date-with-timezone';
import { removeEmptyDataFields } from '@uturn/portal/utils/remove-empty-data-fields';

function RouteDetailHeading({ children }: React.PropsWithChildren) {
	return (
		<span className="text-secondary-800 text-sm font-bold">{children}</span>
	);
}

function RouteDetailDesc({ children }: React.PropsWithChildren) {
	return <span className="text-secondary-700 text-sm">{children}</span>;
}

function RouteValidation({ children }: React.PropsWithChildren) {
	return <div className="mt-4">{children}</div>;
}

function RouteValidationHeading({ children }: React.PropsWithChildren) {
	return <h4 className="text-xs font-bold leading-5">{children}</h4>;
}

function RouteValidationMessage({ children }: React.PropsWithChildren) {
	return <p className="text-xs leading-5">{children}</p>;
}

function routeError({
	description,
	title,
}: {
	description: string;
	title: string;
}): AvailableRoute[] {
	return [
		{
			operatorId: 'undefined',
			routeType: RouteType.TRUCK,
			isSelected: false,
			isValid: false,
			isDisabled: true,
			validationTitle: title,
			validationMessages: [description],
			routeDetails: {
				co2Emission: { quantity: 0, unit: WeightUnit.kilogram },
				distance: { quantity: 0, unit: DistanceUnit.kilometer },
				duration: { quantity: 0, unit: DurationUnit.hour },
			},
		},
	];
}

const fieldRegExps: Record<string, RegExp> = {
	location: /^locations\.[0-9]*\.action\.location/,
	dateFrom: /^locations\.[0-9]*\.action\.dateFrom/,
	dateUntil: /^locations\.[0-9]*\.action\.dateUntil/,
};

export type PossibleRoutesProps = {
	/**
	 * Shipment is archived
	 * (i.e.: executed, completed or cancelled)
	 */
	archived: boolean;
	/**
	 * Shipment is a matched shipment
	 * between a carrier and a shipper
	 */
	matched: boolean;
};

export function PossibleRoutes({ archived, matched }: PossibleRoutesProps) {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();
	const fetchDistanceAndRouteDetails = useFetchDistanceAndRouteDetails();
	const { userHasPermissions } = useAbac();

	const [selected, setSelected] = useState<boolean[]>([]);
	const [routes, setRoutes] = useState<AvailableRoute[]>([]);

	const watchRoutes = form.watch('routes');
	const watchPreferredCarrierIds = form.watch('preferredCarrierIds');

	const isContractShipment = (): boolean => {
		return watchPreferredCarrierIds
			? watchPreferredCarrierIds.length > 0
			: false;
	};

	const setPublishUntilDate = (
		locationActions: AnyLocationAction[],
		publishUntilDate: string | undefined,
	) => {
		const pickupLocation = locationActions.find(
			(item) => item.locationActionType === LocationActionTypePUT.PICKUP,
		);
		if (!pickupLocation) {
			return;
		}
		const { dateUntil: pickupUntilDate } = pickupLocation;
		if (!pickupUntilDate) {
			return;
		}
		if (
			!publishUntilDate ||
			new Date(publishUntilDate) < new Date(pickupUntilDate)
		) {
			form.setValue(
				'publishUntilDate',
				convertToIsoDateWithTimezone(new Date(pickupUntilDate)),
				shouldRevalidate,
			);
		}
	};

	const fetchAvailableRoutes = (
		locationActions: AnyLocationAction[],
		isoType: string | undefined,
	) => {
		if (!userHasPermissions(Permission.FOUR_SHIPPING_API)) {
			setRoutes(
				routeError({
					title: t(
						'pages.create_shipment.possible_routes.error.forbidden.title',
						'Waiting for approval:',
					),
					description: t(
						'pages.create_shipment.possible_routes.error.forbidden.description',
						'Cannot access the Multimodal transport information while your organisation is pending approval by UTURN.',
					),
				}),
			);
			return;
		}

		if (archived) {
			/*
				Two possible use-cases:
				- Error already thrown earlier while loading shipment routes
				  and did not find any.
				- Routes loaded successfuly from shipment data, but user has 
				  to changes one of route KPIs' refresh dependencies (any 
				  address, date from or date until). Since the shipment is 
				  archived anyway, we leave those route KPIs alone.
				In both cases, nothing to do.
			 */
			return;
		}

		const locations =
			locationActions
				.filter((item) => !!item.location?.id || !!item.location?.googlePlaceId)
				.filter((item) => !!item.locationActionType) // Avoid potential Bad Requests
				.map((item) => {
					const {
						location,
						locationActionType,
						dateFrom,
						dateUntil,
						sequenceNumber,
					} = item;
					const result: LocationActionPOST =
						removeEmptyDataFields<LocationActionPOST>({
							location,
							locationActionType: locationActionType as LocationActionTypePOST,
							dateFrom,
							dateUntil,
							sequenceNumber: sequenceNumber!,
						});
					return result;
				}) ?? [];

		if (locations.length < 2) {
			setRoutes([]);
			return;
		}

		fetchDistanceAndRouteDetails.mutate(
			{
				data: { locations, isoCode: isoType },
			},
			{
				onSuccess: (data) => {
					const availableRoutes = toAvailableRoutes(
						t,
						data.data?.routes,
						isContractShipment(),
						matched,
					);
					setRoutes(availableRoutes);
				},
				onError: (error: AxiosError | any) => {
					setRoutes(
						routeError({
							title: t(
								'pages.create_shipment.possible_routes.error.server.title',
								'Internal server error:',
							),
							description: getApiErrorMessage(error),
						}),
					);
				},
			},
		);
	};

	const handleChange = (checked: boolean, index: number) => {
		setSelected(selected.map((value, i) => (i === index ? checked : value)));
	};

	useEffect(() => {
		const currRoutes = (watchRoutes as Route[]) ?? [];
		if (currRoutes.length) {
			const availableRoutes = toAvailableRoutes(
				t,
				currRoutes,
				isContractShipment(),
				matched,
			);
			setRoutes(availableRoutes);
			return;
		}

		if (archived) {
			setRoutes(
				routeError({
					title: t(
						'pages.create_shipment.possible_routes.error.not-found.title',
						'Server response:',
					),
					description: t(
						'pages.create_shipment.possible_routes.error.not-found.description',
						'No routes were found',
					),
				}),
			);
			return;
		}

		const [locations, isoType] = form.getValues([
			'locations',
			'equipment.isoType',
		]);

		const { locationActions } = fromZodLocations(locations);

		fetchAvailableRoutes(locationActions, isoType);
	}, [watchRoutes]);

	useEffect(() => {
		const subscription = form.watch((value, { name }) => {
			const dirtyLocation = name?.match(fieldRegExps.location);
			const dirtyDateFrom = name?.match(fieldRegExps.dateFrom);
			const dirtyDateUntil = name?.match(fieldRegExps.dateUntil);

			if (dirtyLocation || dirtyDateFrom || dirtyDateUntil) {
				const { locationActions } = fromZodLocations(
					value.locations as ZodLocation[],
				);

				if (dirtyDateUntil) {
					setPublishUntilDate(locationActions, value.publishUntilDate);
				}

				fetchAvailableRoutes(locationActions, value.equipment?.isoType);
			}
		});

		return () => subscription.unsubscribe();
	}, [form.watch]);

	useEffect(() => {
		if (!routes.length && selected.length) {
			setSelected([]);
			return;
		}

		setSelected(routes.map((route) => route.isSelected));
	}, [routes]);

	useEffect(() => {
		if (routes.length === 0) {
			form.setValue('routeKpis', defaultRouteKpis, shouldUpdate);
			return;
		}
		form.setValue(
			'routeKpis',
			toRouteKpis(t, routes, isContractShipment(), matched, selected),
			shouldUpdate,
		);
	}, [routes, selected]);

	return (
		<div className="absolute top-0 z-10 w-full px-5 pt-5">
			<Card>
				<CardHeader>
					<CardTitle>
						{t('pages.create_shipment.possible_routes.heading', 'Routes')}
					</CardTitle>
					{routes.length === 0 ? (
						<CardDescription>
							{t(
								'pages.create_shipment.possible_routes.empty-state',
								'Provide a minimum of 2 locations to see available routes.',
							)}
						</CardDescription>
					) : (
						<>
							<div className="flex w-full flex-row justify-between">
								<div className="flex flex-col">
									<RouteDetailHeading>
										{t(
											'pages.create_shipment.possible_routes.headings.transport',
											'Transport',
										)}
									</RouteDetailHeading>
									{routes.map((route, index) => (
										<div className="flex gap-1" key={`selection-${index}`}>
											<div className="flex size-4">
												{route.isValid ? (
													<Checkbox
														checked={selected[index]}
														disabled={route.isDisabled}
														onCheckedChange={(state) => {
															if (typeof state === 'boolean') {
																handleChange(state, index);
															}
														}}
													/>
												) : (
													<Icon
														icon={faCircleExclamation}
														className="text-danger-600"
													/>
												)}
											</div>
											<RouteDetailDesc>
												{toTitle(route.routeType)}
											</RouteDetailDesc>
										</div>
									))}
								</div>
								<div className="flex flex-col">
									<RouteDetailHeading>
										{t(
											'pages.create_shipment.possible_routes.headings.time',
											'Duration',
										)}
									</RouteDetailHeading>
									{routes.map((route, index) => (
										<RouteDetailDesc key={`duration-${index}`}>
											{route.routeDetails.duration.quantity
												? formatDuration(
														route.routeDetails.duration.quantity,
														route.routeDetails.duration.unit,
													)
												: '-'}
										</RouteDetailDesc>
									))}
								</div>
								<div className="flex flex-col">
									<RouteDetailHeading>
										{t(
											'pages.create_shipment.possible_routes.headings.emission',
											'CO₂ emission',
										)}
									</RouteDetailHeading>
									{routes.map((route, index) => (
										<RouteDetailDesc key={`co2-emission-${index}`}>
											{route.routeDetails.co2Emission.quantity
												? formatCo2Emission(
														route.routeDetails.co2Emission.quantity,
														route.routeDetails.co2Emission.unit!,
													)
												: '-'}
										</RouteDetailDesc>
									))}
								</div>
								<div className="flex flex-col">
									<RouteDetailHeading>
										{t(
											'pages.create_shipment.possible_routes.headings.distance',
											'Distance',
										)}
									</RouteDetailHeading>
									{routes.map((route, index) => (
										<RouteDetailDesc key={`distance-${index}`}>
											{route.routeDetails.distance.quantity
												? formatDistance(
														route.routeDetails.distance.quantity,
														route.routeDetails.distance.unit,
													)
												: '-'}
										</RouteDetailDesc>
									))}
								</div>
							</div>
							{routes
								.filter((route) => !route.isValid)
								.map((route, index) => (
									<RouteValidation key={`validation-${index}`}>
										{route.validationTitle && (
											<RouteValidationHeading>
												{route.validationTitle}
											</RouteValidationHeading>
										)}
										{route.validationMessages.map(
											(error: string, i: number) => (
												<RouteValidationMessage key={`error-${i}`}>
													{error}
												</RouteValidationMessage>
											),
										)}
									</RouteValidation>
								))}
						</>
					)}
				</CardHeader>
			</Card>
		</div>
	);
}
