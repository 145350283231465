import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTip } from '@uturn/ui';
import { Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function HiddenFieldLabelInfo() {
	const { t } = useTranslation();
	return (
		<ToolTip
			title={`${t(
				'pages.shipment.tooltip.hidden_fields.info',
				'Information visible after carrier has been assigned',
			)}`}
		>
			<div className="ml-1 flex size-5 items-center justify-center rounded-2xl bg-transparent">
				<Icon icon={faEyeSlash} className="text-secondary-700 size-3.5" />
			</div>
		</ToolTip>
	);
}
