import type { ColumnDef } from '@tanstack/react-table';
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { MyCarriersDTO } from '@uturn/api/v1';
import type { StarsRating } from '@uturn/ui';

import type { BadgeProps } from '@uturn/ui-kit';
import {
	Badge,
	Stars,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { UncontractCarrier } from './uncontract';

const badgeColor: Record<string, BadgeProps['variant']> = {
	contracted: 'default',
	inactive: 'destructive',
	blacklisted: 'destructive',
	requested: 'outline',
	dedicated: 'default',
	default: 'secondary',
};

export function CarriersTable({ data }: { data: MyCarriersDTO[] }) {
	const { t } = useTranslation();

	const columns: ColumnDef<MyCarriersDTO>[] = [
		{
			header: t('general.carrier', 'Carrier')!,
			cell: (cell) => {
				const row = cell.row.original;
				return (
					<div className="flex flex-col items-start justify-start">
						<span className="font-medium">{row.carrierParty}</span>
						<span className="mt-1 text-xs text-muted-foreground">
							{row.carrierEmail}
						</span>
					</div>
				);
			},
		},
		{
			header: t('general.status', 'Status')!,
			cell: (cell) => {
				const row = cell.row.original;
				const relationStatus = row.relationStatus?.toLowerCase() ?? 'default';

				const color = badgeColor[
					relationStatus as keyof typeof badgeColor
				] as BadgeProps['variant'];

				return <Badge variant={color}>{row.relationStatus}</Badge>;
			},
		},
		{
			header: t('general.average-rating', 'Average rating')!,
			cell: (cell) => {
				const row = cell.row.original;

				return (
					<div className="flex flex-row items-center justify-start">
						<Stars rating={row.averageRating as StarsRating} />
						<span className="ml-2 mt-1 text-xs text-muted-foreground">
							{`(${t('general.reviews', {
								count: row.timesRated ?? 0,
							})})`}
						</span>
					</div>
				);
			},
		},
		{
			header: t('general.matches', 'Matches')!,
			accessorKey: 'totalMatches',
		},
		{
			id: 'actions',
			cell: (cell) => {
				const row = cell.row.original;
				return (
					<div className="flex justify-end pr-3.5">
						<UncontractCarrier carrier={row} />
					</div>
				);
			},
		},
	];
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});
	return (
		<div className="overflow-x-auto rounded-md border">
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								{t('general.no-results', 'No results.')}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	);
}
