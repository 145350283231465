import { Sonner, Toaster, useTheme } from '@uturn/ui-kit';
import { CommandMenu } from './command-palette';

export function Providers({ children }: { children: React.ReactNode }) {
	const { theme } = useTheme();

	return (
		<>
			<CommandMenu />
			{children}
			<Toaster />
			<Sonner
				richColors
				theme={theme.mode}
				closeButton
				position="bottom-center"
			/>
		</>
	);
}
