import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { ShipmentStatus } from '@uturn/api/v1';
import { Button, Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export type StickyBarActionProps = {
	label: string | React.ReactNode;
	variant: 'default' | 'outline';
	onClick: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	options?: {
		buttonLoadingCaption?: string;
	};
};

export function StickyBarAction({
	label,
	variant,
	onClick,
	isLoading = false,
	disabled = false,
	options,
}: StickyBarActionProps) {
	return (
		<Button
			variant={variant}
			onClick={onClick}
			disabled={disabled || isLoading}
		>
			{isLoading ? (
				<>
					<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
					{options?.buttonLoadingCaption ?? 'Loading...'}
				</>
			) : (
				label
			)}
		</Button>
	);
}

export type StickyBarActionsProps = {
	shipmentStatus: ShipmentStatus;
	targetPrice: string;
	isPublishing: boolean;
	isSubmitting: boolean;
	isDirty: boolean;
	onUpdate: () => void;
	onPublish: () => void;
	onUnpublish: () => void;
	onCancel: () => void;
};

export function StickyBarActions({
	shipmentStatus,
	targetPrice,
	isPublishing,
	isSubmitting,
	isDirty,
	onUpdate,
	onPublish,
	onUnpublish,
	onCancel,
}: StickyBarActionsProps) {
	const { t } = useTranslation();

	const labels = {
		save: t(
			'pages.shipment-detail.sticky_bar.save_concept_button',
			'Save concept',
		),
		publish: t(
			'pages.shipment-detail.sticky_bar.publish_shipment_button',
			'Publish shipment - target price {{targetPrice}}',
			{ targetPrice },
		),
		publishing: t(
			'pages.shipment-detail.sticky_bar.publishing_shipment_button',
			'Publishing shipment... - price {{targetPrice}}',
			{ targetPrice },
		),
		update: t(
			'pages.shipment-detail.sticky_bar.update_shipment_button',
			'Update',
		),
		updating: t(
			'pages.shipment-detail.sticky_bar.button.loading',
			'Updating...',
		),
		cancel: t(
			'pages.shipment-detail.sticky_bar.cancel_shipment_button',
			'Cancel shipment',
		),
		unpublish: t(
			'pages.shipment-detail.sticky_bar.unpublish_shipment_button',
			'Unpublish shipment',
		),
	};

	const options = {
		buttonLoadingCaption: labels.updating,
	};

	if (shipmentStatus === ShipmentStatus.UNKNOWN) {
		return null;
	}

	return (
		<div className="my-auto flex h-full items-center justify-end">
			<div className="flex flex-row gap-4">
				{shipmentStatus === ShipmentStatus.CONCEPT && (
					<>
						<StickyBarAction
							label={labels.save}
							variant={'outline'}
							onClick={onUpdate}
							isLoading={!isPublishing && isSubmitting}
							disabled={isSubmitting || !isDirty}
							options={options}
						/>
						<StickyBarAction
							label={labels.publish}
							variant={'default'}
							onClick={onPublish}
							isLoading={isPublishing && isSubmitting}
							disabled={isSubmitting}
							options={{ buttonLoadingCaption: labels.publishing }}
						/>
					</>
				)}
				{shipmentStatus === ShipmentStatus.PUBLISHED && (
					<>
						<StickyBarAction
							label={labels.unpublish}
							variant={'outline'}
							onClick={onUnpublish}
							disabled={isSubmitting}
							options={options}
						/>
						<StickyBarAction
							label={labels.update}
							variant={'default'}
							onClick={onUpdate}
							isLoading={isSubmitting}
							options={options}
							disabled={!isDirty}
						/>
					</>
				)}
				{shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED && (
					<>
						<StickyBarAction
							label={labels.cancel}
							variant={'outline'}
							onClick={onCancel}
							disabled={isSubmitting}
							options={options}
						/>
						<StickyBarAction
							label={labels.update}
							variant={'default'}
							onClick={onUpdate}
							isLoading={isSubmitting}
							disabled={!isDirty}
							options={options}
						/>
					</>
				)}
				{shipmentStatus === ShipmentStatus.CANCELLED && (
					<StickyBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting || !isDirty}
						options={options}
						disabled={!isDirty}
					/>
				)}
				{shipmentStatus === ShipmentStatus.COMPLETED && (
					<StickyBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting}
						options={options}
						disabled={!isDirty}
					/>
				)}
				{shipmentStatus === ShipmentStatus.QUOTED && (
					<>
						<StickyBarAction
							label={labels.unpublish}
							variant={'outline'}
							onClick={onUnpublish}
							disabled={isSubmitting}
							options={options}
						/>
						<StickyBarAction
							label={labels.update}
							variant={'default'}
							onClick={onUpdate}
							isLoading={isSubmitting}
							options={options}
							disabled={!isDirty}
						/>
					</>
				)}
				{shipmentStatus === ShipmentStatus.EXECUTED && (
					<StickyBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting}
						options={options}
						disabled={!isDirty}
					/>
				)}
			</div>
		</div>
	);
}
