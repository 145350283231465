import { create } from 'zustand';
import { type StatusTab } from '@uturn/portal/types/shipments';

type ShipmentsSearchStore = {
	totalShipments: number;
	setTotalShipments: (totalShipments: number) => void;
	activeStatusTab: StatusTab;
	setActiveStatusTab: (activeStatusTab: StatusTab) => void;
};

export const useShipmentsSearchStore = create<ShipmentsSearchStore>((set) => ({
	totalShipments: 0,
	setTotalShipments: (totalShipments) => set({ totalShipments }),
	activeStatusTab: 'published',
	setActiveStatusTab: (activeStatusTab) => set({ activeStatusTab }),
}));
