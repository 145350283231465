import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@uturn/ui-kit';
import type { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export type FileDropzoneProps = {
	getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

export function FileDropzone({
	getRootProps,
	getInputProps,
}: FileDropzoneProps) {
	const { t } = useTranslation();

	return (
		<section className="max-w-xl">
			<div
				{...getRootProps()}
				className="bg-secondary-50/20 border-secondary-300 hover:border-secondary-400 flex h-40 w-full cursor-pointer appearance-none justify-center rounded-md border-2 border-dashed px-4 transition focus:outline-none"
			>
				<input {...getInputProps()} />
				<span className="flex items-center justify-center gap-2">
					<Icon icon={faCloudUpload} className="text-secondary-600 size-5" />
					<span className="text-secondary-600 text-sm font-medium">
						{t(
							'pages.shipment-detail.modal.add-document.form.add',
							'Drop file to Attach, or',
						)}{' '}
						<span className="text-primary-600 underline">
							{t(
								'pages.shipment-detail.modal.add-document.form.browse',
								'browse',
							)}
						</span>
					</span>
				</span>
			</div>
		</section>
	);
}
