import {
	type AddressDTO,
	PriceCalculatorTransportType,
	type PriceRequestDto,
} from '@uturn/api/v1';
import {
	Button,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
	FormFieldReadOnly,
	Label,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { getLocationQueryString } from '@uturn/portal/utils';

export type PriceRequestDetailsProps = {
	details: PriceRequestDto;
	locations: AddressDTO[] | string[];
	createNewRequest?: () => void;
	editRequest?: () => void;
};

export function PriceRequestDetails({
	details,
	createNewRequest,
	editRequest,
	locations,
}: PriceRequestDetailsProps) {
	const { t } = useTranslation();
	const { transportType, additionalRequirement, containerSize } = details;

	const generateLocationLabel = (type: string) => {
		return t(
			'pages.price-calculator.request.location-label',
			'{{ locationType }} location',
			{
				locationType: type,
			},
		);
	};

	// TODO: Get rid of this crap
	const locationLabels = [
		generateLocationLabel(t('general.route-locations.pickup', 'Pickup')),
		...(transportType === PriceCalculatorTransportType.IMPORT
			? [generateLocationLabel(t('general.route-locations.deliver', 'Deliver'))]
			: []),
		...(transportType === PriceCalculatorTransportType.EXPORT
			? [generateLocationLabel(t('general.route-locations.load', 'Load'))]
			: []),
		...(transportType === PriceCalculatorTransportType.SHUNT
			? [generateLocationLabel(t('general.route-locations.drop', 'Drop'))]
			: []),
		generateLocationLabel(t('general.route-locations.drop', 'Drop')),
	];

	return (
		<>
			<CardHeader className="pb-4">
				<CardTitle>
					{t('pages.price-calculator.request.trip-label', 'Trip')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col gap-5">
					<div>
						<Label>{locationLabels[0]}</Label>
						<FormFieldReadOnly value={getLocationQueryString(locations[0])} />
					</div>
					<div>
						<Label>{locationLabels[1]}</Label>
						<FormFieldReadOnly value={getLocationQueryString(locations[1])} />
					</div>
					{transportType !== 'SHUNT' && (
						<div>
							<Label>{locationLabels[2]}</Label>
							<FormFieldReadOnly value={getLocationQueryString(locations[2])} />
						</div>
					)}
				</div>
				<h3 className="font-heading mb-2 mt-8 font-medium tracking-tight">
					{t('pages.price-calculator.request.details-label', 'Details')}
				</h3>
				<div className="flex flex-row gap-10">
					<div>
						<Label>
							{t(
								'pages.price-calculator.request.transport-type',
								'Transport type',
							)}
						</Label>
						<FormFieldReadOnly value={transportType} />
					</div>
					<div>
						<Label>
							{t(
								'pages.price-calculator.request.container-size',
								'Container size',
							)}
						</Label>
						<FormFieldReadOnly
							value={t(
								`general.container-sizes.${containerSize}`,
								`${containerSize}`,
							)}
						/>
					</div>
					<div>
						<Label>
							{t(
								'pages.price-calculator.request.additional-requirements',
								'Additional requirements',
							)}
						</Label>
						<FormFieldReadOnly value={additionalRequirement} />
					</div>
				</div>
			</CardContent>
			<CardFooter>
				{editRequest && createNewRequest && (
					<div className="mt-10 flex w-full flex-row justify-end gap-2">
						{editRequest && (
							<Button variant="secondary" onClick={editRequest}>
								{t(
									'pages.price-calculator.adjust-request',
									'Adjust price request',
								)}
							</Button>
						)}
						{createNewRequest && (
							<Button onClick={createNewRequest}>
								{t(
									'pages.price-calculator.new-request',
									'Create new price request',
								)}
							</Button>
						)}
					</div>
				)}
			</CardFooter>
		</>
	);
}
