import type { User } from '@uturn/api/v1';

declare global {
	interface Window {
		usetifulTags: {
			platform: string;
			userId?: string;
			organisationId?: number;
			organisationName?: string;
			organisationType?: string;
			firstName?: string;
			role?: string;
		};
		USETIFUL: {
			user: {
				setId: (id: string) => void;
			};
			reinitialize: () => void;
		};
	}
}

export class Usetiful {
	static init() {
		window.usetifulTags = {
			platform: 'nexturn',
		};
	}

	static identify(user: User) {
		window.usetifulTags = {
			...window.usetifulTags,
			userId: user.userId,
			firstName: user.firstName,
			role: user.role,
		};

		if (user.organisation) {
			window.usetifulTags = {
				...window.usetifulTags,
				organisationId: user.organisation.id,
				organisationName: user.organisation.name,
				organisationType: user.organisation.organisationType,
			};
		}

		if (!window.USETIFUL || !window.USETIFUL.user) return;
		window.USETIFUL.user.setId(user.userId);
		window.USETIFUL.reinitialize();
	}
}
