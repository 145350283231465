import type { ShipmentListDTO } from '@uturn/api/v1';
import { Badge } from '@uturn/ui-kit';

// TODO: Fix any, typing is not correct from API
export function RequirementBadges({
	requirements,
}: {
	requirements: ShipmentListDTO['requirements'];
}) {
	return (
		<div className="flex gap-2">
			{requirements!.length ? (
				requirements
					?.filter((requirement) => requirement.length)
					.map((req: any) => (
						<Badge key={req} variant="outline">
							{req}
						</Badge>
					))
			) : (
				<span>-</span>
			)}
		</div>
	);
}
