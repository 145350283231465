import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import { availableLanguages } from '@uturn/portal/i18n';

type Props = {
	flagOnly?: boolean;
};

export default function LanguageSelector({ flagOnly }: Props) {
	const { i18n, t } = useTranslation();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" size="icon">
					<Flag
						code={
							availableLanguages.find(({ code }) => code === i18n.language)
								?.country || 'gb'
						}
						className="h-auto w-10 rounded-md p-1"
					/>
					<span className="sr-only">
						{t('general.languages.change', 'Change language')}
					</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				{availableLanguages.map((lng) => (
					<DropdownMenuItem
						key={lng.code}
						onClick={() => i18n.changeLanguage(lng.code)}
					>
						<div className="flex items-center gap-2">
							<Flag code={lng.country} className="size-7 rounded-md" />
							{!flagOnly && <span>{lng.code.toUpperCase()}</span>}
						</div>
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
