import type { TFunction } from 'i18next';
import type { TypeOfShipment } from '@uturn/portal/types/shipment';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
	faFolders,
	faPlus,
	faTableList,
} from '@fortawesome/pro-regular-svg-icons';

export const typeOfShipmentName = (
	t: TFunction<'translation', undefined>,
): Record<TypeOfShipment, string> => ({
	single: t('general.type-of-transport.single', 'single'),
	group: t('general.type-of-transport.group', 'group'),
	multi: t('general.type-of-transport.multi', 'multi'),
});

export const typeOfShipmentIcon: Record<TypeOfShipment, IconDefinition> = {
	single: faPlus,
	group: faFolders,
	multi: faTableList,
};

export const typeOfShipmentTitle = (
	t: TFunction<'translation', undefined>
): Record<TypeOfShipment, string> => ({
	single: t('general.type-of-transport.single.title', 'Single shipment'),
	group: t('general.type-of-transport.group.title', 'Grouped shipments'),
	multi: t('general.type-of-transport.multi.title', 'Bulk create shipments'),
});

export const typeOfShipmentDescription = (
	t: TFunction<'translation', undefined>
): Record<TypeOfShipment, string> => ({
	single: t(
		'general.type-of-transport.single.description',
		'One shipment, nice and easy.'
	),
	group: t(
		'general.type-of-transport.group.description',
		'Multiple shipments with the same details and grouped by name'
	),
	multi: t(
		'general.type-of-transport.multi.description',
		'Multiple shipments with different details in excel-like table'
	),
});
