import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useAssignCarrier, useDeclineQuote } from '@uturn/api/v1';
import { useGetShipment } from '@uturn/api/v2';

import type { StarsRating } from '@uturn/ui-kit';
import { sonner, Stars } from '@uturn/ui-kit';

import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteSkeleton } from './quotes-skeleton';
import { SingleQuote } from './single-quote';
import Overlay from '@uturn/portal/components/overlay';
import { MetaDataContext } from '@uturn/portal/context';
import { hevoClient } from '@uturn/portal/services/hevo';
import { formatPrice } from '@uturn/portal/utils';

export function Quotes({
	shipmentNumber,
	parentQueryKey,
	onAssign,
	onDecline,
	setOpenSheet,
}: {
	shipmentNumber: number;
	parentQueryKey?: QueryKey;
	onAssign?: () => void;
	onDecline?: () => void;
	setOpenSheet: (open: boolean) => void;
}) {
	const { t } = useTranslation();
	const { metadata } = useContext(MetaDataContext);
	const queryClient = useQueryClient();
	const {
		data: shipment,
		isLoading,
		queryKey,
	} = useGetShipment(shipmentNumber, {
		query: {
			select: (data) => data.data,
			suspense: false,
		},
	});

	useEffect(() => {
		if (!shipment) {
			return;
		}
		(async () => {
			await hevoClient.sendEventQuoteViewed({
				user: metadata?.user!,
				view_name:
					window.location.pathname === '/shipments'
						? 'shipments list'
						: 'shipment details',
				data: {
					shipmentNumber,
					quotes: shipment?.quotes?.sort(
						(a, b) => (a.price.quantity ?? 0) - (b.price.quantity ?? 0),
					),
				},
				timestamp: new Date().getTime(),
				metadata: window.navigator.userAgent,
			});
		})();
	}, [shipment]);

	const assignQuote = useAssignCarrier();
	const removeQuote = useDeclineQuote();

	if (isLoading) {
		return (
			<div className="grid gap-5 px-8 pt-8">
				<QuoteSkeleton />
			</div>
		);
	}

	const locations = shipment?.locationActions?.sort(
		(a: any, b: any) => a.sequenceNumber! - b.sequenceNumber!,
	);

	const handleAssignQuote = async (quoteId: number) => {
		assignQuote.mutate(
			{ data: { quoteId, shipmentNr: shipmentNumber } },
			{
				onSuccess: () => {
					queryClient.invalidateQueries({
						queryKey,
					});

					if (parentQueryKey) {
						queryClient.invalidateQueries({
							queryKey: parentQueryKey,
						});
					}

					if (onAssign) {
						onAssign();
					}
					sonner.success(
						t(
							'shipment.quote.toast.accept.success',
							'Quote successfully assigned!',
						),
					);

					setOpenSheet(false);
				},
				onError: () => {
					sonner.error(
						t(
							'shipment.quote.toast.accept.error',
							'Error: Unable to assign the quote. Please try again.',
						),
					);
				},
			},
		);
	};

	const handleDeclineQuote = async (
		quoteId: number,
		declineReason?: string,
	) => {
		removeQuote.mutate(
			{
				quoteId,
				data: { declineReason: declineReason ?? '' },
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries({
						queryKey,
					});

					if (parentQueryKey) {
						queryClient.invalidateQueries({
							queryKey: parentQueryKey,
						});
					}

					if (onDecline) {
						onDecline();
					}
					sonner.success(
						t(
							'shipment.quote.toast.decline.success',
							'Quote successfully declined!',
						),
					);
					setOpenSheet(false);
				},

				onError: () => {
					sonner.error(
						t(
							'shipment.quote.toast.decline.error',
							'Error: Unable to decline the quote. Please try again.',
						),
					);
				},
			},
		);
	};

	return (
		<div className="grid grid-cols-1 gap-x-6 gap-y-4 pt-4 sm:max-w-xl sm:grid-cols-6">
			{(assignQuote.isLoading || removeQuote.isLoading) && (
				<Overlay>{t('general.loading', 'Loading...')!}</Overlay>
			)}
			<div className="col-span-full flex flex-col gap-2">
				<h3 className="text font-heading font-medium tracking-tight">
					{t(
						'general.quotes.modal.section.shipment_info.heading',
						'Shipment information',
					)}
				</h3>
				<div className="text-secondary-900 grid grid-cols-[minmax(0,_3fr),_minmax(0,_7fr)] gap-1.5 text-sm font-medium leading-6">
					<span className="text-muted-foreground">
						{t(
							'general.quotes.modal.section.shipment_info.label.shipment',
							'Shipment',
						)}
					</span>
					<span>{shipment?.id}</span>
					<span className="text-muted-foreground">
						{t(
							'general.quotes.modal.section.shipment_info.label.pickup',
							'Pick-up',
						)}
					</span>
					<span>{locations?.at(0)?.location?.name}</span>

					<span className="text-muted-foreground">
						{t(
							'general.quotes.modal.section.shipment_info.label.destination',
							'Destination',
						)}
					</span>
					<span>{locations?.at(-1)?.location?.name}</span>

					<span className="text-muted-foreground">
						{t(
							'general.quotes.modal.section.shipment_info.label.target_price',
							'Target price',
						)}
					</span>
					<span>
						{formatPrice(
							shipment?.price?.quantity as number,
							shipment?.price?.currencyCode ?? 'EUR',
						)}
					</span>
				</div>
			</div>

			{shipment?.carrier ? (
				<div className="col-span-full space-y-2">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'general.quotes.modal.section.carrier.heading',
							'Selected carrier',
						)}
					</h3>
					<div>
						<div className="flex justify-between">
							<div>{shipment?.carrier.name}</div>
							<div className="flex flex-col items-end">
								<Stars
									rating={shipment?.carrier.rating.averageRating as StarsRating}
								/>
								<span className="text-sm text-muted-foreground">
									{t('general.quotes.modal.section.carrier.rating', {
										defaultValue: '{{ number }} rating(s)',
										number: shipment?.carrier.rating.totalRatings ?? 0,
									})}
								</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="col-span-full flex max-h-full flex-col gap-2.5">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'general.quotes.modal.section.carrier_qoutes.heading',
							'Carrier quotes',
						)}
					</h3>

					{shipment?.quotes?.length === 0 ? (
						<div className="text-center text-gray-500">
							{t(
								'general.quotes.modal.section.carrier_qoutes.no_quotes',
								'No quotes available',
							)}
						</div>
					) : (
						shipment?.quotes
							?.sort(
								(a, b) => (a.price.quantity ?? 0) - (b.price.quantity ?? 0),
							)
							.map((quote, index) => (
								<SingleQuote
									key={quote.id ?? index}
									quote={quote}
									onAssignQuote={handleAssignQuote}
									onDeclineQuote={handleDeclineQuote}
									defaultOpen={false}
									shipmentNumber={shipmentNumber}
								/>
							))
					)}
				</div>
			)}
		</div>
	);
}
