import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LanesList } from '../components/lanes-list';
import { Page } from '@uturn/portal/components';
import { InfoRouteCardSkeleton } from '@uturn/portal/components/info-route-card';

export function LanesOverview() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<Helmet title={t('pages.lanes.overview.title', 'Lanes')!} />
			<Page
				title={t('pages.lanes.overview.title', 'Lanes')!}
				primaryAction={{
					label: t('pages.lanes.overview.create', 'Create lane'),
					onClick: () => navigate('./create'),
				}}
				className="m-auto grid gap-2 md:max-w-[850px]"
			>
				<div className="grid gap-3">
					<Suspense
						fallback={
							<>
								<InfoRouteCardSkeleton />
								<InfoRouteCardSkeleton />
								<InfoRouteCardSkeleton />
							</>
						}
					>
						<LanesList />
					</Suspense>
				</div>
			</Page>
		</>
	);
}
