import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useResetPassword } from '@uturn/api/v1';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	cn,
	sonner
} from '@uturn/ui-kit';
import type { AxiosError } from 'axios';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';


const schema = z.object({
	email: z.string().trim().email(),
});

export function PasswordResetRequestForm() {
	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: '',
		},
	});
	const { mutateAsync } = useResetPassword();
	const { t } = useTranslation();

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = async ({ email }) => {
		try {
			await mutateAsync({ data: { email: email.toLowerCase().trim() } });
		} catch (error: AxiosError | any) {
			let description = error.message;
			if ('response' in error) {
				// TODO: Check if there is a better way to filter this response (preferably by error code)
				if (error.response.data.message === 'User not found') {
					return;
				}

				description = error.response?.data?.message;
			}

			form.setError('root', {
				type: 'custom',
				message: description,
			});
			sonner.error(
				description ||
				t('general.errors.generic_title', 'Uh oh! Something went wrong.'));
		}
	};

	// const { formState: { isSubmitting } } = useFormState();

	return (
		<div className={cn('grid gap-6')}>
			{form.formState.isSubmitSuccessful ? (
				<p className="text-sm">
					{t('pages.auth.password_reset_request.request_completed', {
						email: form.getValues('email'),
					})}
				</p>
			) : (
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="grid gap-2">
							<div className="grid gap-1">
								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="sr-only">
												{t('general.email', 'E-mail')}
											</FormLabel>
											<FormControl>
												<Input
													type="email"
													autoComplete="email"
													autoCorrect="off"
													required={true}
													disabled={form.formState.isSubmitting}
													placeholder="john_doe@example.com"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('pages.auth.password-reset.form.submit', 'Reset password')}
							</Button>
						</div>
					</form>
				</Form>
			)}
		</div>
	);
}
