import { FullStory as FS, init as initFullStory } from '@fullstory/browser';
import type { User } from '@uturn/api/v1';

export class FullStory {
	static init() {
		initFullStory({
			orgId: import.meta.env.VITE_FULLSTORY_ORG,
			devMode: import.meta.env.DEV,
		});
	}

	static identify(user: User) {
		FS('setIdentity', {
			uid: user.userId,
			properties: {
				email: user.username,
				displayName: `${user.firstName} ${user.surname}`,
				...user,
			},
		});
	}

	static trackEvent({
		name,
		properties,
		schema,
	}: {
		name: string;
		properties: object;
		schema?: object;
	}) {
		FS('trackEvent', {
			name,
			properties,
			schema,
		});
	}
}
