import type { PropsWithChildren } from 'react';

const Overlay: React.FC<PropsWithChildren> = ({ children, ...props }) => (
	<div
		className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 font-bold text-white"
		{...props}
	>
		{children}
	</div>
);

export default Overlay;
