import { twJoin, twMerge } from 'tailwind-merge';

const Cell = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<span className={twJoin(className, 'py-3 pl-6 pr-3 text-sm text-left')}>
			{children}
		</span>
	);
};

export const ChangeRequestSectionRow = ({
	name,
	prevValue,
	newValue,
}: {
	name: string;
	prevValue: string | [];
	newValue: string | [];
}) => {
	const prevNotEmpty =
		prevValue !== undefined && prevValue !== null && prevValue.length > 0;

	return (
		<div className="grid grid-cols-[25%_30%_30%] items-center border-t">
			<Cell>{name}</Cell>
			<Cell className={twMerge(prevNotEmpty ? 'line-through' : '')}>
				{prevNotEmpty ? prevValue : '-'}
			</Cell>
			<Cell>{newValue}</Cell>
		</div>
	);
};
