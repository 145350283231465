import {
	type InviteCarrierRequest,
	type MyCarriersDTO,
	getMyCarriersList,
	inviteCarrier,
	removeCarrierPartyRelation,
} from '@uturn/api/v1';
import { create } from 'zustand';
import { sortData } from '../utils';

export type CarrierState = {
	carriers: MyCarriersDTO[];
	totalCarriers: number;
	page: number;
	pageLimit: number;
	pageSize: number;
	isLoaded: boolean;
	fetch: (page?: number) => void;
	setPage: (page: number) => void;
	sortData: (key: string, direction: 'asc' | 'desc') => void;
	remove: (carrier: MyCarriersDTO) => void;
	invite: (carrier: InviteCarrierRequest) => Promise<boolean>;
};

export const useCarrierStore = create<CarrierState>((set, get) => ({
	carriers: [],
	totalCarriers: 0,
	page: 0,
	pageLimit: 1,
	pageSize: 60,
	isLoaded: false,
	fetch: async (page?: number) => {
		const currentPage = page || get().page;
		const result = await getMyCarriersList();

		const carriers = result.data || [];

		set({
			carriers,
			totalCarriers: result.data.length,
			page: currentPage,
			isLoaded: true,
		});
	},
	setPage: (page: number) => {
		const { pageLimit, carriers } = get();

		if (page > pageLimit || page < 0) {
			return;
		}

		carriers[page] = carriers[page] || [];

		set({ page, isLoaded: false, carriers });
		get().fetch(page);
	},
	sortData: (key: string, direction: 'asc' | 'desc') => {
		const { carriers } = get();
		const sortedData = sortData(
			carriers as [Record<string, number | string>],
			key,
			direction,
		);

		set({ carriers: sortedData });
	},
	remove: async (carrier: MyCarriersDTO) => {
		try {
			await removeCarrierPartyRelation(carrier);

			const { carriers } = get();

			// TODO: Remove this when the API is fixed
			if (carrier.carrierPartyId === 0) {
				set({
					carriers: carriers.filter(
						(c) => c.inviteRequestId !== carrier.inviteRequestId,
					),
					totalCarriers: carriers.length - 1,
				});
				return;
			}

			set({
				carriers: carriers.filter(
					(c) => c.carrierPartyId !== carrier.carrierPartyId,
				),
				totalCarriers: carriers.length - 1,
			});
		} catch (error) {
			console.error('Error removing the Carrier :', error);
		}
	},
	invite: async (carrier: InviteCarrierRequest) => {
		try {
			await inviteCarrier(carrier);
			return true;
		} catch (error) {
			console.error('Error adding the Carrier :', error);
			return false;
		}
	},
}));
