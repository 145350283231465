import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { ShipmentUnitEnum } from '@uturn/api/v1';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	InputAddon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	ScrollArea,
	cn,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetaDataContext } from '@uturn/portal/context';

export function ContainerForm({ disabled }: { disabled?: boolean }) {
	const { shipmentMetaData, shipmentUnits } = useContext(MetaDataContext);
	const form = useFormContext();
	const { t } = useTranslation();
	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.create.form.container.title', 'Container')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<FormField
					control={form.control}
					name="shipmentUnit"
					disabled={disabled}
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>
								{t('pages.lanes.create.form.container.type.label', 'Type')}*
							</FormLabel>
							<Popover>
								<PopoverTrigger asChild>
									<FormControl>
										<Button
											disabled={field.disabled}
											variant="outline"
											role="combobox"
											className={cn(
												'justify-between',
												!field.value && 'text-muted-foreground',
											)}
										>
											{field.value
												? shipmentUnits[
														Object.keys(ShipmentUnitEnum).find(
															(unit) => unit === field.value,
														)!
													].description
												: t(
														'pages.lanes.create.form.container.type.placeholder',
														'Select container type',
													)}
											<Icon
												icon={faChevronDown}
												className="justify-self-end "
											/>
										</Button>
									</FormControl>
								</PopoverTrigger>
								<PopoverContent className="left-0 p-0">
									<Command className="w-full">
										<CommandInput
											placeholder={t(
												'pages.lanes.create.form.container.type.dropdown.search',
												'Search for a container type',
											).toString()}
										/>
										<ScrollArea className="h-96">
											<CommandEmpty>
												{t(
													'pages.lanes.create.form.container.type.dropdown.no-results',
													'No container types',
												)}
											</CommandEmpty>
											<CommandGroup>
												{Object.keys(ShipmentUnitEnum)
													.filter((unit) => shipmentUnits[unit] !== undefined)
													.map((key) => (
														<CommandItem
															value={key}
															key={key}
															onSelect={() => {
																field.onChange(key);
															}}
														>
															<Icon
																icon={faCheck}
																className={cn(
																	'mr-2 h-4 w-4',
																	key === field.value
																		? 'opacity-100'
																		: 'opacity-0',
																)}
															/>
															{shipmentUnits[key].description}
														</CommandItem>
													))}
											</CommandGroup>
										</ScrollArea>
									</Command>
								</PopoverContent>
							</Popover>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="cargoWeight.quantity"
					disabled={disabled}
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t(
									'pages.lanes.create.form.container.average-cargo-weight',
									'Average cargo weight',
								)}
								*
							</FormLabel>
							<div className="relative">
								<Input type="number" {...field} />
								<InputAddon type="trailing">
									<span className="text-secondary-500 sm:text-sm">
										{t('general.shorthands.units.kilogram', 'kg')}
									</span>
								</InputAddon>
							</div>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="shippingLineId"
					disabled={disabled}
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>
								{t(
									'pages.lanes.create.form.container.shipping-line.label',
									'Shipping line',
								)}
							</FormLabel>
							<Popover>
								<PopoverTrigger asChild>
									<FormControl>
										<Button
											disabled={field.disabled}
											variant="outline"
											role="combobox"
											className={cn(
												'justify-between',
												!field.value && 'text-muted-foreground',
											)}
										>
											{field.value
												? shipmentMetaData!.shippingLines!.find(
														(line) => line.id === field.value,
													)?.description
												: t(
														'pages.lanes.create.form.container.shipping-line.placeholder',
														'Select shipping line',
													)}
											<Icon icon={faChevronDown} className="justify-self-end" />
										</Button>
									</FormControl>
								</PopoverTrigger>
								<PopoverContent className="left-0 p-0">
									<Command
										filter={(value, search) =>
											value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
										}
										className="w-full"
									>
										<CommandInput
											placeholder={t(
												'pages.lanes.create.form.container.shipping-line.dropdown.search',
												'Search for a shipping line',
											).toString()}
										/>
										<ScrollArea className="h-96">
											<CommandEmpty>
												{t(
													'pages.lanes.create.form.container.shipping-line.dropdown.no-results',
													'No shipping line found',
												)}
											</CommandEmpty>
											<CommandGroup>
												{shipmentMetaData?.shippingLines?.map((line) => (
													<CommandItem
														value={`${line.description} ${line.code}`}
														key={line.id}
														onSelect={() => {
															field.onChange(line.id);
														}}
													>
														<Icon
															icon={faCheck}
															className={cn(
																'mr-2 h-4 w-4',
																line.id === field.value
																	? 'opacity-100'
																	: 'opacity-0',
															)}
														/>
														{line.description} ({line.code})
													</CommandItem>
												))}
											</CommandGroup>
										</ScrollArea>
									</Command>
								</PopoverContent>
							</Popover>
							<FormMessage />
						</FormItem>
					)}
				/>
			</CardContent>
		</Card>
	);
}
