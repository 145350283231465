import type { UturnUserRequest } from '@uturn/api/v1';
import { useFetchUturnUserById, useUpdateUserDetails } from '@uturn/api/v1';
import { Avatar } from '@uturn/ui';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	Input,
	Separator,
	sonner,
} from '@uturn/ui-kit';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetaDataContext } from '@uturn/portal/context';

// const userLangCodes = Object.keys(UserLanguageCode).map((key) => ({
// 	label: `${key.charAt(0)}${key.slice(1).toLowerCase()}`,
// 	value: key,
// }));

export function AccountProfile() {
	const { metadata } = useContext(MetaDataContext);
	const { data } = useFetchUturnUserById(metadata!.user?.id as number, {});
	const form = useForm({
		defaultValues: data?.data,
	});
	const { t } = useTranslation();

	const name = form.watch(['firstName', 'lastName']);

	const { mutate } = useUpdateUserDetails({
		mutation: {
			onSuccess: () => {
				sonner.success(
					t(
						'pages.account.profile.form.submit.success',
						'User has been updated!',
					),
				);
			},
			onError: () => {
				sonner.error(t('general.try-again-later', 'Please try again later.'));
			},
		},
	});

	useEffect(() => {
		form.reset(data?.data);
	}, [data, form.reset]);

	return (
		<>
			<Helmet title={t('pages.account.profile.title', 'Account - Profile')!} />
			<div className="space-y-6">
				<div>
					<h3 className="text-lg font-medium">
						{t('pages.account.profile.title', 'Profile')}
					</h3>
					<p className="text-sm text-muted-foreground">
						{t(
							'pages.account.profile.description',
							'This is how others will see you.',
						)}
					</p>
				</div>
				<Separator />
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((formData) => {
							mutate({
								id: metadata!.user?.id as number,
								data: formData satisfies UturnUserRequest,
							});
						})}
						className="space-y-8"
					>
						<div className="col-span-full flex items-center gap-x-8">
							<Avatar
								className="size-24 flex-none rounded-lg bg-gray-800 object-cover"
								name={name.join(' ')}
							/>
						</div>

						<div className="grid grid-flow-col gap-3">
							<FormField
								control={form.control}
								name="firstName"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t('general.firstname', 'First name')}
										</FormLabel>
										<FormControl>
											<Input placeholder="John" {...field} />
										</FormControl>
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="lastName"
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('general.lastname', 'Last name')}</FormLabel>
										<FormControl>
											<Input placeholder="Doe" {...field} />
										</FormControl>
									</FormItem>
								)}
							/>
						</div>

						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('general.email', 'Email')}</FormLabel>
									<FormControl>
										<FormFieldReadOnly value={field.value} />
									</FormControl>
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="phoneNumbers.0.phone.number"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t('general.phone_number', 'Phone number')}
									</FormLabel>
									<FormControl>
										<Input placeholder="0612345678" {...field} />
									</FormControl>
								</FormItem>
							)}
						/>

						<Button type="submit">{t('general.actions.save', 'save')}</Button>
					</form>
				</Form>
			</div>
		</>
	);
}
