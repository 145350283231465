import { z } from '@uturn/portal/zod';

/**
 * Helper function to add a required issue to the context.
 * Where `fieldPath` is the key of the form field to focus on with the 'Required' message.
 *
 * @usage
 * ```ts
 * addIssueRequired(ctx, 'reasonOtherExplanation');
 * ```
 */
export const addIssueRequired = (ctx: z.RefinementCtx, fieldPath: string) => {
	ctx.addIssue({
		code: z.ZodIssueCode.invalid_type,
		expected: z.ZodParsedType.string,
		received: z.ZodParsedType.undefined,
		path: [fieldPath],
	});
};
