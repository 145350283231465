import type { Filter, FilterValues } from '@uturn/api/v1';
import type { MultiSelectOption } from '@uturn/ui';
import {
	AccordionContent,
	AccordionTrigger,
	Checkbox,
	ScrollArea,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';
import { MetaDataContext } from '@uturn/portal/context';

export function RequirementsFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const { metadata } = useContext(MetaDataContext);
	const { t } = useTranslation();
	const requirements = metadata.filterOptions?.find(
		(item: any) => item.id === 'shipmentRequirement',
	) as Filter;
	const options = Object.keys(requirements?.values as FilterValues).map(
		(key) =>
			({
				label: requirements.values![key],
				value: key,
			}) satisfies MultiSelectOption<string>,
	);

	const reqs =
		typeof filters['filter.requirements'] === 'string'
			? [filters['filter.requirements']]
			: (filters['filter.requirements'] ?? []);

	const handleOnChange = (selectedStatus: any, select: boolean) => {
		if (select) {
			setFilters({ 'filter.requirements': [...reqs, selectedStatus] });
		} else {
			setFilters({
				'filter.requirements': reqs.filter((s) => s !== selectedStatus),
			});
		}
	};

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.requirements.title', 'Requirements')}
			</AccordionTrigger>
			<AccordionContent>
				<ScrollArea className="h-36">
					{options.map((s) => (
						<div key={s.value} className="mb-1 flex items-center space-x-2">
							<Checkbox
								checked={reqs.findIndex((value) => value === s.value) !== -1}
								onCheckedChange={(e: boolean) => handleOnChange(s.value, e)}
								id={s.value}
							/>
							<label
								htmlFor={s.value}
								className="cursor-pointer text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							>
								{s.label}
							</label>
						</div>
					))}
				</ScrollArea>
			</AccordionContent>
		</>
	);
}
