import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertToIsoDateWithTimezone(date: Date): string {
	if (!date) return '';

	return dayjs(date).tz().format();
}
