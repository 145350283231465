import type { Coordinates } from '@uturn/ui';
import { Card } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { RouteInformation } from './route-information';
import { GoogleMap } from '@uturn/portal/components/map';
import {
	getDirections,
	usePriceCalculatorStore,
} from '@uturn/portal/store/price-calculator';

export function RouteMap({ locations }: { locations: Coordinates[] }) {
	const { routeInformation } = usePriceCalculatorStore();
	const [route, setRoute] = useState<Coordinates[]>([]);

	useEffect(() => {
		getDirections(locations).then((data) => {
			setRoute(data as any);
		});
	}, [locations, getDirections]);

	return (
		<Card className="sticky top-36 h-[78vh] overflow-hidden ">
			<GoogleMap
				initialLocation={{
					lat: 51.91359903088673,
					lng: 4.439946801361003,
				}}
				locations={locations}
				route={locations.length > 0 ? route : []}
				zoom={7}
			/>
			<RouteInformation data={routeInformation} />
		</Card>
	);
}
