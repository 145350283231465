import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	type InviteUserRequest,
	useInviteUserToOrganization,
} from '@uturn/api/v2';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator,
	sonner,
} from '@uturn/ui-kit';
import { type AxiosError } from 'axios';
import { useRef, useState } from 'react';
import { AllowedTo } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { formInviteUserSchema } from '../schema';
import { Permission } from '@uturn/portal/abac';
import { getApiErrorMessage } from '@uturn/portal/utils';
import { inviteUserRole } from '@uturn/portal/utils/user-role';
import { getRequiredFields } from '@uturn/portal/zod/utils';

const requiredFields = getRequiredFields(formInviteUserSchema);

export default function OrganizationUsersLayout() {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const form = useForm<InviteUserRequest>({
		resolver: zodResolver(formInviteUserSchema),
		defaultValues: {
			role: 'S_USER',
		},
	});

	const formRef = useRef<HTMLFormElement>(null);

	const handleSubmit = () => {
		if (!formRef.current) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const { mutate, isLoading } = useInviteUserToOrganization({
		mutation: {
			onSuccess: () => {
				setOpen(false);
				form.reset();
				sonner.success(
					t(
						'pages.account.organisation.users.submit.success.description',
						'User has been invited!',
					),
				);
			},
			onError: (error: AxiosError | any) => {
				sonner.error(
					<>
						<p>{t('toast.error-title', 'Uh oh! Something went wrong.')}</p>
						<p>{t('general.try-again-later', 'Please try again later.')}</p>
						<p>{getApiErrorMessage(error)}</p>
					</>,
				);
			},
		},
	});

	function onSubmit(data: InviteUserRequest) {
		mutate({ data });
	}

	return (
		<>
			<Helmet
				title={
					t('pages.account.organisation.users.title', 'Organisation - Users')!
				}
			/>
			<div className="space-y-6">
				<div className="flex items-center justify-between space-y-2">
					<div>
						<h3 className="text-lg font-medium">
							{t('pages.account.organisation.users.title', 'Users')}
						</h3>
						<p className="text-sm text-muted-foreground">
							{t(
								'pages.account.organisation.users.description',
								'Manage the users in your organisation.',
							)}
						</p>
					</div>
					<div className="flex items-center space-x-2">
						<Dialog open={open} onOpenChange={setOpen}>
							<AllowedTo
								perform={Permission.INVITE_NEW_USER}
								no={() => (
									<Button
										variant="outline"
										onClick={() =>
											sonner.error(
												t(
													'pages.organisation.users.actions-denied.add.description',
													'Cannot invite new user while your organisation is pending approval by UTURN.',
												),
											)
										}
									>
										{t('pages.organisation.users.actions.add', 'Add new user')}
									</Button>
								)}
							>
								<DialogTrigger asChild>
									<Button>
										{t('pages.organisation.users.actions.add', 'Add new user')}
									</Button>
								</DialogTrigger>
							</AllowedTo>
							<DialogContent className="sm:max-w-[425px]">
								<Form {...form}>
									<form
										onSubmit={form.handleSubmit(onSubmit)}
										ref={formRef}
										className="space-y-3"
									>
										<DialogHeader>
											<DialogTitle>
												{t(
													'pages.account.organisation.users.modal.create.title',
													'Add User',
												)}
											</DialogTitle>
											<DialogDescription>
												{t(
													'pages.account.organisation.users.modal.create.description',
													'Lets grow your organisation by adding a new user.',
												)}
											</DialogDescription>
										</DialogHeader>
										<div className="grid gap-4 py-4">
											<FormField
												control={form.control}
												name="role"
												render={({ field }) => (
													<FormItem>
														<FormLabel
															className={
																requiredFields.role ? 'required-asterix' : ''
															}
														>
															{t('pages.adduser.formlabels.role', 'Role')}
														</FormLabel>
														<Select
															onValueChange={field.onChange}
															defaultValue={field.value}
														>
															<FormControl>
																<SelectTrigger>
																	<SelectValue
																		placeholder={
																			<span>
																				{t('general.select', 'Select')}
																			</span>
																		}
																	/>
																</SelectTrigger>
															</FormControl>
															<SelectContent>
																{Object.entries(inviteUserRole(t)).map(
																	([key, value], index) => (
																		<SelectItem
																			key={`${index}-${key}`}
																			value={key}
																		>
																			{value}
																		</SelectItem>
																	),
																)}
															</SelectContent>
														</Select>
														<FormMessage />
													</FormItem>
												)}
											/>

											<div className="grid grid-flow-col gap-3">
												<FormField
													control={form.control}
													name="firstName"
													render={({ field }) => (
														<FormItem>
															<FormLabel
																className={
																	requiredFields.firstName
																		? 'required-asterix'
																		: ''
																}
															>
																{t(
																	'pages.adduser.formlabels.firstname',
																	'First name',
																)}
															</FormLabel>
															<FormControl>
																<Input placeholder="John" {...field} />
															</FormControl>
															<FormMessage />
														</FormItem>
													)}
												/>
												<FormField
													control={form.control}
													name="lastName"
													render={({ field }) => (
														<FormItem>
															<FormLabel
																className={
																	requiredFields.lastName
																		? 'required-asterix'
																		: ''
																}
															>
																{t(
																	'pages.adduser.formlabels.lastname',
																	'Last name',
																)}
															</FormLabel>
															<FormControl>
																<Input placeholder="Doe" {...field} />
															</FormControl>
															<FormMessage />
														</FormItem>
													)}
												/>
											</div>
											<FormField
												control={form.control}
												name="email"
												render={({ field }) => (
													<FormItem>
														<FormLabel
															className={
																requiredFields.email ? 'required-asterix' : ''
															}
														>
															{t('pages.adduser.formlabels.email', 'Email')}
														</FormLabel>
														<FormControl>
															<Input
																placeholder="john_doe@example.com"
																{...field}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="phoneNumber"
												render={({ field }) => (
													<FormItem>
														<FormLabel
															className={
																requiredFields.phoneNumber
																	? 'required-asterix'
																	: ''
															}
														>
															{t(
																'pages.adduser.formlabels.phone',
																'Phone number',
															)}
														</FormLabel>
														<FormControl>
															<Input
																{...field}
																defaultValue={''}
																placeholder={
																	t('general.phone_example', '+31612345678')!
																}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
										</div>
										<DialogFooter>
											<DialogClose asChild>
												<Button
													disabled={isLoading}
													type="button"
													variant="outline"
												>
													{t('general.cancel', 'Cancel')}
												</Button>
											</DialogClose>
											<Button
												disabled={isLoading}
												onClick={(e) => {
													e.preventDefault();
													handleSubmit();
												}}
											>
												{isLoading ? (
													<>
														<Icon
															className="mr-2 size-4"
															icon={faSpinnerThird}
															spin
														/>
														{t(
															'pages.shipments_cancel.dialog.button.loading',
															'Canceling...',
														)}
													</>
												) : (
													t(
														'pages.account.organisation.users.modal.create.form.submit',
														'Add user',
													)
												)}
											</Button>
										</DialogFooter>
									</form>
								</Form>
							</DialogContent>
						</Dialog>
					</div>
				</div>
				<Separator />
				<Outlet />
			</div>
		</>
	);
}
