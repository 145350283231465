import { Badge } from '@uturn/ui-kit';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CopyToClipboard, Page } from '@uturn/portal/components';
import {
	InfoRouteCard,
	InfoRouteCardContent,
	InfoRouteCardHeader,
	InfoRouteCardLabel,
	InfoRouteCardRoutes,
} from '@uturn/portal/components/info-route-card';
import { formatPrice } from '@uturn/portal/utils';
import { getRouteDate } from '@uturn/portal/utils/get-route-date';

export function SearchResultsOverview() {
	const { refine } = useSearchBox();
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();
	const { results } = useInstantSearch();
	const navigate = useNavigate();

	useEffect(() => {
		const paramQuery = searchParams.get('q');

		if (paramQuery === null && results.query === '') {
			navigate('..');
			return;
		}

		if (paramQuery === null && results.query !== '') {
			setSearchParams({ q: results.query });
		}

		if (paramQuery !== null && results.query !== paramQuery) {
			refine(paramQuery!);
		}
	}, []);

	return (
		<>
			<Helmet
				title={
					t('pages.search.title', '{{amount}} search results for "{{query}}"', {
						amount: results.hits.length,
						query: results.query,
					})!
				}
			/>
			<Page
				title={
					t('pages.search.title', '{{amount}} results for "{{query}}"', {
						amount: results.hits.length,
						query: results.query,
					})!
				}
				className="m-auto md:max-w-[850px]"
			>
				<div className="grid gap-3">
					{results.hits.map((result) => (
						<Link key={result.id} to={`/shipments/${result.shipmentNumber}`}>
							<InfoRouteCard>
								<InfoRouteCardHeader>
									<div className="z-10 flex justify-start gap-3.5 font-light text-muted-foreground">
										<span className="z-10">
											{t('pages.search.card.title', 'Shipment ID - %{{ ID }}', {
												ID: result.shipmentNumber,
											})}
											<CopyToClipboard
												value={result.shipmentNumber.toString()}
											/>
										</span>

										<Badge>
											{t(
												`general.shipments.status.${result.shipmentStatus.toLowerCase()}`,
											)}
										</Badge>
									</div>
								</InfoRouteCardHeader>
								<InfoRouteCardContent
									routes={
										<InfoRouteCardRoutes
											routes={result.locations.map((location: any) => ({
												name: location.name,
												description: (
													<span>
														{getRouteDate(
															location.dateFrom,
															location.dateUntil,
														)}
													</span>
												),
											}))}
											compact={false}
										/>
									}
								>
									{result.invoiceReference && (
										<InfoRouteCardLabel
											title={t('pages.search.card.reference', 'Reference')}
										>
											{result.invoiceReference}
										</InfoRouteCardLabel>
									)}

									{result.targetPrice && (
										<InfoRouteCardLabel
											title={t(
												'pages.search.card.target-price',
												'Target price',
											)}
										>
											{formatPrice(
												result.targetPrice.quantity,
												result.targetPrice.currency,
											)}
										</InfoRouteCardLabel>
									)}

									{result.agreedPrice && (
										<InfoRouteCardLabel
											title={t(
												'pages.search.card.agreed-price',
												'Agreed price',
											)}
										>
											{formatPrice(
												result.agreedPrice.quantity,
												result.targetPrice.currency,
											)}
										</InfoRouteCardLabel>
									)}

									{result.carrierName && (
										<InfoRouteCardLabel
											title={t('pages.search.card.carrier', 'Carrier')}
										>
											{result.carrierName}
										</InfoRouteCardLabel>
									)}
								</InfoRouteCardContent>
							</InfoRouteCard>
						</Link>
					))}
				</div>
			</Page>
		</>
	);
}
