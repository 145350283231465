/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import type { ShipmentLocationActionRequestDtoLocationActionType as LocationActionType } from '@uturn/api/v1';
import type { UpdateShipmentV2 } from '@uturn/api/v2';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type {
	FieldLabels,
	SectionType,
} from '../components/change-request-modal/change-request.types';
import { useLocationTypeTranslation } from '@uturn/portal/modules/shipments/hooks';
import { formatDatetime } from '@uturn/portal/utils';

function getNestedProperty(obj: any, path: string) {
	const keys = path.split('.');
	return keys.reduce(
		(value, key) =>
			value && value[key] !== undefined ? value[key] : undefined,
		obj,
	);
}

const isArray = (obj: any) => {
	return typeof obj === 'object' && obj !== null && Array.isArray(obj);
};

const locationActionField = (path: string, index: number) => {
	return `locations.${index}.action.${path}`;
};

/**
 * When new route location is added, form.formState.dirtyFields
 * also include paths with false values and array paths spreads
 * as in the example below instead "path: true" when dirty.
 *
 * @example
 * ```json
 * {
 *   'requirementIds.0': true,
 *   'requirementIds.1': true,
 *   'requirementIds.2': true,
 * }
 * ```
 */
const isArrayDirty = (
	field: string,
	dirtyFieldRefs: Record<string, boolean>,
) => {
	for (const key in dirtyFieldRefs) {
		if (key.startsWith(field)) {
			// "requirementIds.1": boolean
			//   => if one of the keys is true, then the field is dirty.
			if (dirtyFieldRefs[key]) {
				return true;
			}
		}
	}
	return false;
};

const shipmentFieldsToCheck = (
	t: TFunction<'translation', undefined>,
): FieldLabels => ({
	'equipment.isoType': t(
		'pages.shipment-detail.modal.change-request.labels.equipment-iso-type',
		'ISO Type',
	),
	'grossWeight.quantity': t(
		'pages.shipment-detail.modal.change-request.labels.gross-weight-quantity',
		'Gross weight',
	),
	unCode: t(
		'pages.shipment-detail.modal.change-request.labels.un-code',
		'UN Code',
	),
	generatorSet: t(
		'pages.shipment-detail.modal.change-request.labels.generator-set',
		'Generator Set',
	),
	requirementIds: t(
		'pages.shipment-detail.modal.change-request.labels.requirement-ids',
		'Shipping requirements',
	),
});

const locationActionsFieldsToCheck = (
	t: TFunction<'translation', undefined>,
): FieldLabels => ({
	'location.id': t(
		'pages.shipment-detail.modal.change-request.labels.location-id',
		'Location',
	),
	dateFrom: t(
		'pages.shipment-detail.modal.change-request.labels.location-date-from',
		'Date from',
	),
	dateUntil: t(
		'pages.shipment-detail.modal.change-request.labels.location-date-until',
		'Date until',
	),
});

const newLocationActionsFieldsToCheck = (
	t: TFunction<'translation', undefined>,
): FieldLabels => ({
	'location.id': t(
		'pages.shipment-detail.modal.change-request.labels.location-id',
		'Location',
	),
	dateFrom: t(
		'pages.shipment-detail.modal.change-request.labels.location-date-from',
		'Date from',
	),
	dateUntil: t(
		'pages.shipment-detail.modal.change-request.labels.location-date-until',
		'Date until',
	),
	port: t(
		'pages.shipment-detail.modal.change-request.labels.location-port',
		'Port',
	),
	remarks: t(
		'pages.shipment-detail.modal.change-request.labels.location-remarks',
		'Remarks',
	),
	shippingLineId: t(
		'pages.shipment-detail.modal.change-request.labels.location-shipping-line-id',
		'Shipping line',
	),
	vesselName: t(
		'pages.shipment-detail.modal.change-request.labels.location-vessel-name',
		'Vessel name',
	),
});

const fallbackLocationActionsFieldsToCheck = (
	t: TFunction<'translation', undefined>,
): FieldLabels => ({
	'location.googlePlaceId': t(
		'pages.shipment-detail.modal.change-request.labels.location-id',
		'Location',
	),
});

export function useDetermineChangeRequest() {
	const { t } = useTranslation();
	const locationTypeTranslation = useLocationTypeTranslation();
	const determineChangeRequest = ({
		oldValues,
		newValues,
		oldLocationActions,
		newLocationActions,
		dirtyFieldRefs,
	}: {
		oldValues: Partial<UpdateShipmentV2>;
		newValues: Partial<UpdateShipmentV2>;
		oldLocationActions: any[];
		newLocationActions: any[];
		dirtyFieldRefs: Record<string, boolean>;
	}) => {
		// Shipment Information
		const shipmentInformationChanges: SectionType = {
			name: 'Shipment Information',
			prevValues: [],
			newValues: [],
		};
		for (const [fieldPath, fieldName] of Object.entries(
			shipmentFieldsToCheck(t),
		)) {
			let oldValue = getNestedProperty(oldValues, fieldPath);
			let newValue = getNestedProperty(newValues, fieldPath);
			let isDiff = false;
			if (fieldPath in dirtyFieldRefs && dirtyFieldRefs[fieldPath]) {
				if (isArray(oldValue) && isArray(newValue)) {
					const delValue = oldValue.filter((item) => !newValue.includes(item));
					const addValue = newValue.filter((item) => !oldValue.includes(item));
					oldValue = delValue;
					newValue = addValue;
				}
				// TODO: at times, unCode is included in dirtyFieldRefs,
				//       even though oldValue and newValue are undefined.
				isDiff = String(oldValue) !== String(newValue);
			} else if (
				isArray(oldValue) &&
				isArray(newValue) &&
				isArrayDirty(fieldPath, dirtyFieldRefs)
			) {
				isDiff = true;
				const delValue = oldValue.filter((item) => !newValue.includes(item));
				const addValue = newValue.filter((item) => !oldValue.includes(item));
				oldValue = delValue;
				newValue = addValue;
			}
			if (isDiff) {
				shipmentInformationChanges.prevValues.push({
					name: fieldName,
					path: fieldPath,
					value: oldValue,
				});
				shipmentInformationChanges.newValues.push({
					name: fieldName,
					path: fieldPath,
					value: newValue,
				});
			}
		}

		// Routes Information
		const locationActionsChanges: SectionType[] = [];
		for (const newLocationAction of newLocationActions) {
			const { id, locationActionType } = newLocationAction;
			const name = `${
				locationTypeTranslation[locationActionType as LocationActionType]
			} location`;
			const locationActionChanges: SectionType = {
				name,
				prevValues: [],
				newValues: [],
			};
			const indexOfOldLocationActions = oldLocationActions?.findIndex(
				(item) => String(item.id) === String(id),
			);
			const isNewRoute = indexOfOldLocationActions === -1;
			if (isNewRoute) {
				for (let [fieldPath, fieldName] of Object.entries(
					newLocationActionsFieldsToCheck(t),
				)) {
					const oldValue = undefined;
					let newValue = getNestedProperty(newLocationAction, fieldPath);
					/**
					 * Fallback to googlePlaceId if location.id is not set.
					 */
					if (fieldPath === 'location.id' && !newValue) {
						fieldPath = 'location.googlePlaceId';
						fieldName = fallbackLocationActionsFieldsToCheck(t)[fieldPath];
						newValue = getNestedProperty(newLocationAction, fieldPath);
					}
					if (newValue) {
						locationActionChanges.prevValues.push({
							name: fieldName,
							path: fieldPath,
							value: oldValue,
						});
						locationActionChanges.newValues.push({
							name: fieldName,
							path: fieldPath,
							value: newValue,
						});
					}
				}
			} else {
				const oldLocationAction = oldLocationActions[indexOfOldLocationActions];
				for (let [fieldPath, fieldName] of Object.entries(
					locationActionsFieldsToCheck(t),
				)) {
					let oldValue = getNestedProperty(oldLocationAction, fieldPath);
					let newValue = getNestedProperty(newLocationAction, fieldPath);
					/**
					 * Fallback to googlePlaceId if location.id is not set.
					 */
					if (fieldPath === 'location.id' && !newValue) {
						fieldPath = 'location.googlePlaceId';
						fieldName = fallbackLocationActionsFieldsToCheck(t)[fieldPath];
						oldValue = getNestedProperty(oldLocationAction, fieldPath);
						newValue = getNestedProperty(newLocationAction, fieldPath);
					}
					const fieldRef =
						fieldPath === 'location.id' ||
						fieldPath === 'location.googlePlaceId'
							? locationActionField('location', indexOfOldLocationActions)
							: locationActionField(fieldPath, indexOfOldLocationActions);
					let isDiff = false;
					if (fieldRef in dirtyFieldRefs && dirtyFieldRefs[fieldRef]) {
						/**
						 * Hookform's dirty property is reliable up to a point.
						 * When adding route locations, all routes below the
						 * added routes become dirty automatically 😡.
						 * Therefore, double-checking afterwards is still necessary.
						 */
						if (fieldPath.startsWith('date')) {
							isDiff =
								(formatDatetime(oldValue) ?? '') !==
								(formatDatetime(newValue) ?? '');
						} else {
							isDiff = String(oldValue) !== String(newValue);
						}
					}
					if (isDiff) {
						locationActionChanges.prevValues.push({
							name: fieldName,
							path: fieldPath,
							value: oldValue,
						});
						locationActionChanges.newValues.push({
							name: fieldName,
							path: fieldPath,
							value: newValue,
						});
					}
				}
			}
			if (
				locationActionChanges.prevValues.length > 0 ||
				locationActionChanges.newValues.length > 0
			) {
				locationActionsChanges.push(locationActionChanges);
			}
		}

		// Return
		if (
			shipmentInformationChanges.prevValues.length === 0 &&
			shipmentInformationChanges.newValues.length === 0
		) {
			return [...locationActionsChanges];
		}
		return [shipmentInformationChanges, ...locationActionsChanges];
	};
	return { determineChangeRequest };
}
