import type { AddressLookupResponse } from '@uturn/api/v1';
import { lookUpShippingLocation } from '@uturn/api/v1';
import { useState } from 'react';

export function useShipmentLocationSuggestions() {
	const [locations, setLocations] = useState<AddressLookupResponse[]>([]);

	return {
		locations,
		getLocations: (text: string) => {
			lookUpShippingLocation({ searchText: text }).then((data) => {
				setLocations(data.data);
			});
		},
	};
}
