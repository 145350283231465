import {
	type SubscriptionDto,
	type TierDTO,
	useCreateSubscription,
	useFetchAllOrganizationSubscriptions,
	useGetPriceCalculatorTiers,
} from '@uturn/api/v1';
import { Card, Dialog, DialogContent, sonner } from '@uturn/ui-kit';
import type { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MetaDataContext } from '@uturn/portal/context';
import {
	PriceCalculatorSubscriptionConfirmDialog,
	PricingTiers,
} from '@uturn/portal/modules/price-calculator/components';
import { getApiErrorMessage } from '@uturn/portal/utils';

// Calculate the number of days until the next reset.
function getDaysUntilNextReset(startDate: string | undefined) {
	const today = new Date();
	const startingDate = startDate ? new Date(startDate) : new Date(today);

	if (startingDate.getDate() > today.getDate()) {
		return startingDate.getDate() - today.getDate();
	}

	const nextResetDate = new Date(
		today.getMonth() === 11 ? today.getFullYear() + 1 : today.getFullYear(),
		(today.getMonth() + 1) % 12,
		startingDate.getDate(),
	);

	return Math.floor(
		(nextResetDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24),
	);
}

// The active subscription logic is based on useGetSubscriptionByProduct
// that seems to be inclusive of the endDate.
function isSubscriptionActive(subscription: SubscriptionDto) {
	if (subscription.status !== 'SUBSCRIBED') {
		return false;
	}
	if (!subscription.endDate) {
		return true;
	}
	const endDate = new Date(subscription.endDate);
	const endOfToday = new Date();
	endOfToday.setHours(23, 59, 59, 999);
	const subscribed = endDate.getTime() > endOfToday.getTime();
	return subscribed;
}

function CurrentSubscriptionCard({
	subscription,
}: {
	subscription?: SubscriptionDto;
}) {
	const { t } = useTranslation();

	if (!subscription) {
		return null;
	}

	let remainingRequests: React.ReactNode = '';
	switch (subscription.remainingRequests) {
		case -1:
			remainingRequests = t(
				'pages.subscriptions.current-subscription.unlimited-requests',
				'This period you have unlimited requests left.',
			);
			break;
		case 0:
			remainingRequests = t(
				'pages.subscriptions.current-subscription.no-requests',
				'This period you have no requests left, to get more upgrade or wait until the renewal.',
			);
			break;
		default:
			remainingRequests = (
				<>
					{t(
						'pages.subscriptions.current-subscription.remaining-requests-prefix',
						'This period you have',
					)}{' '}
					<strong>
						{t(
							'pages.subscriptions.current-subscription.remaining-requests-suffix',
							'{{ remainingRequests }} requests left',
							{
								remainingRequests: subscription.remainingRequests,
							},
						)}
					</strong>
					{'.'}
				</>
			);
			break;
	}

	const tierName = t(
		`pages.subscriptions.tier.name.${subscription.tierName.toLowerCase()}`,
		subscription.tierName,
	);

	return (
		<Card className="p-8">
			<h3 className="text-lg font-semibold leading-8">
				{t(
					'pages.subscriptions.current-subscription.title',
					'Current subscription',
				)}
			</h3>
			<p className="mt-4 text-sm leading-6">
				{t(
					'pages.subscriptions.current-subscription.current-tier',
					'You are currently on the {{ tierName }} tier',
					{ tierName },
				)}
				<br />
				{remainingRequests}
				<br />
				{t(
					'pages.subscriptions.current-subscription.days-until-reset-prefix',
					'Your requests will reset in',
				)}{' '}
				<strong>
					{t(
						'pages.subscriptions.current-subscription.days-until-reset-suffix',
						'{{ daysUntilReset }} days',
						{
							daysUntilReset: getDaysUntilNextReset(subscription.startDate),
						},
					)}
				</strong>
				{'.'}
			</p>
		</Card>
	);
}

export function PriceCalculatorSubscriptionsOverview() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { metadata } = useContext(MetaDataContext);

	const { data: priceCalculatorTiers } = useGetPriceCalculatorTiers();
	const { data: orgSubscriptions } = useFetchAllOrganizationSubscriptions(
		metadata?.user?.organisation?.id as number,
	);

	const { mutate, isLoading } = useCreateSubscription();

	const [openModal, setOpenModal] = useState(false);
	const [tier, setTier] = useState<TierDTO>();

	const onSubscribe = (tierId: number) => {
		mutate(
			{
				data: {
					productId: 1,
					tierId,
				},
			},
			{
				onSuccess: () => {
					sonner.success(
						t(
							'pages.subscriptions.submit.success.title',
							'Subscribed successfully',
						),
						{
							description: t(
								'pages.subscriptions.submit.success.description',
								'You have successfully subscribed to the price calculator',
							),
						},
					);
					navigate('/calculator', { replace: true });
				},
				onError: (error: AxiosError | any) => {
					sonner.error(getApiErrorMessage(error));
					navigate('/calculator/subscriptions', { replace: true });
				},
			},
		);
	};

	const onSelect = (selectedTier: TierDTO) => {
		setTier(selectedTier);
		setOpenModal(true);
	};

	const activeSubscription = orgSubscriptions?.data.subscriptions
		.filter((item) => isSubscriptionActive(item))
		.at(-1);

	return (
		<>
			<Helmet title={t('pages.subscriptions.title', 'Subscriptions')!} />
			<div className="mx-auto mb-16 mt-8 grid max-w-7xl gap-3 px-6 pb-20 sm:mb-32 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h1 className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
						{t('pages.subscriptions.title', 'Price calculator plans')}
					</h1>
				</div>
				<p className="leading- mx-auto mt-6 max-w-3xl text-center text-lg">
					{t(
						'pages.subscriptions.description',
						"Choosing the right subscription model can be a daunting task. We're here to help you make the best decision that aligns with your needs and preferences We will provide you with a clear overview of our subscription options, along with some tips to help you make the right choice.",
					)}
				</p>
				<CurrentSubscriptionCard subscription={activeSubscription} />
				<PricingTiers
					subscription={activeSubscription}
					tiers={priceCalculatorTiers?.data}
					onSelect={onSelect}
				/>
			</div>
			<Dialog open={openModal} onOpenChange={setOpenModal}>
				<DialogContent className="flex w-[25rem] flex-col gap-8 text-left md:w-[40rem] lg:w-[50rem]">
					<PriceCalculatorSubscriptionConfirmDialog
						tier={tier!}
						isSubscribing={isLoading}
						onSubscribe={onSubscribe}
						onClose={() => setOpenModal(false)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}
