import { type AddressLookupResponse } from '@uturn/api/v1';

export const formatSuggestion = (item: AddressLookupResponse) => {
	return `${item.name ?? ''} ${item.street ?? ''} ${item.city ?? ''}`;
};

export const formatSuggestions = (data: AddressLookupResponse[]) => {
	const formatedSuggestions: string[] = [];

	data.forEach((item) => {
		const formattedSuggestion = formatSuggestion(item);
		formatedSuggestions.push(formattedSuggestion);
	});

	return formatedSuggestions;
};
