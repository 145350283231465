import { useFetchLane } from '@uturn/api/v1';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContainerDetails } from '../components/details/container-details';
import { LaneDetailContext } from '../components/details/detail-context';
import { RouteDetails } from '../components/details/route-details';
import { ShipmentDetails } from '../components/details/shipment-details';
import { LoadingSpan, Page } from '@uturn/portal/components';

function LaneDetailsForm({ laneId }: { laneId: number }) {
	const { data } = useFetchLane(laneId);
	const navigate = useNavigate();

	if (!data) {
		navigate('..');
		return null;
	}

	return (
		<LaneDetailContext.Provider value={data.data}>
			<Helmet title={data.data.reference} />
			<ShipmentDetails />
			<ContainerDetails />
			<RouteDetails />
		</LaneDetailContext.Provider>
	);
}

export function LaneDetails() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	if (!id || Number.isNaN(Number(id))) {
		navigate('..');
	}

	return (
		<>
			<Helmet title={t('pages.lanes.details.title', 'Lane details')!} />
			<Page
				backButton={{
					label: t('pages.lanes.back', 'Back'),
					href: '..',
				}}
				title={t('pages.lanes.details.title', 'Lane details')!}
				className="m-auto md:max-w-[550px]"
			>
				<Suspense fallback={<LoadingSpan />}>
					<LaneDetailsForm laneId={Number(id)} />
				</Suspense>
			</Page>
		</>
	);
}
