import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { type QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCancelPendingChangeSet } from '@uturn/api/v1';

import {
	Button,
	Callout,
	CalloutIcon,
	CalloutTitle,
	Icon,
	sonner,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmRevokePendingChangesModal } from '../confirm-revoke-pending-changes-modal';

export function ChangeRequestBanner({
	shipmentNumber,
	shipmentQueryKey,
	pendingChangeSetQueryKey,
}: {
	shipmentNumber: string;
	shipmentQueryKey: QueryKey;
	pendingChangeSetQueryKey: QueryKey;
}) {
	const { mutate } = useCancelPendingChangeSet();
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	const queryClient = useQueryClient();

	const handleRevokeChangeRequest = () => {
		mutate(
			{
				shipmentNumber: Number(shipmentNumber),
			},
			{
				onSuccess: () => {
					setOpenModal(false);

					queryClient.invalidateQueries(shipmentQueryKey);
					queryClient.invalidateQueries(pendingChangeSetQueryKey);
					sonner.success(
						t(
							'pages.shipment-detail.banner.change-request.submit.success.description',
							'Change request revoked successfully',
						),
					);
				},
				onError: () => {
					setOpenModal(false);
					sonner.error(
						t(
							'pages.shipment-detail.banner.change-request.submit.error.description',
							'Error revoking change request',
						),
					);
				},
			},
		);
	};

	return (
		<>
			<Callout>
				<CalloutIcon>
					<Icon icon={faInfoCircle} />
				</CalloutIcon>
				<CalloutTitle>
					{t(
						'pages.shipment-detail.banner.change-request.description',
						'Awaiting response from the carrier on your update request. You can view the change request in the Event log. To undo your changes, click the Revoke button.',
					)}
				</CalloutTitle>

				<Button
					size="sm"
					className="flex items-center gap-3"
					onClick={(e) => {
						e.preventDefault();
						setOpenModal(true);
					}}
				>
					{t('pages.shipment-detail.banner.change-request.revoke', 'Revoke')}
				</Button>
			</Callout>
			<ConfirmRevokePendingChangesModal
				handleRevokeChangeRequest={handleRevokeChangeRequest}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</>
	);
}
