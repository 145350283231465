import type { PhoneDto } from '@uturn/api/v2';
import parsePhoneNumber from 'libphonenumber-js';
import { addIssueCustom } from '../utils';
import { z } from '@uturn/portal/zod';

export const phoneNumberSchema = z.string().transform((value, ctx) => {
	if (!value) {
		return undefined;
	}

	let input = value;

	/**
	 * Transform 00 numbers into + numbers.
	 */
	if (input.startsWith('00')) {
		input = input.replace(/^0{2}/, '+');
	}

	/**
	 * Check if input starts with leading country calling code.
	 */
	if (!input.match(/^\+\d+/)) {
		addIssueCustom(ctx, 'phone_e164_invalid');
		return z.NEVER;
	}

	/**
	 * Parse phone number and determine country code from leading country calling code
	 * during validation.
	 */
	const parser = parsePhoneNumber(input, {
		defaultCountry: 'NL',
	});

	if (!parser || !parser.isValid()) {
		addIssueCustom(ctx, 'phone_invalid');
		return z.NEVER;
	}

	const phone: PhoneDto = {
		countryCode: parser.country!,
		number: parser.number!,
	};

	return phone;
});
