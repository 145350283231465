/**
 * Recursively remove any empty fields from an object.
 */
export function removeEmptyDataFields<T>(data: T) {
	const dataCopy = { ...data };
	for (const key in dataCopy) {
		const value = dataCopy[key as keyof typeof data];
		if (typeof value === 'object') {
			// array
			if (value instanceof Array) {
				if (value.length === 0) {
					// // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					// delete dataCopy[key as keyof typeof data];
				} else {
					dataCopy[key as keyof typeof data] = value.map((item: any) => {
						if (typeof item === 'object') {
							return removeEmptyDataFields(item);
						}
						return item;
					});
				}
			}
			// object
			else {
				dataCopy[key as keyof typeof data] = removeEmptyDataFields(value);
			}
		} else if (value === null || value === '' || value === undefined) {
			delete dataCopy[key as keyof typeof data];
		}
	}
	return dataCopy;
}
