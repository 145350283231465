import type { ShipmentDtoTsoStatus } from '@uturn/api/v2';
import type { TFunction } from 'i18next';

export const shipmentStatusTsoName = (
	t: TFunction<'translation', undefined>,
): Record<ShipmentDtoTsoStatus, string> => ({
	OPEN: t('general.shipments.tso-status.open', 'Open'),
	IN_PROGRESS: t('general.shipments.tso-status.in-progress', 'In progress'),
	EXECUTED: t('general.shipments.tso-status.executed', 'Executed'),
	CANCELLED: t('general.shipments.tso-status.cancelled', 'Cancelled'),
	INVOICED: t('general.shipments.tso-status.invoiced', 'Invoiced'),
	COST_DECLARED: t(
		'general.shipments.tso-status.cost-declared',
		'Cost declared',
	),
});
