import type { ShipmentRequirementDTO } from '@uturn/api/v1';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeRequestSectionRow } from './change-request-section-row';
import type { FieldChange, SectionType } from './change-request.types';
import { MetaDataContext } from '@uturn/portal/context';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import { renderLocation } from '@uturn/portal/modules/shipments/utils';
import {
	formatDatetime,
	outputDatetimeFormatOptions,
} from '@uturn/portal/utils';

const useGetFormatedLocation = () => {
	const { t } = useTranslation();

	const getFormatedLocation = ({
		id,
		locations,
	}: {
		id: number;
		locations: any;
	}) => {
		if (id) {
			const location = locations.find((item: any) => item.location.id === id);
			if (location?.location) {
				return renderLocation(location.location);
			}
		}
		return t(
			'pages.shipment-detail.modal.change-request.no-location.selected',
			'No location selected',
		);
	};

	return {
		getFormatedLocation,
	};
};

const getFormatedGooglePlace = ({
	id,
	locations,
}: {
	id: string;
	locations: any;
}) => {
	const { t } = useTranslation();
	if (id) {
		const location = locations.find(
			(item: any) => item.location.googlePlaceId === id,
		);
		if (location?.location) {
			return renderLocation(location.location);
		}
	}
	return t(
		'pages.shipment-detail.modal.change-request.no-location.selected',
		'No location selected',
	);
};

const getFormatedCheckbox = (value: boolean | undefined) => {
	if (value === undefined) {
		return '-';
	}
	return value ? 'Yes' : 'No';
};

const getFormatedRequirements = (
	requirementIds: number[],
	requirements: ShipmentRequirementDTO[],
): string => {
	const results = requirementIds.map((id) => {
		const requirement = requirements.filter((item) => item.id === id);
		if (!requirement.length) {
			return String(id); // TODO: `Not found (${id})`; ?
		}
		const { code, description } = requirement[0] ?? {};
		return description ?? code;
	});
	return results.toString();
};

export const ChangeRequestSection = ({
	name,
	prevValues,
	newValues,
}: SectionType) => {
	const { getFormatedLocation } = useGetFormatedLocation();
	const { t } = useTranslation();
	const { shipmentMetaData } = useContext(MetaDataContext);
	const { changeRequestLocations } = useContext(ShipmentDetailContext);

	const determineToFormatValue = (value: FieldChange, type: 'prev' | 'new') => {
		if (value.path === 'location.id') {
			const locations =
				type === 'prev'
					? changeRequestLocations.currentLocations
					: changeRequestLocations.newLocations;
			return getFormatedLocation({
				id: value.value,
				locations,
			});
		}
		if (value.path === 'location.googlePlaceId') {
			const locations =
				type === 'prev'
					? changeRequestLocations.currentLocations
					: changeRequestLocations.newLocations;
			return getFormatedGooglePlace({
				id: value.value,
				locations,
			});
		}
		if (value.path === 'generatorSet') {
			return getFormatedCheckbox(value.value);
		}
		if (value.path === 'requirementIds') {
			return getFormatedRequirements(
				value.value ?? [],
				shipmentMetaData?.shipmentRequirements ?? [],
			);
		}
		if (value.path === 'dateUntil' || value.path === 'dateFrom') {
			return formatDatetime(value.value, outputDatetimeFormatOptions);
		}
		return value.value;
	};

	return (
		<div className="overflow-hidden rounded-lg border bg-card text-card-foreground shadow-sm">
			<div className="flex items-center justify-between px-6 py-3">
				<h3>
					<span className="text font-semibold leading-none tracking-tight">
						{name}
					</span>
					<span className="ml-1.5 text-xs text-muted-foreground">
						{t(
							'pages.shipment-detail.modal.change-request.amount-of-changes',
							'({{ amount }} changes)',
							{
								amount: newValues.length,
							},
						)}
					</span>
				</h3>
			</div>
			{prevValues.map((prevValue, index) => {
				const newValueIndex = newValues.findIndex(
					(item) => item.path === prevValue.path,
				);
				const newValueFound = newValueIndex !== -1;
				if (newValueFound) {
					const newValue = newValues[newValueIndex];
					return (
						<ChangeRequestSectionRow
							key={index}
							name={prevValue.name}
							prevValue={determineToFormatValue(prevValue, 'prev')!}
							newValue={determineToFormatValue(newValue, 'new')!}
						/>
					);
				}
				return null;
			})}
		</div>
	);
};
