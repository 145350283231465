import { faBars } from '@fortawesome/pro-regular-svg-icons';
import {
	Button,
	Icon,
	ModeToggle,
	ScrollArea,
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from '@uturn/ui-kit';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import LanguageSelector from '@uturn/portal/components/language-selector';

type NavItems = { name: string; href: string }[];

export function Desktop({ items }: { items: NavItems }) {
	return (
		<nav className="hidden items-center gap-1.5 md:flex">
			{items.map((item) => (
				<NavLink
					key={item.name}
					to={item.href}
					className={({ isActive }) =>
						twMerge(
							'text-accent-foreground text-sm py-2 px-3 rounded-md font-medium transition-colors relative',
							isActive
								? 'bg-accent'
								: 'bg-accent/0 opacity-60 hover:bg-accent/100 hover:opacity-100',
						)
					}
				>
					{item.name}
				</NavLink>
			))}
		</nav>
	);
}

export function Mobile({ items }: { items: NavItems }) {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	return (
		<div className="md:hidden">
			<Sheet open={open} onOpenChange={setOpen}>
				<SheetTrigger asChild>
					<Button variant="outline" size="icon">
						<Icon icon={faBars} />
					</Button>
				</SheetTrigger>
				<SheetContent side="bottom">
					<SheetHeader>
						<SheetTitle className="flex items-center justify-between">
							<h2>{t('header.navigation.mobile.title', 'Go to')}</h2>
							<div className="mr-4 flex items-center gap-2">
								<ModeToggle />
								<LanguageSelector />
							</div>
						</SheetTitle>
					</SheetHeader>
					<ScrollArea className="size-full">
						<nav className="mx-6 mt-8 flex flex-col items-center gap-4">
							{items.map((item) => (
								<NavLink
									key={item.name}
									to={item.href}
									onClick={() => setOpen(false)}
									className={({ isActive }) =>
										twMerge(
											'w-full text-accent-foreground py-2 px-3 rounded-md font-medium transition-colors relative',
											isActive
												? 'bg-accent'
												: 'bg-accent/0 opacity-60 hover:bg-accent/100 hover:opacity-100',
										)
									}
								>
									{item.name}
								</NavLink>
							))}
						</nav>
					</ScrollArea>
				</SheetContent>
			</Sheet>
		</div>
	);
}

export function Navigation({ navItems }: { navItems: NavItems }) {
	return (
		<>
			<Desktop items={navItems} />
			<Mobile items={navItems} />
		</>
	);
}
