import { P } from '@uturn/ui';
import { Separator } from '@uturn/ui-kit';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { OrganizationSubscriptionsTable } from '../components/subscriptions-table';

export function OrganizationSubscriptions() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.subscriptions.title',
						'Organisation - Subscriptions',
					)!
				}
			/>
			<Suspense
				fallback={
					<P>
						{t(
							'pages.account.organisation.subscriptions.loading',
							'Loading subscriptions...',
						)}
					</P>
				}
			>
				<div className="space-y-6">
					<div>
						<h3 className="text-lg font-medium">
							{t(
								'pages.account.organisation.subscriptions.title',
								'Subscriptions',
							)}
						</h3>
						<p className="text-sm text-muted-foreground">
							{t(
								'pages.account.organisation.subscriptions.description',
								'An overview of all your active subscriptions.',
							)}
						</p>
					</div>
					<Separator />
					<OrganizationSubscriptionsTable />
				</div>
			</Suspense>
		</>
	);
}
