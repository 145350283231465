import {
	ContainerStatus,
	TransportTypeCode,
	TypeOfShipment,
} from '@uturn/portal/types/shipment';
import { z } from '@uturn/portal/zod';

/**
 * Exclude all form.onSubmit
 */

/**
 * currently only handled by useAddShipmentStore.setManualCreateInit
 * used in parent page './add.tsx'.
 *
 * (from useAddShipmentStore)
 */
export const transportTypeSchema = z.nativeEnum(TransportTypeCode);

/**
 * currently only handled by useAddShipmentStore.setManualCreateInit
 * used in parent page './add.tsx'.
 *
 * (from useAddShipmentStore)
 */
export const typeOfShipmentSchema = z.nativeEnum(TypeOfShipment);

/**
 * containerType <= containerType.code
 *
 * (from container section)
 */
export const containerTypeSchema = z.string();

/**
 * containerStatus: z.nativeEnum(OrganizationSettingsDtoContainerStatus, {
 *   required_error: 'Container status is required',
 * }),
 *
 * (from container section)
 */
export const containerStatusSchema = z.nativeEnum(ContainerStatus);

/**
 * invoiceRequired <= orgDefaultValues?.invoiceRequired in form.defaultValues
 *
 * required if requiresInvoiceReference
 *
 * (from publish-detail section)
 */
export const invoiceRequiredSchema = z.boolean().default(false);
