import { type QueryKey } from '@tanstack/react-query';
import {
	type FileRequest,
	type ShipmentRequirementDTO,
	type ShipmentUnitDTO,
	type ShippingLineDTO,
} from '@uturn/api/v1';
import {
	type Carrier,
	type ShipmentDtoShipmentStatus,
	type ShipmentDtoTsoStatus,
} from '@uturn/api/v2';
import { Card, CardContent, CardHeader, CardTitle } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalInformation } from './additional-information';
import { CargoDetails } from './cargo-details';
import { CarrierInformation } from './carrier-information';
import { ContainerDetails } from './container-details';
import { ShipmentGroup } from './shipment-group';
import { ShipmentStatusInfo } from './shipment-status-info';
import { ShippingRequirements } from './shipping-requirements';
import type { CarrierReview, Document } from '@uturn/portal/types/shipment';

// TODO: once api has been refactored, we have to provide the correct types instead of any.
type ShipmentDetailsSectionProps = {
	containerTypes: ShipmentUnitDTO[];
	shippingLines: ShippingLineDTO[];
	shipmentRequirements: ShipmentRequirementDTO[];
	setFileRequests?: (fileRequests: FileRequest[]) => void;
	setFiles?: (files: File[]) => void;
	shipmentNumber?: number;
	carrier?: Carrier;
	carrierReview?: CarrierReview;
	shipmentStatus?: ShipmentDtoShipmentStatus;
	tsoStatus?: ShipmentDtoTsoStatus;
	initDocuments?: any[];
	setNewFiles?: (files: File[], fileRequests: FileRequest[]) => void;
	documentQueryKey?: QueryKey;
	enableAddingGroup?: boolean;
};

export function ShipmentDetailsSection({
	containerTypes,
	shippingLines,
	shipmentRequirements,
	shipmentNumber,
	carrier,
	carrierReview,
	shipmentStatus,
	tsoStatus,
	initDocuments,
	setNewFiles,
	documentQueryKey,
	enableAddingGroup,
}: ShipmentDetailsSectionProps) {
	const { t } = useTranslation();
	const [documents, setDocuments] = useState<Document[]>([]);

	useEffect(() => {
		if (!setNewFiles) return;
		const fileRequests: FileRequest[] = [];
		const files: File[] = [];

		documents.forEach((document) => {
			fileRequests.push(document.fileRequest as FileRequest);
			files.push(document.file);
		});

		setNewFiles(files, fileRequests);
	}, [documents]);

	return (
		<div className="relative h-full">
			<Card>
				<CardHeader>
					<CardTitle>
						{t(
							'pages.create_shipment.shipment-details.heading',
							'Shipment information',
						)}
					</CardTitle>
				</CardHeader>
				<CardContent className="flex flex-col gap-7">
					<div
						className={`flex flex-col gap-4 ${
							!shipmentStatus && !carrier && !enableAddingGroup ? 'hidden' : ''
						}`}
					>
						{enableAddingGroup && <ShipmentGroup />}
						{shipmentStatus && (
							<ShipmentStatusInfo
								shipmentStatus={shipmentStatus}
								orderStatus={tsoStatus}
							/>
						)}

						{carrier && (
							<CarrierInformation
								shipmentNumber={shipmentNumber}
								carrier={carrier}
								carrierReview={carrierReview}
							/>
						)}
					</div>

					<ContainerDetails
						containerTypesRaw={containerTypes}
						shippingLinesRaw={shippingLines}
						enableAddingGroup={enableAddingGroup ?? false}
					/>

					<CargoDetails />

					<ShippingRequirements requirements={shipmentRequirements} />

					<AdditionalInformation
						initDocuments={initDocuments!}
						setDocuments={setDocuments}
						documentQueryKey={documentQueryKey}
					/>
				</CardContent>
			</Card>
		</div>
	);
}
