import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			suspense: true,
		},
	},
});

export function ApiProvider(props: PropsWithChildren) {
	return <QueryClientProvider client={queryClient} {...props} />;
}
