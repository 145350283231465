/* eslint-disable no-restricted-syntax */
import type { FieldErrors } from 'react-hook-form';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';

type FlattenedObject = { [key: string]: any };

export const flattenObject = (
	data: any,
	parentKey: string = '',
	separator: string = '.',
): FlattenedObject => {
	const flattened: FlattenedObject = {};
	const obj = { ...data };
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
			if (
				typeof obj[key] === 'object' &&
				obj[key] !== null &&
				!Array.isArray(obj[key])
			) {
				Object.assign(flattened, flattenObject(obj[key], newKey, separator));
			} else if (
				typeof obj[key] === 'object' &&
				obj[key] !== null &&
				Array.isArray(obj[key])
			) {
				obj[key].forEach((value: any, index: number) => {
					if (typeof value === 'object' && value !== null) {
						Object.assign(
							flattened,
							flattenObject(value, `${newKey}.${index}`, separator),
						);
					} else if (value !== null) {
						flattened[`${newKey}.${index}`] = value;
					}
				});
			} else {
				flattened[newKey] = obj[key];
			}
		}
	}
	return flattened;
};

const getRefs = (data: FlattenedObject) => {
	const refs: (keyof FormValues)[] = [];
	for (const key in data) {
		if (key.match(/\.ref\.name/)) {
			refs.push(data[key]);
		}
	}
	return refs;
};

export const getFirstErrorFieldRef = (
	errors: FieldErrors<FormValues>,
): keyof FormValues | undefined => {
	const refs = getRefs(flattenObject(errors));
	if (refs.length > 0) {
		return refs[0];
	}
	return undefined;
};
