import type { RouteDetails } from '@uturn/api/v1';
import { useFetchPriceCalculatorRequest } from '@uturn/api/v1';
import { Card } from '@uturn/ui-kit';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PriceIndicationResult } from './price-indication-result';
import { PriceRequestDetails } from './price-request-details';
import { PriceCalculatorContext } from '@uturn/portal/modules/price-calculator/context/price-calculator-context';
import { usePriceCalculatorStore } from '@uturn/portal/store/price-calculator';

export function PriceCalculatorResultById() {
	const { setLocations } = useContext(PriceCalculatorContext);
	const { setRouteInformation, resetRouteInformation } =
		usePriceCalculatorStore();
	const navigate = useNavigate();
	const { id } = useParams();

	if (id === undefined) {
		navigate('/price-calculator');
	}

	const { data } = useFetchPriceCalculatorRequest(id!);

	useEffect(() => {
		if (!data?.data) {
			resetRouteInformation();
			return;
		}
		const priceResponse = data.data;
		setLocations(priceResponse.locations);
		setRouteInformation({
			co2Emission: priceResponse.co2Emission,
			distance: priceResponse.distance,
			duration: priceResponse.duration,
		});
	}, [data, setRouteInformation]);

	return (
		<>
			<motion.div
				initial={{ opacity: 0, translateY: 15 }}
				animate={{ opacity: 1, translateY: 0 }}
				exit={{
					opacity: 0,
					translateY: 15,
					transition: { delay: 0.3, ease: 'easeInOut' },
				}}
				transition={{ duration: 0.3 }}
			>
				<Card key="locked">
					{/* TODO: Fix this crap too */}
					<PriceRequestDetails
						locations={
							data?.data.locations.map((location) => location.name) ?? []
						}
						details={{
							additionalRequirement: data?.data.additionalRequirement!,
							containerSize: data?.data.containerSize!,
							email: '',
							transportType: data?.data.transportType!,
							locations: [],
						}}
					/>
				</Card>
			</motion.div>
			<motion.div
				initial={{ opacity: 0, translateY: 10 }}
				animate={{
					opacity: 1,
					translateY: 0,
					transition: {
						delay: 0.2,
						duration: 0.3,
						ease: 'easeInOut',
					},
				}}
				exit={{
					opacity: 0,
					translateY: 10,
					transition: { delay: 0, ease: 'easeInOut' },
				}}
			>
				<Card>
					<PriceIndicationResult
						result={{
							averagePrice: data?.data.averagePrice!,
							marketTrend: data?.data.marketTrend!,
						}}
					/>
				</Card>
			</motion.div>
		</>
	);
}
