import { ShipmentDtoShipmentStatus, ShipmentDtoTsoStatus } from '@uturn/api/v2';

const carrierAssignedLockedFields = [
	'price.quantity',
	'dutiesPaid',
	'conditions',
	'fixedPrice',
	'publishUntilDate',
	'preferredCarrierIds',
];

const quotedLockedFields = [
	'containerType',
	'generatorSet',
	'conditions',
	'routeSection',
	'locationActions.customerName',
	'locationActions.dateUntil',
	'locationActions.dateFrom',
	'locationActions.location',
	'locationActions.shippingLineId',
	'equipment.isoType',
	'requirementIds',
	'containerStatus',
	'grossWeight.quantity',
	'generatorSet',
	'conditions',
	'unCode',
	'shippingLine',
];

const executedLockedFields = [
	'containerType',
	'equipment.containerNumber',
	'equipment.isoType',
	'grossWeight.quantity',
	'unCode',
	'generatorSet',
	'seal',
	'details',
	'shippingLine',
	'requirementIds',
	'cargoDescription',
	'containerStatus',
	'routeSection',
	'locationActions.dateUntil',
	'locationActions.dateFrom',
	'locationActions.location',
	'locationActions.shippingLineId',
	'locationActions.remarks',
	'locationActions.reference',
	'locationActions.vesselName',
	'locationActions.portOfOrigin',
	'locationActions.customerName',
];

const canceledCompletedLockedFields = ['shipperReference'];

export function getReadOnlyFieldsList(
	shipmentStatus: ShipmentDtoShipmentStatus,
	shipmentTsoStatus: ShipmentDtoTsoStatus,
	numberOfShipmentInvoicedLines: number,
) {
	if (shipmentStatus === ShipmentDtoShipmentStatus.QUOTED) {
		return [...quotedLockedFields];
	}

	if (shipmentStatus === ShipmentDtoShipmentStatus.CARRIER_ASSIGNED) {
		return [...carrierAssignedLockedFields];
	}

	if (shipmentStatus === ShipmentDtoShipmentStatus.EXECUTED) {
		return [...carrierAssignedLockedFields, ...executedLockedFields];
	}

	if (
		(shipmentStatus === ShipmentDtoShipmentStatus.CANCELLED ||
			shipmentStatus === ShipmentDtoShipmentStatus.COMPLETED) &&
		shipmentTsoStatus !== ShipmentDtoTsoStatus.CANCELLED &&
		shipmentTsoStatus !== undefined &&
		numberOfShipmentInvoicedLines < 1
	) {
		return [...carrierAssignedLockedFields, ...executedLockedFields];
	}

	if (
		shipmentStatus === ShipmentDtoShipmentStatus.CANCELLED ||
		shipmentStatus === ShipmentDtoShipmentStatus.COMPLETED
	) {
		return [
			...carrierAssignedLockedFields,
			...executedLockedFields,
			...canceledCompletedLockedFields,
		];
	}

	return [];
}

export function isFieldReadOnly(readOnlyFields: string[], field: string) {
	return !!readOnlyFields.includes(field);
}
