import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

interface ShipmentGroupDetailContextType {
	groupId: string;
	groupName: string;
	setGroupId: (groupId: string) => void;
	setGroupName: (groupName: string) => void;
}

export const ShipmentGroupDetailContext =
	createContext<ShipmentGroupDetailContextType>({
		groupId: '',
		groupName: '',
		setGroupId: () => {},
		setGroupName: () => {},
	});

export const ShipmentGroupDetailProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [groupId, setGroupId] = useState('');
	const [groupName, setGroupName] = useState('');

	const value = useMemo(
		() => ({
			groupId,
			groupName,
			setGroupId,
			setGroupName,
		}),
		[groupId, groupName, setGroupId, setGroupName],
	);

	return (
		<ShipmentGroupDetailContext.Provider value={value}>
			{children}
		</ShipmentGroupDetailContext.Provider>
	);
};
