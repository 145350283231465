import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import type { AddressLookupResponse, ShippingLineDTO } from '@uturn/api/v1';
import {
	ShipmentLocationActionRequestDtoLocationActionType as LocationActionType,
	getPlaceDetailsByPlaceId,
} from '@uturn/api/v1';
import { Button, DateTimePicker, Icon } from '@uturn/ui';
import {
	AutoComplete,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Textarea,
} from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { AllowedTo } from 'react-abac';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarrierExecutionDetails } from './carrier-execution-details';
import {
	LocationPortbaseStatus,
	LocationPortbaseStatusDenied,
} from './location-portbase-status';
import { ShipInformation } from './ship-information';
import { ShipmentLocationInfo } from './shipment-location-info';
import { Permission } from '@uturn/portal/abac';
import { HiddenFieldLabelInfo } from '@uturn/portal/modules/shipments/components/hidden-field-label-info';
import { useLocationTypeTranslation } from '@uturn/portal/modules/shipments/hooks';
import type {
	FormValues,
	ZodLocationActionLocation,
} from '@uturn/portal/modules/shipments/schema';
import {
	isFieldReadOnly,
	renderLocation,
	shouldRevalidate,
	shouldUpdate,
} from '@uturn/portal/modules/shipments/utils';
import { formatDatetime } from '@uturn/portal/utils';
import { convertToIsoDateWithTimezone } from '@uturn/portal/utils/convert-to-iso-date-with-timezone';

export type ShipmentLocationProps = {
	/**
	 * The id of the locationAction.
	 *
	 * The FE handles the id as a string during FE
	 * CRUD operations until it saved in the BE.
	 * Its type could be described as:
	 * ```ts
	 *   id: '1' | '2' | '3' | crypto.randomUUID()
	 * ```
	 *
	 * However, the BE saves and provides this id as a number.
	 *
	 * @note
	 * This id (or locationAction.id) and the locationAction.location.id
	 * are different.
	 */
	id: string | number;
	indexInLocations?: number;
	isReadOnly?: boolean;
	enablePortbase?: boolean;
	readOnlyFields?: string[];
	shippingLines: ShippingLineDTO[];
	autocompleteSuggestions: string[];
	autoCompleteData: AddressLookupResponse[];
	setAutocompleteValue: (value: string) => void;
	removeLocation: () => void;
};

export function ShipmentLocation({
	id,
	indexInLocations,
	isReadOnly = false,
	enablePortbase,
	readOnlyFields = [],
	shippingLines,
	autocompleteSuggestions,
	autoCompleteData,
	setAutocompleteValue,
	removeLocation,
}: ShipmentLocationProps) {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();
	const locationTypeTranslation = useLocationTypeTranslation();

	const [suggestions, setSuggestion] = useState<any>([]);
	const [additionalLocationData, setAdditionalLocationData] =
		useState<AddressLookupResponse | null>(null);

	const fieldRef = (name: string) => {
		return `locations.${indexInLocations}.${name}` as keyof FormValues; // as const;
	};

	const watchLocationType = form.watch(fieldRef('action.locationActionType'));
	const watchDateFrom = form.watch(fieldRef('action.dateFrom'));

	const isPickUpOrDropLocation =
		watchLocationType &&
		(watchLocationType === LocationActionType.PICKUP ||
			watchLocationType === LocationActionType.DROP);

	const addLocationToState = async (index: number) => {
		const locationData = autoCompleteData[index];

		const {
			source,
			id: locationId,
			address,
			name,
			placeId,
			rating,
			country,
		} = locationData;

		setAdditionalLocationData(rating ? locationData : null);

		let location = {} as ZodLocationActionLocation;
		let countryCode = form.getValues(fieldRef('countryCode'));

		if (source === 'GOOGLE') {
			const { data: place } = await getPlaceDetailsByPlaceId({
				placeId: placeId ?? '',
			});
			countryCode = place.country?.countryCode ?? '';
			const { postalCode, city, houseNumber, latitude, longitude, street } =
				place.address ?? {};
			location = {
				id: place.id,
				googlePlaceId: placeId,
				name: place.name,
				address: {
					countryCode,
					postalCode,
					city,
					houseNumber,
					latitude,
					longitude,
					street,
				},
			};
		} else {
			countryCode = country?.countryCode ?? '';
			const { postalCode, city, houseNumber, latitude, longitude, street } =
				address ?? {};
			location = {
				id: locationId,
				// googlePlaceId: placeId,
				name,
				address: {
					countryCode,
					postalCode,
					city,
					houseNumber,
					latitude,
					longitude,
					street,
				},
			};
		}

		form.setValue(fieldRef('action.location'), location, shouldRevalidate);
		form.setValue(fieldRef('countryCode'), countryCode, shouldRevalidate);
	};

	const clearLocationInState = () => {
		setAdditionalLocationData(null);

		form.setValue(fieldRef('action.location'), undefined, shouldUpdate);
		form.setValue(fieldRef('countryCode'), '', shouldUpdate);
	};

	useEffect(() => {
		if (!autocompleteSuggestions) return;
		const newSuggestions = autocompleteSuggestions.map(
			(suggestion: string) => ({
				label: suggestion,
				value: suggestion,
			}),
		);
		setSuggestion(newSuggestions);
	}, [autocompleteSuggestions]);

	return (
		<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
			<div className="flex items-center justify-between">
				<h6 className="font-bold first-letter:uppercase">
					{t(
						'components.shipment_location.heading',
						'{{locationType}} location',
						{
							locationType:
								locationTypeTranslation[
									watchLocationType as LocationActionType
								],
						},
					)}
				</h6>
				{!isPickUpOrDropLocation && !isReadOnly && (
					<Button variant="transparent" onPress={removeLocation}>
						<Icon icon={faTrash} className="text-red-600" />
					</Button>
				)}
			</div>
			<div className="grid grid-cols-1 gap-5 sm:grid-cols-6">
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name={fieldRef('action.location')}
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t(
										'components.shipment_location.type',
										'{{locationType}} location',
										{
											locationType:
												locationTypeTranslation[
													watchLocationType as LocationActionType
												],
										},
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'locationActions.location') ? (
									<FormFieldReadOnly
										value={renderLocation(field.value) ?? '-'}
									/>
								) : (
									<FormControl>
										<AutoComplete
											refCallback={field.ref}
											value={renderLocation(field.value) ?? ''}
											options={suggestions}
											emptyMessage={t(
												'components.shipment_location.no-result',
												'No location found',
											)}
											debouncerDelay={1000}
											onInputChange={(e) => {
												if (e === '') clearLocationInState();
												setAutocompleteValue(e);
											}}
											setSelected={(index: number) => addLocationToState(index)}
											forceUniqueLabelsEnabled={true}
											placeholder={
												t(
													'components.shipment_location.placeholder',
													'Search for a name or address',
												)!
											}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				{additionalLocationData !== null && (
					<ShipmentLocationInfo data={additionalLocationData} />
				)}
				{!isPickUpOrDropLocation && (
					<div className="sm:col-span-full">
						<FormField
							control={form.control}
							name={fieldRef('action.customerName')}
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'components.shipment_location.customer_name',
											'Customer name',
										)}
									</FormLabel>
									{isFieldReadOnly(
										readOnlyFields,
										'locationActions.customerName',
									) ? (
										<FormFieldReadOnly value={field.value ?? ''} />
									) : (
										<FormControl>
											<Input
												{...field}
												value={field.value ?? ''}
												onChange={(e) => field.onChange(e.target.value)}
											/>
										</FormControl>
									)}
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
				)}
				<div className="sm:col-span-3">
					<FormField
						control={form.control}
						name={fieldRef('action.dateFrom')}
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t(
										'components.shipment_location.date.from',
										'{{locationType}} from',
										{
											locationType:
												locationTypeTranslation[
													watchLocationType as LocationActionType
												],
										},
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'locationActions.dateFrom') ? (
									<FormFieldReadOnly
										value={formatDatetime(field.value) ?? '-'}
									/>
								) : (
									<FormControl>
										<DateTimePicker
											refCallback={field.ref}
											defaultValue={field.value}
											dateFormat="dd-MM-yyyy HH:mm"
											minDate={new Date()}
											timeCaption={t('general.time', 'Time')!}
											timeIntervals={10}
											showTimeSelect
											onChange={(date) =>
												field.onChange(convertToIsoDateWithTimezone(date))
											}
											customInput={<Input />}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-3">
					<FormField
						control={form.control}
						name={fieldRef('action.dateUntil')}
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t(
										'components.shipment_location.date.until',
										'{{locationType}} until',
										{
											locationType:
												locationTypeTranslation[
													watchLocationType as LocationActionType
												],
										},
									)}
								</FormLabel>
								{isFieldReadOnly(
									readOnlyFields,
									'locationActions.dateUntil',
								) ? (
									<FormFieldReadOnly
										value={formatDatetime(field.value) ?? '-'}
									/>
								) : (
									<FormControl>
										<DateTimePicker
											refCallback={field.ref}
											defaultValue={field.value}
											dateFormat="dd-MM-yyyy HH:mm"
											minDate={new Date(watchDateFrom as string)}
											timeCaption={t('general.time', 'Time')!}
											timeIntervals={10}
											showTimeSelect
											onChange={(date) =>
												field.onChange(convertToIsoDateWithTimezone(date))
											}
											customInput={<Input />}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name={fieldRef('action.reference')}
						render={({ field }) => (
							<FormItem>
								<FormLabel className="flex items-center">
									{t('general.reference', 'Reference')}
									<HiddenFieldLabelInfo />
								</FormLabel>
								{isFieldReadOnly(
									readOnlyFields,
									'locationActions.reference',
								) ? (
									<FormFieldReadOnly value={field.value ?? '-'} />
								) : (
									<FormControl>
										<Input
											{...field}
											value={field.value ?? ''}
											onChange={(e) => field.onChange(e.target.value)}
											maxLength={50}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name={fieldRef('action.remarks')}
						render={({ field }) => (
							<FormItem>
								<FormLabel>{t('general.remarks', 'Remarks')}</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'locationActions.remarks') ? (
									<FormFieldReadOnly value={field.value ?? '-'} />
								) : (
									<FormControl>
										<Textarea
											{...field}
											value={field.value ?? ''}
											onChange={(e) => field.onChange(e.target.value)}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				{isPickUpOrDropLocation && (
					<ShipInformation
						readOnlyFields={readOnlyFields}
						shippingLines={shippingLines}
						indexInLocations={indexInLocations}
					/>
				)}
				{enablePortbase && (
					<AllowedTo
						perform={Permission.PORTBASE_API}
						no={() => <LocationPortbaseStatusDenied />}
					>
						<LocationPortbaseStatus locationActionId={Number(id)} />
					</AllowedTo>
				)}
				<CarrierExecutionDetails indexInLocations={indexInLocations} />
			</div>
		</div>
	);
}
