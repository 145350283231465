import type { ColumnDef } from '@tanstack/react-table';
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SubscriptionDto } from '@uturn/api/v1';
import { useFetchAllOrganizationSubscriptions } from '@uturn/api/v1';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@uturn/ui-kit';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDate, formatPrice } from '@uturn/portal/utils';

const columns = (
	t: TFunction<'translation', undefined>,
): ColumnDef<SubscriptionDto>[] => [
	{
		header: t('pages.organisation.subscriptions.product', 'Product')!,
		accessorKey: 'product.name',
	},
	{
		header: t('pages.organisation.subscriptions.tier', 'Tier')!,
		accessorKey: 'tierName',
	},
	{
		header: t('pages.organisation.subscriptions.price', 'Price')!,
		accessorFn: (row) =>
			formatPrice(row.price.quantity, row.price.currencyCode),
	},
	{
		header: t('pages.organisation.subscriptions.start_date', 'Start date')!,
		accessorFn: (row) => formatDate(row.startDate!) ?? '-',
	},
	{
		header: t('pages.organisation.subscriptions.end_date', 'End date')!,
		accessorFn: (row) => formatDate(row.endDate) ?? '-',
	},
];

export function OrganizationSubscriptionsTable() {
	const { orgId } = useParams();
	const { t } = useTranslation();

	const { data } = useFetchAllOrganizationSubscriptions(Number(orgId));

	const table = useReactTable({
		data: data?.data.subscriptions.filter(
			(subscription) => subscription.status === 'SUBSCRIBED',
		) as SubscriptionDto[],
		columns: columns(t),
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="rounded-md border">
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columns(t).length}
								className="h-24 text-center"
							>
								{t('general.no-results', 'No results.')}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	);
}
