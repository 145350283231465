import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';
import type { TFunction } from 'i18next';
import { UnpublishOptions } from './shipment-cancel.types';
import type { Modal, UnpublishOption } from './shipment-cancel.types';

export const modals: Record<ShipmentStatus, Modal> = {
	[ShipmentStatus.CONCEPT]: 'archive-shipment',
	[ShipmentStatus.PUBLISHED]: 'unpublish',
	[ShipmentStatus.QUOTED]: 'unpublish',
	[ShipmentStatus.CARRIER_ASSIGNED]: 'cancel-shipment',
	[ShipmentStatus.COST_DECLARED]: '-',
	[ShipmentStatus.CANCELLED]: '-',
	[ShipmentStatus.COMPLETED]: '-',
	[ShipmentStatus.EXECUTED]: '-',
	[ShipmentStatus.UNKNOWN]: '-',
} as const;

export const unpublishOptions = (
	t: TFunction<'translation', undefined>,
): UnpublishOption[] => [
	{
		value: UnpublishOptions.UNPUBLISH,
		heading: t(
			'pages.shipment-detail.modal.unpublish.options.unpublish.title',
			'Unpublish shipment',
		),
		body: t(
			'pages.shipment-detail.modal.unpublish.options.unpublish.description',
			'All quotes will be lost and status will be set to draft. When you republish the shipment, carriers that quoted before will be notified.',
		),
		recommended: true,
	},
	{
		value: UnpublishOptions.CANCEL,
		heading: t(
			'pages.shipment-detail.modal.unpublish.options.cancel.title',
			'Cancel shipment',
		),
		body: t(
			'pages.shipment-detail.modal.unpublish.options.cancel.description',
			'This cannot be undone and the shipment will be canceled permanently.',
		),
	},
];
