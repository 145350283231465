import { UserRole as Role } from '@uturn/api/v1';
import type { Rules } from 'react-abac';

export type Permission = (typeof Permission)[keyof typeof Permission];

export const Permission = {
	PUBLISH_SHIPMENT: 'PUBLISH_SHIPMENT',
	INVITE_CARRIER: 'INVITE_CARRIER',
	INVITE_NEW_USER: 'INVITE_NEW_USER',
	/**
	 * [Portbase](https://www.portbase.com/en/services/):
	 * Real-time insight into the customs status of your
	 * incoming cargo at shortsea and ferry terminals.
	 */
	PORTBASE_API: 'PORTBASE_API',
	/**
	 * [4shipping B.V.](https://www.4shipping.com/):
	 * Multimodal transport solutions make a significant
	 * contribution to reducing CO₂ emissions with more
	 * sustainable method of container transport.
	 */
	FOUR_SHIPPING_API: 'FOUR_SHIPPING_API',
	METABASE_API: 'METABASE_API',
} as const;

const basePermissions = {
	[Permission.PUBLISH_SHIPMENT]: true,
	[Permission.INVITE_CARRIER]: true,
	[Permission.INVITE_NEW_USER]: true,
	[Permission.PORTBASE_API]: true,
	[Permission.FOUR_SHIPPING_API]: true,
};

export const rules: Rules<Role, Permission, boolean> = {
	[Role.G_CARRIER]: { ...basePermissions },
	[Role.G_SHIPPER]: {},
	[Role.C_USER]: { ...basePermissions },
	[Role.C_ADMIN]: { ...basePermissions },
	[Role.S_ADMIN]: {
		...basePermissions,
		[Permission.METABASE_API]: true,
	},
	[Role.S_USER]: { ...basePermissions },
	[Role.U_ADMIN]: { ...basePermissions },
	[Role.C_DRIVER]: { ...basePermissions },
};
