import type { EventHistoryDTO } from '@uturn/api/v1';

export enum EventType {
	'Accepted',
	'ApproveCost',
	'AssignDriver',
	'Assigned',
	'Cancelled',
	'Cancelquote',
	'Completed',
	'Contractdeclined',
	'Created',
	'DeclineCost',
	'ETAUpdate',
	'Executed',
	'FinancialUpdate',
	'IssueReported',
	'Newquote',
	'PBerror',
	'Published',
	'RemovePortbase',
	'Started',
	'Stopped',
	'UpdateAccepted',
	'InviteAccepted',
	'UpdateContainer',
	'Updated',
	'UpdatedCost',
	'UpdateDeclined',
	'InviteRejected',
	'UpdateRequested',
	'InviteRequested',
	'UturnNote',
	'CarrierNote',
	'ShipperNote',
	'Withdrawn',
	'InviteCancelled',
	'Exported',
	'ApprovedCancellation',
	'InvoiceUpdate',
	'CostUpdate',
	'UserRegistered',
	'UserUpdated',
	'OrgRegistered',
	'OrgUpdated',
	'ContractUpdated',
	'Arrived',
	'Departed',
	'UpdateRequestCancelled',
	'UturnOrgNote',
	'UturnUserNote',
	'DocumentRegistered',
	'DocumentRemoved',
	'ShipperMessage',
	'CarrierMessage',
	'OrgDocumentRegistered',
	'OrgDocumentRemoved',
	'ReleasePinCodeGenerated',
	'ReleaseTransferred',
	'ReleaseRetrieved',
	'ReleaseRevoked',
	'DeclinedQuote',
	'SharedShipment',
	'UserInvited',
	'UserInviteAccepted',
	'UserInviteDeclined',
}

export type EventsFeedProps = {
	events: EventGroup[];
	months: string[];
	isLoading?: boolean;
	loadNextPage?: () => void;
};

export type EventGroup = {
	date: Date;
	events: EventHistoryDTO[];
};
