import { faNetworkWired } from '@fortawesome/pro-regular-svg-icons';
import type { CommandPaletteAction } from '@uturn/ui';
import { useDevTools } from '../devtools';
import { loginProfiles } from './login-profiles';

const actions: CommandPaletteAction[] = [
	...loginProfiles(),
	{
		icon: faNetworkWired,
		name: 'Toggle React Query Dev Tools',
		onAction: () => {
			useDevTools.getState().toggleReactQueryDevTools();
		},
	},
];

export const devActions = import.meta.env.DEV ? actions : [];
