import type { z } from '@uturn/portal/zod';

export const RequiredField = 'required';

/**
 * Get required fields from a Zod Schema.
 *
 * @param schema Zod Schema
 * @returns Required fields key-value pairs where key is the field name and value is true or false.
 *
 * @usage
 * Modify any form zod schema and add the following `.describe(RequiredField)` to any mandatory fields,
 * as follows:
 * ```ts
 *   email: z.string().email().describe(RequiredField)
 * ```
 * Then call the function as follows:
 * ```ts
 * const requiredField = getRequiredFields(formInviteUserSchema);
 * ```
 *
 * @example
 * formInviteUserSchema in 'apps/portal/src/modules/org/schema/invite-user.ts'.
 */
export const getRequiredFields = (
	schema: z.ZodObject<any>,
): Record<string, boolean> => {
	const requiredFields: Record<string, boolean> = {};
	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(schema.shape)) {
		// eslint-disable-next-line no-underscore-dangle
		const { description } = (value as any)._def;
		requiredFields[key] =
			(description && description === RequiredField) ?? false;
	}
	return requiredFields;
};
