import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
	ConditionDTOType,
	useGetSubscriptionByProduct,
	useSavePriceRequest,
} from '@uturn/api/v1';
import type {
	AddressDTO,
	ConditionKey,
	PartySubscriptionConditionDto,
	PriceRequestDto,
	PriceResponseDto,
} from '@uturn/api/v1';
import { SystemNotificationContext } from '@uturn/ui';
import { sonner } from '@uturn/ui-kit';
import type { AxiosError } from 'axios';
import { AnimatePresence } from 'framer-motion';
import type { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '@uturn/portal/components';
import { MetaDataContext } from '@uturn/portal/context';
import {
	PriceCalculatorResult,
	PriceCalculatorResultById,
	PriceRequestForm,
	RemainingRequestsBanner,
	RouteMap,
	additionalRequirementOptions,
} from '@uturn/portal/modules/price-calculator/components';
import { PriceCalculatorContext } from '@uturn/portal/modules/price-calculator/context/price-calculator-context';
import { defaultValues } from '@uturn/portal/modules/price-calculator/schema';
import { usePriceCalculatorStore } from '@uturn/portal/store/price-calculator';
import { getApiErrorMessage } from '@uturn/portal/utils';

const getEnabledFeatures = (
	t: TFunction<'translation', undefined>,
	conditions: PartySubscriptionConditionDto[] | undefined,
): ConditionKey[] => {
	if (!conditions) {
		return [];
	}

	const enabledFeatures =
		conditions
			.filter(
				(item) =>
					item.type === ConditionDTOType.BOOLEAN && item.value === 'true',
			)
			.map((item) => item.key) ?? [];

	if (!enabledFeatures.length) {
		return [];
	}

	return additionalRequirementOptions(t)
		.filter(
			(item) =>
				item.conditionKey && enabledFeatures.includes(item.conditionKey!),
		)
		.map((item) => item.conditionKey!);
};

export function ProtectedPriceCalculator() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const { metadata } = useContext(MetaDataContext);
	const { handleSystemNotification } = useContext(SystemNotificationContext);

	const { resetRouteInformation } = usePriceCalculatorStore();

	const savePriceRequest = useSavePriceRequest();
	const { data: partySubscription } = useGetSubscriptionByProduct(1, {
		query: { cacheTime: 0 },
	});

	const [priceRequest, setPriceRequest] =
		useState<PriceRequestDto>(defaultValues);
	const [priceResponse, setPriceResponse] = useState<PriceResponseDto | null>(
		null,
	);
	const [locations, setLocations] = useState<AddressDTO[]>([]);
	const [remainingRequests, setRemainingRequests] = useState<number>(
		partySubscription?.data.activeSubscription?.remainingRequests ?? 0,
	);

	useEffect(() => {
		resetRouteInformation();
	}, []);

	useEffect(() => {
		if (id === undefined) {
			setPriceResponse(null);
		}
	}, [id]);

	useEffect(() => {
		if (remainingRequests === 0 && priceResponse === null && id === undefined) {
			console.log('tech-197 ProtectedPriceCalculator', {
				source: 'useEffect[remainingRequests]',
				remainingRequests,
				priceResponse,
				id,
			});
			navigate('/calculator/subscriptions');
		}
	}, [remainingRequests]);

	const onSubmit = (request: any) => {
		const email = metadata?.user?.username ?? '';
		const submitData = {
			...request,
			locations: locations.map((location: any) => ({
				id: location.id,
				...(location.googlePlaceId && {
					googlePlaceId: location.googlePlaceId,
				}),
			})),
			email,
		};

		setPriceRequest(submitData);

		savePriceRequest.mutate(
			{ data: submitData },
			{
				onSuccess: (response) => {
					setPriceResponse(response.data);
					// Only set remaining if it's not unlimited
					if (remainingRequests !== -1) {
						setRemainingRequests(remainingRequests - 1);
					}
					navigate(`/calculator/${response.data.requestId}`, { replace: true });
				},
				onError: (error: AxiosError | any) => {
					const status = error.response?.data?.status ?? error.status;
					const message = getApiErrorMessage(error);
					if (status === 500) {
						sonner.error(message);
						return;
					}
					if (status === 400 || status === 404) {
						// TODO: Replace this as it doesn't do anything atm.
						handleSystemNotification({
							color: 'danger',
							title: t(
								'general.error-message',
								'Something went wrong, {{ errorMessage }}',
								{ errorMessage: message },
							),
							icon: faXmark,
						});
						sonner.error(message);
						return;
					}
					sonner.error(
						t(
							'pages.price-calculator.form.hint.error.title',
							'Subscription issue?',
						),
						{
							description: message,
						},
					);
					// TODO: Should we really navigate to subscriptions here?
					navigate('/calculator/subscriptions', { replace: true });
				},
			},
		);
	};

	const onRequestReset = () => {
		if (remainingRequests === 0) {
			navigate('/calculator/subscriptions', { replace: true });
		} else {
			navigate('/calculator', { replace: true });
		}
		setPriceResponse(null);
		setPriceRequest(defaultValues);
		setLocations([]);
	};

	return (
		<>
			<Helmet title={t('pages.price-calculator.title', 'Price Calculator')!} />
			<Page title={t('pages.price-calculator.title', 'Price Calculator')!}>
				<div className="relative grid gap-8 md:grid-cols-[minmax(0,3fr),_minmax(0,2fr)]">
					<PriceCalculatorContext.Provider
						value={{
							locations,
							setLocations: (newLocations) => setLocations(newLocations),
						}}
					>
						<div className="flex w-full flex-col gap-4">
							{remainingRequests !== -1 && (
								<RemainingRequestsBanner
									subscription={partySubscription!.data}
									remainingRequests={remainingRequests}
								/>
							)}
							<AnimatePresence mode="wait">
								{priceResponse === null && id === undefined && (
									<PriceRequestForm
										additionalRequirementEnabledFeatures={getEnabledFeatures(
											t,
											partySubscription?.data.activeSubscription?.conditions,
										)}
										defaultValues={priceRequest}
										onSubmit={onSubmit}
										onTripChange={setLocations}
									/>
								)}

								{priceResponse !== null && id !== undefined && (
									<PriceCalculatorResult
										locations={locations}
										priceRequest={priceRequest}
										priceResponse={priceResponse}
										onRequestEdit={() => {
											if (remainingRequests === 0) {
												navigate('/calculator/subscriptions', {
													replace: true,
												});
											} else {
												navigate(`/calculator`, { replace: true });
											}
											setPriceRequest((request) => {
												return {
													...request,
													locations,
												};
											});
											setPriceResponse(null);
										}}
										onRequestReset={onRequestReset}
									/>
								)}

								{priceResponse === null && id !== undefined && (
									<PriceCalculatorResultById />
								)}
							</AnimatePresence>
						</div>
					</PriceCalculatorContext.Provider>
					<div className="relative hidden md:block">
						<RouteMap
							locations={locations.map((location) => ({
								lat: location.latitude!,
								lng: location.longitude!,
							}))}
						/>
					</div>
				</div>
			</Page>
		</>
	);
}
