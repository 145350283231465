import { Grid } from '@uturn/ui';
import { Skeleton } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { Page } from '@uturn/portal/components';

export function ShipmentActionBarSkeletonLoader() {
	return (
		<div className="my-auto flex h-full items-center justify-end">
			<div className="flex flex-row gap-2">
				<Skeleton className="h-10 w-36 rounded-xl" />
				<Skeleton className="h-10 w-72 rounded-xl" />
			</div>
		</div>
	);
}

export function ShipmentDetailsSectionSkeletonLoader() {
	return (
		<div className="relative h-full">
			<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
				<Skeleton className="h-[400px] w-full rounded-xl" />
			</div>
		</div>
	);
}

export function RouteSectionSkeletonLoader() {
	return (
		<div className="relative h-full">
			<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
				<Skeleton className="h-[200px] w-full rounded-xl" />
			</div>
			<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
				<Skeleton className="h-[200px] w-full rounded-xl" />
			</div>
			<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
				<Skeleton className="h-[200px] w-full rounded-xl" />
			</div>
		</div>
	);
}

export function PublishDetailsSectionSkeletonLoader() {
	return (
		<div className="relative h-full">
			<div className="bg-secondary-100/40 border-secondary-200/60 flex flex-col gap-4 rounded-lg border p-6">
				<Skeleton className="h-[400px] w-full rounded-xl" />
			</div>
		</div>
	);
}

export function ButtonPreviousSkeletonLoader() {
	return <Skeleton className="flex h-10 w-28 items-center gap-1" />;
}

export function ButtonNextSkeletonLoader() {
	return <Skeleton className="flex h-10 w-20 items-center gap-1" />;
}

export function ButtonEventsSkeletonLoader() {
	return <Skeleton className="flex h-10 w-20 items-center gap-2" />;
}

export function ButtonMoreActionSkeletonLoader() {
	return <Skeleton className="flex h-10 w-9 items-center gap-2" />;
}

export function ShipmentMapSectionSkeletonLoader() {
	return <Skeleton className="h-full overflow-hidden rounded-xl" />;
}

export type ShipmentDetailSkeletonLoaderProps = {
	caption?: string;
};

export function ShipmentDetailSkeletonLoader({
	caption,
}: ShipmentDetailSkeletonLoaderProps) {
	const { t } = useTranslation();
	return (
		<Page
			title={`Shipment ID #...`}
			backButton={{
				label:
					caption ?? t('pages.shipment-detail.loading', 'Getting shipment...'),
				href: '/shipments',
			}}
			stickyBar={{
				position: 'bottom',
				children: <ShipmentActionBarSkeletonLoader />,
			}}
		>
			<Grid
				columns={[
					{
						type: '%',
						value: 60,
					},
					{
						type: 'auto',
					},
				]}
				className="relative min-h-screen pb-16 print:!flex print:flex-col"
				gap={2.5}
			>
				<div className="flex flex-col gap-4">
					<ShipmentDetailsSectionSkeletonLoader />
					<RouteSectionSkeletonLoader />
					<PublishDetailsSectionSkeletonLoader />
				</div>
				<div className="sticky h-[70vh] print:hidden">
					<div className="absolute -top-[4.4rem] right-0 flex gap-2">
						<ButtonPreviousSkeletonLoader />
						<ButtonNextSkeletonLoader />
						<ButtonEventsSkeletonLoader />
						<ButtonMoreActionSkeletonLoader />
					</div>
					<ShipmentMapSectionSkeletonLoader />
				</div>
			</Grid>
		</Page>
	);
}
