import { type ReactNode, createContext } from 'react';
import { ShipmentView } from '@uturn/portal/hooks/shipments/use-shipment-view';

const defaultView = ShipmentView.Comfortable;

export const ShipmentViewContext = createContext<ShipmentView>(defaultView);

export const ShipmentViewProvider = ({
	children,
	view = defaultView,
}: {
	children: ReactNode;
	view?: ShipmentView;
}) => {
	return (
		<ShipmentViewContext.Provider value={view}>
			{children}
		</ShipmentViewContext.Provider>
	);
};
