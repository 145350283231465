import {
	AlertDialogCancel,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Button,
} from '@uturn/ui-kit';

import { useTranslation } from 'react-i18next';

export const ShipmentConceptSavedModal = ({
	shipmentNumber,
	navigate,
	copyShipment,
}: {
	shipmentNumber: string;
	navigate: any;
	copyShipment: () => void;
}) => {
	const { t } = useTranslation();
	return (
		<>
			<AlertDialogHeader>
				<AlertDialogTitle>
					{t(
						'pages.shipment.modal.concept.title',
						'Shipment {{shipmentNumber}} saved',
						{ shipmentNumber },
					)}
				</AlertDialogTitle>
				<AlertDialogDescription>
					{t(
						'pages.shipment.modal.concept.body',
						'You have successfully created shipment {{shipmentNumber}}  as a concept. Please note that this shipment is not visible to carriers until it is published. What would you like to do next?',
						{ shipmentNumber },
					)}
				</AlertDialogDescription>
			</AlertDialogHeader>
			<AlertDialogFooter>
				<div className="flex gap-2">
					<Button
						variant="outline"
						onClick={() => {
							navigate(`/shipments/${shipmentNumber}`);
						}}
					>
						{t(
							'pages.addshipment.modal.actions.check-details',
							'Shipment details',
						)}
					</Button>
					<Button
						variant="outline"
						onClick={() => {
							navigate(`/shipments/drafted`);
						}}
					>
						{t(
							'pages.addshipment.modal.actions.back-to-overview',
							'Shipments overview',
						)}
					</Button>
				</div>
				<AlertDialogCancel asChild>
					<Button variant="outline" onClick={copyShipment}>
						{t('pages.addshipment.modal.actions.copy', 'Copy')}
					</Button>
				</AlertDialogCancel>
				<Button
					onClick={() => {
						navigate(`/shipments/add`);
					}}
				>
					{t('pages.addshipment.modal.actions.new', 'Create new')}
				</Button>
			</AlertDialogFooter>
		</>
	);
};
