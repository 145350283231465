import '@uturn/design-tokens/base.css';
import { RouterProvider } from 'react-router-dom';
import { ApiProvider } from '@uturn/portal/api';
import { Providers } from '@uturn/portal/providers';
import { router } from '@uturn/portal/routes';

export function App() {
	return (
		<ApiProvider>
			<Providers>
				<RouterProvider router={router} />
			</Providers>
		</ApiProvider>
	);
}
