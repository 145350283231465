import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon } from '@uturn/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CopyToClipboard({ value }: { value: string }) {
	const [openTooltip, setOpenTooltip] = useState(false);
	const { t } = useTranslation();

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={(e) => {
				e.preventDefault();
				navigator.clipboard.writeText(value);
				setOpenTooltip(true);
				setTimeout(() => setOpenTooltip(false), 1000);
			}}
			className="relative size-6"
		>
			<Icon className="text-muted-foreground" icon={faCopy} />
			<AnimatePresence>
				{openTooltip && (
					<motion.div
						initial={{ opacity: 0, y: 10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 10 }}
						className="absolute -top-10 z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md"
					>
						{t('general.copied', 'Copied!')}
					</motion.div>
				)}
			</AnimatePresence>
		</Button>
	);
}
