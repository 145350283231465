import { FetchCancellationReasons200Item as CancelReason } from '@uturn/api/v1';
import { UnpublishOptions } from './shipment-cancel.types';
import { z } from '@uturn/portal/zod';
import {
	RequiredField,
	addIssueRequired,
	getRequiredFields,
} from '@uturn/portal/zod/utils';

export const formCancelSchema = z.object({
	type: z.literal(UnpublishOptions.CANCEL),
	reasonSelect: z.string().describe(RequiredField),
	reasonOtherExplanation: z.string().max(255).optional(),
});

export const formUnpublishSchema = z.object({
	type: z.literal(UnpublishOptions.UNPUBLISH),
	reasonSelect: z.string().optional(),
	reasonOtherExplanation: z.string().optional(),
});

export const formSchema = z
	.discriminatedUnion('type', [formCancelSchema, formUnpublishSchema])
	.superRefine((values, ctx) => {
		if (
			values.type === UnpublishOptions.CANCEL &&
			values.reasonSelect === CancelReason.OTHER &&
			!values.reasonOtherExplanation
		) {
			addIssueRequired(ctx, 'reasonOtherExplanation');
		}
	});

export type FormValues = z.infer<typeof formSchema>;

export const requiredFields = getRequiredFields(formCancelSchema);
