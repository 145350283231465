import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AddShipmentForm } from './add-form';
import { Page } from '@uturn/portal/components/page';

export function AddShipment() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet title={t('pages.addshipment.title', 'Add new shipment')!} />
			<Page
				backButton={{
					label: 'Shipments',
					href: '/shipments',
				}}
				className="m-auto md:max-w-4xl"
			>
				<AddShipmentForm />
			</Page>
		</>
	);
}
