import { useGetEventsByShipmentNumber } from '@uturn/api/v1';
import { Sheet, SheetContent } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { EventsFeed } from '../event-feed/events-feed';
import { groupedEventsByDate } from '../event-feed/events-feed.utils';
import { getMonthsFromLocal } from '@uturn/portal/utils/get-months-from-local';

export function EventsShipmentHistorySheet({
	shipmentNumber,
	openSheet,
	setOpenSheet,
}: {
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
	shipmentNumber: string;
}) {
	const [events, setEvents] = useState<any[]>([]);
	const months = getMonthsFromLocal({ localeName: navigator.language });

	const { data } = useGetEventsByShipmentNumber(Number(shipmentNumber), {
		query: { suspense: false },
	});

	useEffect(() => {
		if (data) {
			setEvents(groupedEventsByDate(data.data.flat()));
		}
	}, [data]);

	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent>
				<EventsFeed events={events} months={months} />;
			</SheetContent>
		</Sheet>
	);
}
