import { WeightUnit } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';
import { formValidationMessage } from '@uturn/portal/zod/utils';

/**
 * The four digit code identifying the type of hazardous goods. Required when hazardous
 * @pattern ^$|\d{4}
 */
export const unCodeSchema = z.union([
	z.literal(''),
	z
		.string()
		.length(4)
		.refine(
			(value: string) => /^\d{4}$/.test(value),
			formValidationMessage('4_numbers'),
		),
]);

export const grossWeightSchema = z.object({
	quantity: z.number().nonnegative(), // quantity: z.string().min(1).max(5).pipe(z.coerce.number()),
	unit: z.nativeEnum(WeightUnit).default('kilogram'),
});
// { required_error: 'Weight is required' }
// { message: 'Weight must be less than 5 digits' }
// { message: 'Weight must be greater or equal to 0' }

export const cargoDescriptionSchema = z.string();
