import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
	type ValidationsResult,
	newValidationsResult,
	validationsError,
} from '@uturn/portal/types';
import { ContainerStatus } from '@uturn/portal/types/shipment';

export function useGrossWeightValidation() {
	const { t } = useTranslation();
	/**
	 * grossWeight is almost always is required, unless
	 * saving as concept or ConntainerStatus.EMPTY, which
	 * is only possible for TransportType.SHUNT.
	 *
	 * @param grossWeight
	 * @param containerStatus
	 * @param publishing Publishing or updating published shipment
	 * @param validationsResult
	 * @returns
	 */
	const validateGrossWeight = (
		grossWeight: number,
		containerStatus: ContainerStatus,
		publishing: boolean,
		validationsResult?: ValidationsResult,
	): ValidationsResult => {
		let results = validationsResult ?? newValidationsResult();

		if (!publishing || containerStatus === ContainerStatus.EMPTY) {
			return results;
		}

		const grossWeightSchema = z
			.number({
				required_error: t(
					'general.errors.form_validation.required',
					'Required.',
				)!,
				invalid_type_error: t(
					'general.errors.form_validation.nan',
					'Must be a number.',
				)!,
			})
			.max(99999, {
				message: t(
					'general.errors.gross_weight.too_big',
					'This is too heavy.',
				)!,
			})
			.positive({
				message: t(
					'general.errors.form_validation.too_small',
					'Must be greater than 0.',
				)!,
			});
		try {
			grossWeightSchema.parse(grossWeight);
		} catch (err) {
			if (err instanceof z.ZodError) {
				results = validationsError(
					results,
					err.issues[0].message,
					'grossWeight.quantity',
				);
			}
		}
		return results;
	};
	return { validateGrossWeight };
}
