import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import type { TierDTO } from '@uturn/api/v1';
import {
	Button,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Icon,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function PriceCalculatorSubscriptionConfirmDialog({
	tier,
	isSubscribing,
	onSubscribe,
	onClose,
}: {
	tier: TierDTO;
	isSubscribing: boolean;
	onSubscribe: (tierId: number) => void;
	onClose: () => void;
}) {
	const { t } = useTranslation();

	return (
		<>
			<DialogHeader>
				<DialogTitle>
					{t(
						'pages.subscriptions.modal.confirm.title',
						'Confirmation of subscription',
					)}
				</DialogTitle>
				{/* TODO: Add translation */}
				<p className="leading-6">
					You are about to start a “{tier.name}” subscription for the price
					calculator. <br />
					Here are some more details: <br />
					<ul>
						<li>- On-going subscription</li>
						<li>- Yearly invoiced & terminable</li>
						<li>- Invoiced in advance</li>
						<li>- Payment term of 10 days</li>
					</ul>
					<br />
					You can already use the price calculator after you have started the
					subscription. <br />
					Selecting “Agree and subscribe” indicates your agreement with the{' '}
					<a
						rel="noreferrer"
						href="https://portal.uturn-system.com/assets/documents/PCS contract.pdf"
						target="_blank"
						className="font-bold text-primary hover:text-primary/75"
					>
						terms and conditions
					</a>
					.
					<br />
					<br />
					Are you sure you want to start this subscription?
				</p>
			</DialogHeader>

			<DialogFooter>
				<Button disabled={isSubscribing} onClick={onClose} variant="secondary">
					{t('general.cancel', 'Cancel')}
				</Button>
				<Button disabled={isSubscribing} onClick={() => onSubscribe(tier.id!)}>
					{isSubscribing ? (
						<>
							<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
							{t('general.processing', 'Processing...')}
						</>
					) : (
						t('pages.subscriptions.modal.confirm.submit', 'Agree and subscribe')
					)}
				</Button>
			</DialogFooter>
		</>
	);
}
