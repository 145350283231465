import {
	type CreateShipment,
	CurrencyType,
	type MyCarriersDTO,
	type SaveShipmentBodyOne,
	type ShippingLineDTO,
	getMyCarriersList,
	saveShipment,
} from '@uturn/api/v1';
import { type Route } from '@uturn/api/v2';
import type { Coordinates } from '@uturn/ui';
import type { AxiosResponse } from 'axios';
import { create } from 'zustand';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { toStore } from '@uturn/portal/modules/shipments/utils';
import {
	type AvailableRoute,
	type ContainerStatus,
	type OrganizationDefaultValues,
	TransportTypeCode,
	TypeOfShipment,
} from '@uturn/portal/types/shipment';

export type CreateShipmentData = CreateShipment & {
	shippingLine?: ShippingLineDTO;
	containerStatus: ContainerStatus;
};

export type AddShipmentState = {
	copyData: Partial<FormValues>;
	initData: Partial<FormValues>; // Partial<CreateShipmentData>;
	orgDefaultValues: OrganizationDefaultValues;
	currencyCode: CurrencyType;
	transportType: TransportTypeCode;
	typeOfShipment: TypeOfShipment;
	carriers: MyCarriersDTO[];
	routes: AvailableRoute[];
	mapLocations: any[];
	setOrgDefaultValues: (data: OrganizationDefaultValues) => void;
	createShipment: (
		request: SaveShipmentBodyOne,
	) => Promise<AxiosResponse<number[], any>>;
	fetchCarriers: () => void;
	setRoutes: (data: Route[]) => void;
	addMapLocations: (data: any) => void;
	setDefaultValues: (initData: Partial<CreateShipmentData>) => void;
	setManualCreateInit: (
		transportType: TransportTypeCode,
		typeOfShipment: TypeOfShipment,
		initData: Partial<FormValues>,
		orgDefaultValues: OrganizationDefaultValues,
		currencyCode: CurrencyType,
	) => void;
	setCopyData: (copyData: FormValues) => void;
	setCurrencyCode: (currencyCode: CurrencyType) => void;
};

export const useAddShipmentStore = create<AddShipmentState>((set) => ({
	initData: {},
	copyData: {},
	carriers: [],
	transportType: TransportTypeCode.IMPORT,
	typeOfShipment: TypeOfShipment.SINGLE,
	routes: [],
	mapLocations: [],
	orgDefaultValues: undefined,
	currencyCode: CurrencyType.EUR,

	setOrgDefaultValues: (data) => {
		set({ orgDefaultValues: data });
	},

	createShipment: async (request: SaveShipmentBodyOne) => {
		return saveShipment(request, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
			.then((response) => {
				return response;
			})
			.catch((error) => {
				return error.response.data;
			});
	},

	fetchCarriers: async () => {
		const { data } = await getMyCarriersList();

		const carriers = data || [];

		set({ carriers });
	},

	setRoutes: (data: Route[]) => {
		const routes: AvailableRoute[] = data.map((route: Route) => ({
			...route,
			isSelected: true,
			isValid: !route.validationMessages?.length,
		}));

		set({ routes });
	},

	addMapLocations: (locations: Coordinates[]) => {
		set({ mapLocations: locations });
	},

	setDefaultValues: (initData: Partial<CreateShipmentData>) => {
		if (Object.keys(initData).length === 0) {
			set({ initData: {} });
			return;
		}
		set({
			initData: {
				...(initData as FormValues),
			},
		});
	},

	setManualCreateInit: (
		transportType,
		typeOfShipment,
		initData,
		orgDefaultValues,
		currencyCode,
	) => {
		const defaultValues = toStore(
			transportType,
			typeOfShipment,
			initData,
			orgDefaultValues,
			currencyCode,
		);

		set({
			copyData: {},
			transportType,
			typeOfShipment,
			initData: {
				...defaultValues,
			},
		});
	},

	setCopyData: (copyData: FormValues) => {
		if (Object.keys(copyData).length === 0) {
			set({ copyData: {} });
			return;
		}
		set({
			orgDefaultValues: undefined,
			initData: {},
			copyData: {
				...copyData,
			},
			transportType: copyData.transportType,
			typeOfShipment: copyData.typeOfShipment,
		});
	},

	setCurrencyCode: (currencyCode: CurrencyType) => {
		set({ currencyCode });
	},
}));
