import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPasswordPost } from '@uturn/api';
import {
	Button,
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	Icon,
	Input,
	cn,
	sonner,
} from '@uturn/ui-kit';
import { type AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const schema = z
	.object({
		password: z.string(),
		confirmPassword: z.string(),
	})
	.refine(({ password, confirmPassword }) => password === confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});

export function PasswordResetForm() {
	const [token, setToken] = useState<string>('');
	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const tokenParam = searchParams.get('token');

	useEffect(() => {
		setToken(tokenParam ?? '');
	}, [tokenParam]);

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = async ({
		password,
		confirmPassword,
	}) => {
		try {
			await resetPasswordPost({
				newPassword: password,
				newPassword1: confirmPassword,
				token,
			});
		} catch (e: AxiosError | any) {
			let description = e.message;
			if ('response' in e) {
				description = e.response.data.message;
			}
			form.setError('root', {
				type: 'custom',
				message: description,
			});
			sonner.error(
				description ||
					t('general.errors.generic_title', 'Uh oh! Something went wrong.'),
			);
		}
	};

	return (
		<div className={cn('grid gap-6')}>
			{form.formState.isSubmitSuccessful ? (
				<p className="text-sm">{t('pages.auth.password_reset.success')}</p>
			) : (
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="grid gap-2">
							<div className="grid gap-1">
								<FormField
									control={form.control}
									name="password"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="sr-only">
												{t('general.password', 'Password')}
											</FormLabel>
											<FormControl>
												<Input
													type="password"
													autoComplete="off"
													autoCorrect="off"
													minLength={6}
													required={true}
													disabled={form.formState.isSubmitting}
													placeholder={t('general.password', 'Password')!}
													{...field}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="confirmPassword"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="sr-only">
												{t('general.password', 'Password')}
											</FormLabel>
											<FormControl>
												<Input
													type="password"
													autoComplete="off"
													autoCorrect="off"
													required={true}
													minLength={6}
													disabled={form.formState.isSubmitting}
													placeholder={
														t(
															'pages.auth.password_reset.form.fields.confirm_password',
															'Confirm password',
														)!
													}
													{...field}
												/>
											</FormControl>
											{form.formState.errors.confirmPassword && (
												<p className="text-xs text-red-700">
													{form.formState.errors.confirmPassword.message}
												</p>
											)}
											<FormDescription className="text-xs">
												{t('general.errors.form_validation.min_length', {
													propertyName: 'Password',
													minLength: '6',
												})}
											</FormDescription>
										</FormItem>
									)}
								/>
							</div>
							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('pages.auth.password_reset.form.submit', 'Reset password')}
							</Button>
						</div>
					</form>
				</Form>
			)}
		</div>
	);
}
