import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
	AdditionalRequirement,
	ContainerSizeEnum,
	PriceCalculatorTransportType as TransportType,
	getPlaceDetailsByPlaceId,
} from '@uturn/api/v1';
import type {
	AddressDTO,
	AddressLookupResponse,
	ConditionKey,
	PriceRequestDto,
} from '@uturn/api/v1';
import { Radio } from '@uturn/ui';
import {
	AutoComplete,
	Button,
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
	FormField,
	FormItem,
	FormLabel,
	Icon,
	Label,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@uturn/ui-kit';
import getCountryISO3 from 'country-iso-3-to-2';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import {
	Controller,
	FormProvider,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { PriceRequestFormProps } from './price-request-form.types';
import {
	additionalRequirementOptions,
	containerSizeOptions,
	locationLabels,
	transportTypeOptions,
} from './price-request-form.types';
import { usePriceCalculatorStore } from '@uturn/portal/store/price-calculator';
import { getLocationQueryString } from '@uturn/portal/utils';

function addressLookupToAddressDTO(address: AddressLookupResponse): any {
	return {
		id: address.id ?? null,
		name: address.name ?? null,
		...(address.placeId && { googlePlaceId: address.placeId }),
		street: address.street ?? null,
		city: address.city ?? null,
		postalCode: address.postalCode ?? '',
		countryCode: getCountryISO3(address.country?.countryCode ?? ''),
		latitude: address.latitude ?? null,
		longitude: address.longitude ?? null,
		houseNumber: address.houseNumber ?? null,
	};
}

function useAutoSuggestions() {
	const { fetchLocations } = usePriceCalculatorStore();
	const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
		string[]
	>([]);
	const [fullAutocompleteSuggestions, setFullAutocompleteSuggestions] =
		useState<AddressLookupResponse[]>([]);

	return {
		autocompleteSuggestions,
		getAutoCompleteSuggestions: (text: string) => {
			fetchLocations({ searchText: text }).then((locations) => {
				setAutocompleteSuggestions(
					locations.map(
						(item) =>
							`${item.name ?? ''} ${item.street ?? ''} ${item.city ?? ''}`,
					),
				);

				setFullAutocompleteSuggestions(locations);
			});
		},
		fullAutocompleteSuggestions,
	};
}

function AdditionalRequirementsRadioGroup({
	additionalRequirementEnabledFeatures = [],
}: {
	additionalRequirementEnabledFeatures?: ConditionKey[];
}) {
	const { t } = useTranslation();
	const form = useFormContext<PriceRequestDto>();

	if (!additionalRequirementEnabledFeatures.length) {
		return (
			<div>
				<Label>
					{t(
						'pages.price-calculator.form.fields.additional-requirements.label',
						'Additional requirements',
					)}
				</Label>
				<div className="relative">
					<div className="z-1 text-secondary-600 absolute grid size-full items-center justify-center text-center font-bold">
						{t(
							'pages.price-calculator.form.fields.additional-requirements.no-access',
							'To access additional requirements, please upgrade your subscription.',
						)}
					</div>
					<div className="blur-md">
						<Radio
							options={additionalRequirementOptions(t).map((item) => ({
								label: item.label,
								value: item.value,
							}))}
							value={AdditionalRequirement.NONE}
							onChange={() => {}}
							disabled={true}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<Label className="mt-4 flex items-center gap-2">
				{t(
					'pages.price-calculator.form.fields.additional-requirements.label',
					'Additional requirements',
				)}
				<TooltipProvider delayDuration={10}>
					<Tooltip>
						<TooltipTrigger>
							<Icon icon={faInfoCircle} className="size-4" />
						</TooltipTrigger>
						<TooltipContent>
							{t(
								'pages.price-calculator.form.fields.additional-requirements.tooltip',
								'Additional transport requirements that can be added to the price request.',
							)}
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</Label>
			<Controller
				name="additionalRequirement"
				control={form.control}
				render={({ field: { onChange, value } }) => (
					<Radio
						options={additionalRequirementOptions(t)
							.filter(
								(item) =>
									!item.conditionKey ||
									additionalRequirementEnabledFeatures.includes(
										item.conditionKey,
									),
							)
							.map((item) => ({
								label: item.label,
								value: item.value,
							}))}
						value={
							AdditionalRequirement[
								value as unknown as keyof typeof AdditionalRequirement
							]
						}
						onChange={onChange}
					/>
				)}
			/>
		</div>
	);
}

export function PriceRequestForm({
	defaultValues,
	onSubmit,
	onTripChange,
	additionalRequirementEnabledFeatures,
}: PriceRequestFormProps) {
	const { t } = useTranslation();
	const {
		autocompleteSuggestions,
		getAutoCompleteSuggestions,
		fullAutocompleteSuggestions,
	} = useAutoSuggestions();

	const [suggestions, setSuggestions] = useState<any[]>([]);

	useEffect(() => {
		if (!autocompleteSuggestions) return;
		const newSuggestions = autocompleteSuggestions.map(
			(suggestion: string) => ({
				label: suggestion,
				value: suggestion,
			}),
		);

		setSuggestions(newSuggestions);
	}, [autocompleteSuggestions]);

	const [transportType, setTransportType] = useState<TransportType>(
		TransportType.IMPORT,
	);

	const form = useForm({
		...{
			defaultValues,
		},
	});

	const { fetchRouteInformation } = usePriceCalculatorStore();

	const watchLocations = form.watch('locations');
	const watchTransportType = form.watch('transportType');

	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: 'locations',
		rules: {
			required: t(
				'general.field-required',
				'This field is required',
			).toString(),
		},
	});

	useEffect(() => {
		if (
			watchTransportType === TransportType.SHUNT &&
			transportType !== TransportType.SHUNT
		) {
			remove(2);
		}

		if (watchTransportType !== TransportType.SHUNT && fields.length < 3) {
			append({});
		}
		setTransportType(watchTransportType);
	}, [watchTransportType]);

	useEffect(() => {
		const subscription = form.watch((value, { name }) => {
			if (
				// Reason for startsWith is because the name will be locations.0, locations.1, locations.2
				(name?.startsWith('locations') === false && name !== 'transportType') ||
				value.locations === undefined
			) {
				return;
			}

			const requiredLocationsLength =
				value.transportType === TransportType.SHUNT ? 2 : 3;

			const locations: AddressDTO[] = structuredClone(watchLocations)
				.filter(
					(location: any) =>
						location !== undefined && location.id !== undefined,
				)
				.slice(0, requiredLocationsLength) as AddressDTO[];

			if (locations.length === requiredLocationsLength) {
				fetchRouteInformation({
					locations,
					transportType: value.transportType || TransportType.IMPORT,
				});
			}

			onTripChange(locations);
		});

		return () => subscription.unsubscribe();
	}, [watchLocations]);

	const formRef = useRef<HTMLFormElement>(null);

	// TODO Remove any's and fix this
	function onSelect(indexLocation: number, indexAutoSuggestion: number) {
		const location = fullAutocompleteSuggestions[indexAutoSuggestion];

		if (location.source?.toUpperCase() === 'GOOGLE') {
			getPlaceDetailsByPlaceId({
				placeId: location.placeId ?? '',
			}).then((res) => {
				form.setValue(
					`locations.${indexLocation}`,
					addressLookupToAddressDTO(res.data),
				);
			});
		} else {
			form.setValue(
				`locations.${indexLocation}`,
				addressLookupToAddressDTO(location),
			);
		}

		form.trigger();
		return null;
	}

	function locationTypeLabel(i: number): string {
		return t('components.shipment_location.type', '{{locationType}} location', {
			locationType: locationLabels(t)[transportType][i],
		});
	}

	return (
		<motion.div
			initial={{ opacity: 0, translateY: 15 }}
			animate={{ opacity: 1, translateY: 0 }}
			exit={{ opacity: 0, translateY: 15 }}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
		>
			<Card key="unlocked">
				<CardHeader>
					<CardTitle>
						{t('pages.price-calculator.form.title', 'Request a price')}
					</CardTitle>
					<CardDescription>
						{t(
							'pages.price-calculator.form.description',
							'Market price insight with the UTURN price calculator. Provide your shipment details and get objective data based insights in price.',
						)}
					</CardDescription>
				</CardHeader>
				<CardContent>
					<FormProvider {...form}>
						<form
							className="flex flex-col gap-3"
							ref={formRef}
							onSubmit={form.handleSubmit((e) => {
								let hasError = false;

								for (let i = 0; i < e.locations.length; i += 1) {
									if (e.locations[i].id === undefined) {
										hasError = true;
										form.setError(`locations.${i}`, {
											message: t(
												'TODO',
												'Please select a location from the dropdown',
											)!,
										});
									}
								}

								if (hasError === false) {
									onSubmit(e);
								}
							})}
						>
							<Controller
								name="transportType"
								control={form.control}
								render={({ field: { onChange, value } }) => (
									<Radio
										label="Transport type"
										options={transportTypeOptions(t)}
										value={TransportType[value as keyof typeof TransportType]}
										onChange={onChange}
									/>
								)}
							/>
							<div className="my-4 flex flex-col gap-4">
								{fields.map((item, index) => (
									<FormField
										key={item.id}
										name={`locations.${index}`}
										control={form.control}
										render={({ field }) => (
											<FormItem>
												<FormLabel>{locationTypeLabel(index)}</FormLabel>
												<AutoComplete
													refCallback={field.ref}
													value={getLocationQueryString(field.value)}
													options={suggestions}
													emptyMessage={t(
														'pages.price-calculator.form.fields.location.dropdown.no-result',
														'No location found',
													)}
													debouncerDelay={1000}
													onInputChange={(e) => {
														if (e === '') {
															field.onChange({});
															return;
														}
														getAutoCompleteSuggestions(e);
													}}
													setSelected={(selectIndex) =>
														onSelect(index, selectIndex)
													}
													forceUniqueLabelsEnabled={true}
													placeholder={
														t(
															'pages.price-calculator.form.fields.location.dropdown.placeholder',
															'Search and select {{ location }} in Europe',
															{
																location:
																	locationTypeLabel(index).toLowerCase(),
															},
														)!
													}
												/>
											</FormItem>
										)}
									/>
								))}
							</div>
							<Controller
								name="containerSize"
								control={form.control}
								render={({ field: { onChange, value } }) => (
									<Radio
										label={t(
											'pages.price-calculator.form.fields.container-size',
											'Container size',
										)}
										options={containerSizeOptions(t)}
										value={
											ContainerSizeEnum[value as keyof typeof ContainerSizeEnum]
										}
										onChange={onChange}
									/>
								)}
							/>
							<AdditionalRequirementsRadioGroup
								additionalRequirementEnabledFeatures={
									additionalRequirementEnabledFeatures
								}
							/>
							<div className="mt-8 flex flex-row justify-end">
								<Button disabled={!form.formState.isValid} type="submit">
									{t('pages.price-calculator.request-price', 'Request price')}
								</Button>
							</div>
						</form>
					</FormProvider>
				</CardContent>
			</Card>
		</motion.div>
	);
}
