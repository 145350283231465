import type { MetaData, ShipmentMetaData } from '@uturn/api/v1';
import { createContext } from 'react';

export type ShipmentUnit = {
	code: string;
	referenceCode: string;
	description: string;
	portbaseCode: string;
	containerRequired: boolean;
	generatorSetRequired: boolean;
};

export const MetaDataContext = createContext<{
	metadata: MetaData | undefined;
	shipmentMetaData: ShipmentMetaData | undefined;
	/**
	 * List of shipping requirement codes that are only managed by BE.
	 *
	 * @remark
	 * For now shippingRequirementExceptions provides a sharable place,
	 * instead of finding hardcoded requirement codes all over the code base.
	 *
	 * @todo
	 * Improve this further: Should this be moved to BE shipmentMetaData,
	 * separate BE endpoint, FE configuration file or other?
	 */
	shippingRequirementExceptions: string[];
	shipmentUnits: { [key: string]: ShipmentUnit };
	/** Dictated by Meilisearch in AG Grid Table view implementation  */
	shipmentMaxRoutes: number;
}>({
	metadata: {},
	shipmentMetaData: {},
	shippingRequirementExceptions: [],
	shipmentUnits: {},
	shipmentMaxRoutes: 0,
});
