import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import { getFeedback } from '@sentry/react';
import { DropdownMenuItem, Icon } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReportABug = () => {
	const [form, setForm] = useState<any>();
	const { t } = useTranslation();
	const feedback = getFeedback();

	useEffect(() => {
		if (!feedback) {
			return;
		}

		(async () => {
			const feedbackDialog = await feedback?.createForm();
			setForm(feedbackDialog);
		})();
	}, [feedback]);

	if (!feedback || !form) {
		return null;
	}

	return (
		<DropdownMenuItem
			className="flex cursor-pointer gap-2"
			onClick={async () => {
				form.appendToDom();
				form.open();
			}}
		>
			<Icon icon={faMegaphone} />
			{t('header.navigation.user.report-a-bug', 'Report a bug')}
		</DropdownMenuItem>
	);
};

export default ReportABug;
